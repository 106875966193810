<template>

   <div class="col-span-4 md:col-span-1 p-6 flex flex-col rounded-3xl animate-pulse bg-gray-200">
      <div class="flex flex-col">
         <div class="flex flex-row gap-3 w-full">
            <div class="md:min-h-[40px] md:max-h-[40px] md:min-w-[40px] md:max-w-[40px] w-[35px] h-[35px] rounded-full bg-gray-100"></div>
            <div class="flex flex-col gap-3 justify-center w-full">
               <div class="bg-gray-100 h-[10px] w-[80%]"></div>
               <div class="bg-gray-100 h-[10px] w-[40%]"></div>
            </div>
         </div>
         <div class="w-full bg-gray-100 h-[20px] mt-4"></div>
         <div class="flex gap-2 mt-4">
            <div class="rounded-full py-5 px-12 bg-gray-100"></div>
            <div class="rounded-full py-5 px-12 bg-gray-100"></div>
            <div class="rounded-full py-5 px-12 bg-gray-100"></div>
         </div>
      </div>
   </div>

</template>

<script setup lang="ts">

</script>
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../pages/Login.vue'
import Education from '../pages/Education.vue'
import SiteHome from '../pages/SiteHome.vue'
import SiteHomeNew from '../pages/SiteHomeNew.vue'
import Apply from '../pages/Apply.vue'
import Home from '../pages/Home.vue'
import HomeNew from '../pages/HomeNew.vue'
import Profile from '../pages/Profile.vue'
import ProjectDetails from '../pages/ProjectDetails.vue'
import ProjectDetailsNew from '../pages/ProjectDetailsNew.vue'
import ProfileEdit from '../pages/ProfileEdit.vue'
import SaveProject from '../pages/SaveProject.vue'
import UserList from '../pages/user/UserList.vue'
import InviteFriend from '../pages/InviteFriend.vue'
import NotFound from '../pages/NotFound.vue'
import AdditionalAuthorization from '../pages/AdditionalAuthorization.vue'
import Test from '../pages/Test.vue'
import { isUserAuthorized } from '@/utils/common'
import { ADMIN_EMAIL } from '@/utils/constants'

const routes: Array<RouteRecordRaw> = [
   {
      path: '/',
      name: 'SiteHome',
      component: SiteHomeNew,
      meta: { title: "InFlight" }
   },
   {
      path: '/additional-authorization',
      name: 'AdditionalAuthorization',
      component: AdditionalAuthorization,
      meta: { title: "Additional Authorization" }
   },
   {
      path: '/login/:pairId/:platform',
      name: 'LoginToShare',
      component: Login,
      meta: { title: "Login | InFlight" },
      props: true
   },
   {
      path: '/sign-in',
      name: 'SignIn',
      component: Login,
      meta: { title: "Sign In | InFlight" },
      props: true
   },
   {
      path: '/apply',
      name: 'Apply',
      component: Apply,
      meta: { title: "Apply | InFlight" },
      props: true
   },
   {
      path: '/education',
      name: 'Education',
      component: Education,
      meta: { title: "Education | InFlight" }
   },
   {
      path: '/home',
      name: 'Home',
      component: HomeNew,
      meta: { title: "Home | InFlight" }
   },
   {
      path: '/home-new',
      name: 'HomeNew',
      component: HomeNew,
      meta: { title: "Home New Design" }
   },
   {
      path: "/profile/edit",
      name: "ProfileEdit",
      component: ProfileEdit,
      meta: { title: "Edit Profile" }
   },
   {
      path: "/:name/invite/:number",
      name: "InviteFriend",
      component: InviteFriend,
      meta: { title: "Invite Friend" },
      props: true
   },
   // {
   //    path: "/profile/:id?",
   //    name: "Profile",
   //    component: Profile,
   //    meta: { title: "Profile | InFlight" },
   //    props: true
   // },
   {
      path: "/project/:number/:feedbackRequest?",
      name: "ProjectDetails",
      component: ProjectDetailsNew,
      meta: { title: "Project | InFlight" },
      props: true
   },
   {
      path: "/project/new/:number/:feedbackRequest?",
      name: "ProjectDetailsNew",
      component: ProjectDetailsNew,
      meta: { title: "Project | InFlight" },
      props: true
   },
   {
      path: "/project/save/:id?",
      name: "SaveProject",
      component: SaveProject,
      meta: { title: "Save Project | InFlight" },
      props: true
   },
   {
      path: "/test",
      name: "Test",
      component: Test,
      meta: { title: "Quick Test" }
   },
   {
      path: '/users',
      name: 'UserList',
      component: UserList,
      meta: { title: "User List" }
   },
   { 
      path: '/:pathMatch(.*)*', 
      name: 'NotFound', 
      component: NotFound 
   }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach( (to, from, next) => {
   
   const user = localStorage.getItem('inFlightAuthUser')
   const authUser = user !== null ? JSON.parse(user) : null

   if (to.meta.title) {
      document.title = to.meta.title as string
   }

   if (!authUser && (to.name == 'Profile' || to.name == 'Home' || to.name == 'ProfileEdit' || to.name == 'SaveProject' || to.name == 'Education')) {

      next(false)
      router.push({name: 'SignIn'})
   } else if (authUser && (to.name == 'Login' || to.name == 'SignIn')) {

      next(false)
      router.push({name: 'Home'})
   } else if (authUser && to.name !== 'AdditionalAuthorization' && !isUserAuthorized()) {
      next(false)
      router.push({name: 'AdditionalAuthorization'})
   } else if (authUser && to.name == 'AdditionalAuthorization' && isUserAuthorized()) {
      router.push({name: 'Home'})
   } else if (authUser && to.name == 'SiteHome') {
      next(false)
      router.push({name: 'Home'})
   } else if (authUser && authUser.email != ADMIN_EMAIL && to.name == 'UserList') {
      router.push({name: 'Home'})
   } else if (to.name == 'NotFound') {
      router.push({name: 'SiteHome'})
   }
   
   next(true)
})

export default router

<template>

</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { createLoginRedirect, createFriend } from '@/utils/common'
import router from '@/router'

const props = defineProps(['number'])

onMounted(async () => {
   
   const isLoggedIn = localStorage.getItem('inFlightAuthUser')
   
   if (isLoggedIn) {

      createFriend(props.number)
      router.push({name: 'HomeNew'})
   } else {
      createLoginRedirect(window.location.href)
      router.push({name: 'SignIn'})
   }
})

</script>
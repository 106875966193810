import { getBackendBaseUrl } from "@/utils/common"
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Auth = () => {

   const getToken = async (refreshToken : string) => {
      try {
         let response = await axios.post(baseUrl + '/refresh-token', { refresh_token: refreshToken })
         if(response.status == 200){
            const tokens = {
               access : response.data.token,
               refresh: refreshToken
            }
            localStorage.setItem('inFlightAuthUserTokens', JSON.stringify(tokens))
         }else{
            throw Error('error')
         }
      } catch (err) {
         return 'error'
      }
   }

   const authorizeUser = async (code : string) => {
      try {
         const response = await axios.post(baseUrl + '/authorize-user', { code: code }, {})
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      getToken,
      authorizeUser
   }
}

export default Auth
<template>
   <div class="flex flex-col gap-8 w-full md:w-[390px] max-w-full p-4 bg-white border border-black border-opacity-10 rounded-2xl">
      <div @click="toggleOpenDetail()" class="flex items-center cursor-pointer">
         <div>
            <div v-if="artwork.type == 'image' || (artwork.type == 'video' && artwork.thumbnail !== '')" class="bg-no-repeat bg-cover bg-center rounded-3xl min-w-[75px] max-w-[75px] min-h-[75px] max-h-[75px] mx-auto" :style="{ backgroundImage: `url(${getArtworkSourceURL(artwork)})` }"></div>
            <div v-else-if="artwork.type == 'video'" class="w-[75px] h-[75px] flex items-center justify-center border border-black border-opacity-10 rounded-3xl">
               <font-awesome-icon icon="fa-solid fa-play" class="icon text-black" size="xl" />
            </div>
            <div v-else-if="artwork.type == 'hevc'" class="w-[75px] h-[75px] flex items-center justify-center border border-black border-opacity-10 rounded-3xl">
               <font-awesome-icon icon="fa-solid fa-file" class="icon text-black" size="xl" />
            </div>
         </div>
         <div class="flex-1 mx-6 flex flex-col items-start">
            <div class="font-medium text-head-2 whitespace-nowrap overflow-hidden">{{ title != ''  ? truncateText(title, 15) : 'Screen title'}}</div>
            <div class="text-[#C8C8C8] font-bold whitespace-nowrap overflow-hidden">{{ description != '' ? truncateText(description, 15) : 'Click to add details' }}</div>
         </div>
         <div @click="handleRemove(artwork.sourceIndex, artwork.source)" class="ml-auto">
            <font-awesome-icon icon="fa-solid fa-times" class="icon text-head-5 text-[#EEEEEE]" />
         </div>
      </div>
      <div v-if="isOpen" @animationend="handleAnimationEnd" :class="animationClass" class="flex flex-col gap-4 animate__animated">
         <div class="bg-[#f6f6f6] w-full rounded-xl">
            <input v-model="titleTwo" maxlength="50" class="w-full bg-transparent h-[50px] px-4 outline-none" type="text" placeholder="Screen title">
         </div>
         <div class="bg-[#f6f6f6] rounded-2xl">
            <textarea v-model="descriptionTwo" maxlength="1000" class="w-full bg-transparent h-[100px] px-4 pt-4 outline-none" placeholder="Add a description"></textarea>
         </div>
         <div class="flex items-center justify-center w-full">
            <div @click="toggleOpenDetail()" class="bg-white text-[#C9C9C9] font-bold text-head-1 px-10 py-4 flex items-center justify-center cursor-pointer">Cancel</div>
            <div @click="handleSave" class="bg-theme-blue rounded-full font-bold text-head-1 px-10 py-4 flex items-center justify-center text-white cursor-pointer">Save</div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { getAssetBaseUrl, truncateText } from '@/utils/common'

const props = defineProps(['title', 'description', 'artwork'])
const emit = defineEmits(['save', 'removed'])
const isOpen = ref<boolean>(false)
const animationClass = ref<string>('animate__zoomIn')
let title = ref<string>(props.title)
let description = ref<string>(props.description)
const assetUrl : string = getAssetBaseUrl()
const titleTwo = ref<string>(props.title)
const descriptionTwo = ref<string>(props.description)

const screenWidth = computed(() => {
   const screenWidth = window.innerWidth;
  
   if (screenWidth < 768) {
      return 'mobile'
   } else if (screenWidth >= 768 && screenWidth <= 1260) {
      return 'medium-1'
   } else if (screenWidth >= 1261 && screenWidth <= 1650) {
      return 'medium-2'
   } else {
      return 'large'
   }
})

const getArtworkSourceURL = (artwork: any) : string => {

   let artworkSource : string = ''

   if (artwork.type == 'image') {

      artworkSource = artwork.source == 'database' ? assetUrl + 'artworks/' + artwork.data : artwork.data

      if (artwork.source == 'database') {
         artworkSource += screenWidth.value == 'mobile' ? '?tr=w-285,h-600' : '?tr=w-350,h-600'
      }

   } else if (artwork.type == 'video') {

      artworkSource = assetUrl + 'video_thumbnails/' + artwork.thumbnail as string

      if (artwork.thumbnail !== '') {  
         artworkSource += screenWidth.value == 'mobile' ? '?tr=w-285,h-600' : '?tr=w-350,h-600'
      }
   }

   return artworkSource
}

const toggleOpenDetail = () => {

   animationClass.value = !isOpen.value ? 'animate__zoomIn' : 'animate__zoomOut'

   if (!isOpen.value) {
      isOpen.value = true
   }
}

const handleRemove = (index: string, source: string) => {

   emit('removed', index, source)
}

const handleAnimationEnd = () => {

   if (animationClass.value == 'animate__zoomOut') {
      isOpen.value = false
   }
}

const handleSave = () => {

   title.value = titleTwo.value
   description.value = descriptionTwo.value

   toggleOpenDetail()
   emit('save', props.artwork.sourceIndex, titleTwo.value, descriptionTwo.value)
}

</script>

<style scoped>
   .animate__zoomIn, .animate__zoomOut {
      animation-duration: 0.2s;
   }
</style>
<template>
   <div class="flex flex-col pt-6 h-full">
      <div class="flex flex-row items-center justify-between xs:px-theme md:px-theme-desktop md:mt-8 xs:mb-16 md:mb-[50px]">
         <div @click="$router.back()" class="cursor-pointer">
            <img class="min-w-[17px] max-w-[17px]" src="/assets/icons/cross.svg" alt="back">
         </div>
         <div class="text-head-2 font-medium ml-6">Edit profile</div>
         <div class="flex items-center gap-2 text-head-3 font-medium" :class="isLoading ? 'text-theme-gray' : 'text-theme-blue cursor-pointer'">
            <span v-if="!isLoading" @click="handleSubmit">Save</span>
            <span v-else>
               <img class="w-[30px] h-[30px]" src="/assets/loading.svg" alt="">
            </span>
         </div>
      </div>
      <div v-if="user" class="flex flex-col mx-auto my-24 h-full xs:px-theme md:px-0 w-[26rem]">
         <div class="flex items-center justify-center">
            <div @click="handleChooseAvatar" class="relative cursor-pointer w-[100px] h-[100px]">
               <input class="hidden" ref="fileInput" type="file" @change="uploadPhoto" accept="image/*">
               <img ref="photoRef" class="rounded-full w-full h-full" :src="user.photoURL" alt="">
               <div class="absolute bottom-0 right-0 rounded-full bg-black flex items-center justify-center p-2 border-white border border-2">
                  <img class="w-[19px]" src="/assets/icons/pencil-white-2.svg" alt="edit">
               </div>
            </div>
         </div>
         <div class="my-4">
            <input v-model="user.displayName" class="border border-theme-gray-4 w-full h-12 rounded-lg pl-2" type="text" placeholder="Full name">
         </div>
         <div class="my-4">
            <textarea v-model="user.bio" class="border border-theme-gray-4 h-36 outline-none w-full h-12 rounded-lg p-2" placeholder="Your Bio"></textarea>
         </div>
         <div @click=handleDeleteAccount class="mt-auto text-center text-head-3 text-red-600 font-bold cursor-pointer">Delete Account</div>
      </div>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
   <ConfirmationModal :show="openConfirmModal" @closed="handleConfirmClose">
      <template v-slot:buttons>
         <button @click="askingConfirmed" type="button" :class="isLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer'" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">Yes, delete my account</button>
         <button @click="openConfirmModal = false" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">Cancel</button>
      </template>
   </ConfirmationModal>
</template>
 
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import User from '@/composables/User'
import { NotificationType } from '@/types/common'
import Notification from '@/widgets/Notification.vue'
import { isImageFile, removeAuthUser, updateAuthUser } from '@/utils/common'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'
import { useRouter } from 'vue-router'

const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const isLoading = ref<boolean>(true)
const user = ref<any>(null)
const { getUser, updateUser, removeUser } = User()
const fileInput = ref<HTMLInputElement | null>(null)
const photoRef = ref<any>(null)
const openConfirmModal = ref<boolean>(false)
const router = useRouter()

const errors = ref<any>({
   photo: false,
   name: false,
   bio: false
})

const mounted = async () => {
   
   const response = await getUser('')

   if (response.status == 200) {
      
      user.value = response.data.user
   } else {
      const error = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error as string}
   }

   isLoading.value = false
}

const handleSubmit = async () => {

   if (user.value && !isLoading.value) {

      resetErrors()
      const hasError = validateForm()

      if (!hasError) {

         isLoading.value = true
         const response = await updateUser(user.value)

         if (response.status == 200) {
            
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Profile updated'}
            updateAuthUser(response.data.user)
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error as string}
         }

         isLoading.value = false
      }
   }
}

const validateForm = () => {

   let hasError

   if (user.value.displayName == '') {
      errors.value.name = 'Name is required'
      hasError = true
   }

   if (user.value.bio && user.value.bio.length > 140) {
      errors.value.name = 'Bio cannot exceed 140 characters in length'
      hasError = true
   }

   return hasError
}

const handleChooseAvatar = () => {
   fileInput.value?.click()
}

const uploadPhoto = (e: Event) => {

   user.value.photo = null
   const inputElement = e.target as HTMLInputElement

   if (!inputElement.files || inputElement.files.length === 0) {
      return
   }

   const file = inputElement.files[0]
   const reader = new FileReader()

   if (!isImageFile(file)) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'File must be an image of type JPG or PNG'}
      return
   } else if (file.size > 3145728) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'Image must be 3MB or less'}
      return
   }

   reader.onloadend = () => {
      photoRef.value.src = reader.result as string
      user.value.photo = reader.result as string
   }

   reader.readAsDataURL(file)
}

const handleDeleteAccount = () => {
   openConfirmModal.value = true
}

const askingConfirmed = async () => {

   if (!isLoading.value) {
      isLoading.value = true

      const response = await removeUser(user.value.uid)

      if (response.status == 204) {

         removeAuthUser()
         router.push({name: 'Login'})
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error as string}
      }

      isLoading.value = false
   } 
}

const handleApiError = (error : any) => {
   if(error.response){
      const message = error.response.data ? error.response.data.error : error.response.statusText
      return `status code: ${error.response.status} - message: ${message}`
   }

   return error.message
}

const notifClosed = () => {
   notif.value.show = false
}

const resetErrors = () => {
   errors.value = {
      photo: false,
      name: false,
      bio: false
   }
}

const handleConfirmClose = () => openConfirmModal.value = false

onMounted(mounted)

</script>
 
<template>
   <div @keydown.space="handleSpaceKey" @keydown.enter="handleEnterKey" class="h-[620px] flex flex-col gap-3 px-6 bg-black pb-8 rounded-3xl w-[90%] mx-auto md:w-[375px] shadow-custom-symetric-2">
      <h3 class="min-h-[80px] max-h-[80px] flex justify-center items-center text-white text-center text-head-2">
         <div class="flex relative items-center justify-center w-full gap-3">
            <img class="rounded-full w-[42px] h-[42px]" :src="authUser.image" alt="">
            <div v-if="feedback.success == null" class="text-head-6 text-white font-medium">Share your feedback</div>
            <div v-else-if="feedback.success == true">Thank You!</div>
         </div>
      </h3>
      <div v-if="!feedbackRequestData" class="text-theme-gray-white font-medium text-center">
         ...Loading please wait
      </div>
      <div v-else class="flex flex-col flex-grow">
         <div v-if="feedbackRequestData.status !== 'requested'" class="text-theme-gray-white font-medium text-center">
            You have already provided a feedback to this request
         </div>
         <div v-else-if="feedback.success == true" class="text-theme-gray-white font-medium text-center">
            You have successfully posted your feedback
         </div>
         <div v-else class="flex flex-grow overflow-y-auto w-full">
            <input class="hidden" ref="fileInput" @change="handlePhotoChoosen" type="file" name="file" accept="image/*" />
            <div class="flex flex-col gap-8 w-full">
               <div class="flex flex-col gap-2">
                  <div class="text-head-7 text-white font-medium">Details</div>
                  <div v-if="feedbackRequestData && feedbackRequestData.description" v-html="feedbackRequestData.description.replace(/\n/g, '<br>')" class="text-theme-gray-white-2 text-size-3 whitespace-pre-line"></div>
               </div>
               <div class="flex flex-col gap-2">
                  <div class="text-head-7 text-white font-medium">Feedback</div>
                  <textarea v-model="feedback.comment" class="h-[140px] w-full rounded-2xl bg-[#1F1F1F] p-6 text-head-6 resize-none outline-none b-0 text-white" placeholder="Leave your feedback for Kyle Barber"></textarea>
                  <p v-if="errors.comment" class="font-medium text-theme-bright-red">Comment is required</p>
               </div>
               <div class="flex flex-row items-center gap-2 h-[40px]">
                  <div v-if="feedback.image.name == null" @click="handleChoosePhoto" class="w-[50%] overflow-hidden cursor-pointer rounded-2xl flex items-center justify-center gap-3 bg-[#1F1F1F] px-3 py-2">
                     <img class="w-[15px] h-[15px]" src="/assets/icons/media.svg" alt="">
                     <div class="text-white text-size-3 mt-[2px]">Add Media</div>
                  </div>
                  <div v-else class="w-[50%] overflow-hidden rounded-2xl flex items-center justify-center gap-3 bg-[#1F1F1F] px-3 py-2">
                     <div class="max-w-[80%] overflow-hidden whitespace-nowrap text-white text-size-3 mt-[2px]">{{ feedback.image.name }}</div>
                     <div @click="resetImage" class="text-white text-head-1 ml-auto cursor-pointer">
                        <font-awesome-icon icon="fa-solid fa-times" class="icon" />
                     </div>
                  </div>
                  <div v-if="recorder.hasRecordingEnded" class="w-[50%] rounded-2xl flex items-center justify-center gap-3 bg-[#1F1F1F] px-3 py-2">
                     <div @click="toggleAudio" class="text-white text-head-1 mr-auto cursor-pointer">
                        <font-awesome-icon v-if="!audio.isPlaying" icon="fa-solid fa-play" class="icon" />
                        <font-awesome-icon v-else icon="fa-solid fa-pause" class="icon" />
                     </div>
                     <div class="flex">
                        <img class="w-[25px]" src="/assets/icons/waveform-white.svg" alt="">
                        <img class="w-[25px]" src="/assets/icons/waveform-white.svg" alt="">
                        <img class="w-[25px]" src="/assets/icons/waveform-white.svg" alt="">
                     </div>
                     <div @click="resetAudio" class="text-white text-head-1 ml-auto cursor-pointer">
                        <font-awesome-icon icon="fa-solid fa-times" class="icon" />
                     </div>
                  </div>
                  <div v-else class="w-[50%] overflow-hidden rounded-2xl flex items-center justify-center gap-3 bg-[#1F1F1F] px-3 py-2">
                     <div v-if="!recorder.isRecording" @click="startRecording" class="flex items-center gap-3 cursor-pointer">
                        <img class="h-[18px]" src="/assets/icons/voice.svg" alt="">
                        <div class="text-white text-size-3 mt-[2px]">Add Voice</div>
                     </div>
                     <div v-else @click="stopRecording" class="flex items-center gap-3 cursor-pointer">
                        <img class="h-[18px]" src="/assets/icons/voice.svg" alt="">
                        <div class="text-white text-size-3 mt-[2px]">{{ recorder.recordingTime }} - Stop</div>
                     </div>
                  </div>
               </div>
               <div class="flex justify-between mt-auto border-t border-gray-700 pt-6 gap-4">
                  <div class="flex flex-col">
                     <div v-if="feedbackRequestData" class="flex-1 whitespace-nowrap text-size-3 text-white font-medium">You will earn ${{ feedbackRequestData.totalAmount }}</div>
                     <div v-if="feedbackRequestData" class="flex-1 whitespace-nowrap flex items-center gap-2 text-size-3 text-theme-gray-white-2 font-medium">~{{ feedbackRequestData.delivery }} delivery</div>
                  </div>
                  <div @click="submitFeedback" :class="isBusy ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'" class="w-full bg-theme-blue rounded-full whitespace-nowrap flex items-center justify-center font-medium text-head-3 h-[47px] ml-auto text-white">
                     Share Feedback
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { UserType } from '@/types/user'
import { getAudioBase64, handleApiError, isImageFile } from '@/utils/common'
import Expert from '@/composables/Expert'
import { Recorder } from '@/types/recorder'
import { getMaxAudioLength } from '@/utils/common'
import { NotificationType } from '@/types/common'
import Notification from '@/widgets/Notification.vue'

const props = defineProps(['projectId', 'feedbackRequest'])
const emit = defineEmits(['close', 'reopen', 'success'])
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const isBusy = ref<boolean>(false)
const fileInput = ref<HTMLInputElement | null>(null)
const { getFeedbackRequest, createFeedback } = Expert()
const userData = localStorage.getItem('inFlightAuthUser')
const authUser = userData ? JSON.parse(userData) : null
//const maxAudioLength = getMaxAudioLength()
const maxAudioLength = 10
const errors = ref<any>({
   comment: false
})
let recorder: Recorder = reactive({
   mediaRecorder: null,
   isRecording: false,
   hasRecordingEnded: false,
   audioChunks: [],
   timer: null,
   recordingTime: '00:00',
})
const audio = ref<any>({
   player: null,
   isPlaying: false
})
const feedback = ref<any>({
   comment: '',
   image: {
      data: null,
      name: null
   },
   audio: {
      data: null
   },
   success: null
})
const feedbackRequestData = ref<any>(null)

onMounted(async () => {
   
   navigator.permissions.query({name: 'microphone' as PermissionName}).then(function (permissionObj) {
      
   })
   
   const response = await getFeedbackRequest(props.feedbackRequest)
   if (response.status == 200) {
      feedbackRequestData.value = response.data.feedback
   } else {
      const error = handleApiError(response)
      console.log(error)
   }
})

onBeforeUnmount(() => {
   stopRecording()
})

const validateForm = () : boolean => {

   let success = true
   errors.value.comment = false

   if (feedback.value.comment == '') {
      success = false
      errors.value.comment = true
   }

   return success
}

const submitFeedback = async () => {

   const isValid = validateForm()

   if (isValid && !isBusy.value) {

      isBusy.value = true

      if (recorder.hasRecordingEnded && recorder.audioChunks.length) {
         const { audioBlob, audio } = createAudio()
         feedback.value.audio.data = await getAudioBase64(audioBlob)
      }
      
      const response = await createFeedback(props.feedbackRequest, feedback.value.comment, feedback.value.audio.data, feedback.value.image.data)
      if (response.status == 200) {
         feedback.value.success = true
         emit('success')
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      }

      isBusy.value = false
   }
}

const notifClosed = () => {
   notif.value.show = false
}

const toggleAudio = () => {
   
   if (audio.value.player && audio.value.isPlaying) {
      audio.value.player.pause()
      audio.value.isPlaying = false
   } else {
      const audioBlob = new Blob(recorder.audioChunks)
      const audioUrl = URL.createObjectURL(audioBlob)
      audio.value.player = new Audio(audioUrl)
      audio.value.player.play()
      audio.value.isPlaying = true

      audio.value.player.addEventListener('ended', () => {
         audio.value.isPlaying = false
      })
   }
}

const createAudio = () => {
   const audioBlob = new Blob(recorder.audioChunks)
   const audioUrl = URL.createObjectURL(audioBlob)
   const audio = new Audio(audioUrl)
   
   return {
      audioBlob,
      audio
   }
}

const handleSpaceKey = (event: any) => {
   feedback.value.comment = `${feedback.value.comment} `
   event.preventDefault()
   event.stopPropagation()
}

const handleEnterKey = (event: any) => {
   feedback.value.comment = `${feedback.value.comment}\n`
   event.preventDefault()
   event.stopPropagation()
}

const postStart = () => {
   recorder.isRecording = true
   recorder.hasRecordingEnded = false

   startTimer()
}

const postStop = () => {
   const tracks = recorder.mediaRecorder?.stream.getTracks()
   tracks!!.forEach((track: any) => track.stop())

   recorder.isRecording = false
   recorder.hasRecordingEnded = true

   stopTimer()
}

const startRecording = async () => {
   try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      recorder.mediaRecorder = new MediaRecorder(stream)
      recorder.audioChunks = []

      recorder.mediaRecorder.ondataavailable = (event: any) => {
         if (event.data.size > 0) {
            recorder.audioChunks.push(event.data)
         }
      }

      recorder.mediaRecorder.onstop = () => {
         postStop()
      }

      recorder.mediaRecorder.start()
      postStart()

   } catch (error) {
      console.error('Error starting recording:', error)
   }
}

const stopRecording = () => {
   if (recorder.mediaRecorder && recorder.mediaRecorder.state === 'recording') {
      recorder.mediaRecorder.stop()
   }
}

const startTimer = () => {
   let seconds = 0

   recorder.timer = setInterval(() => {

      if (maxAudioLength && seconds == maxAudioLength) {
         stopRecording()
      } else {
         seconds++
         recorder.recordingTime = formatTime(seconds)
      }
   }, 1000)
}

const stopTimer = () => {
   clearInterval(recorder.timer)
}

const formatTime = (seconds: number) => {
   const minutes = Math.floor(seconds / 60)
   const remainingSeconds = seconds % 60
   return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
}

const resetImage = () => {
   feedback.value.image = {
      data: null,
      name: null
   }
}

const resetAudio = () => {
   recorder.mediaRecorder = null
   recorder.isRecording = false
   recorder.hasRecordingEnded = false
   recorder.audioChunks = []
   recorder.timer = null
   recorder.recordingTime = '00:00'
}

const handleChoosePhoto = (event: any) => {
   fileInput.value?.click()
}

const handlePhotoChoosen = (e: Event) => {

   feedback.value.image = {
      data: null,
      name: null
   }
   const inputElement = e.target as HTMLInputElement
   let error

   if (inputElement && inputElement.files && inputElement.files[0]) {
      const file = inputElement.files[0]
      const reader = new FileReader()

      if (!isImageFile(file)) {
         console.log('File must be an image type (jpg, png, webp)')
         error = true
      } else if (file.size > 20971520) {
         console.log('Image must be 20MB or less')
         error = true
      }

      if (!error) {

         feedback.value.image.name = file.name
         reader.onloadend = () => {
            feedback.value.image.data = reader.result as string
         }
      }

      reader.readAsDataURL(file)
   }
}

</script>

<style scoped>

::-webkit-scrollbar { width: 2px; }
::-webkit-scrollbar-thumb { background: #ffffff;  border-radius: 4px; }
::-webkit-scrollbar-thumb:hover { background: #ffffff; }
::-webkit-scrollbar-track { background: #000000; }

</style>
<template>
   <div class="rounded-3xl bg-white p-3 h-[400px] shadow-custom-symetric-4">
      <div class="flex flex-col flex-grow h-full gap-3">
         <div class="w-full flex-grow h-full bg-gray-100 rounded-3xl"></div>
         <div class="flex items-center gap-2">
            <div class="max-h-[35px] min-h-[35px] min-w-[35px] max-w-[35px] rounded-full bg-gray-200"></div>
            <div class="gap-2 flex flex-col w-full">
               <div class="h-3 w-12 bg-gray-200  w-[35%] rounded-md"></div>
               <div class="h-3 w-16 bg-gray-200 w-[55%] rounded-md"></div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">

</script>
<template>

   <div class="flex flex-col bg-white overflow-x-hidden">
      <div class="flex flex-col w-full mx-auto">
         <header>
            <slot name="header" v-if="showHeader" />
         </header>
         <section class="flex flex-col flex-grow h-full">
            <slot name="content" />
         </section>
      </div>
   </div>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()

const headerRoutes = [
   { name: 'Home', onMobile: true },
   { name: 'HomeNew', onMobile: true },
   { name: 'SiteHome', onMobile: true },
   { name: 'Profile', onMobile: false },
   { name: 'UserList', onMobile: false },
   { name: 'AddUser', onMobile: false },
   { name: 'Education', onMobile: true },
]

const isMobileView = () => {
   const sw = window.innerWidth
   return sw < 768 ? true : false
}

const showHeader = computed(() => {
   const currentRouteName = route.name
   const matchingRoute = headerRoutes.find(route => route.name === currentRouteName)
   if (matchingRoute && (!isMobileView() || (isMobileView() && matchingRoute.onMobile))) {
      return true
   }
   return false
})
</script>
import { getBackendBaseUrl } from "@/utils/common"
import axios from "axios"
import Auth from "./Auth"

const baseUrl : string = getBackendBaseUrl()
const { getToken } = Auth()

const User = () => {

   const getTokens = () => {
      let tokenData = localStorage.getItem('inFlightAuthUserTokens')
      let tokens = tokenData ? JSON.parse(tokenData) : null
      return tokens
   }

   const hasTokens = () => {
      return localStorage.getItem('inFlightAuthUserTokens') !== null
   }

   const getHeaders = () => { 

      const headers : any = { 'Content-Type': 'application/json'}

      if (hasTokens()) {
         headers['Authorization'] = `Bearer ${getTokens().access}`
      }

      return headers
   }

   const getTeammates = async () => {

      try {
         const response = await axios.post(baseUrl + '/user-teammates', {}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.post(baseUrl + '/user-teammates', {}, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }
   
   const createTeammate = async (teammate: string) => {

      try {
         const response = await axios.post(baseUrl + '/create-teammate', { teammate: teammate }, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.post(baseUrl + '/create-teammate', { teammate: teammate }, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   return {
      getTeammates,
      createTeammate
   }
}

export default User
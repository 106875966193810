<template> 
   
   <div class="flex flex-col mx-auto w-full h-[400px] shadow-custom-symetric-4 rounded-3xl">
      <div class="m-3 flex-grow overflow-hidden bg-[#EEF2F4] rounded-3xl pt-6 flex">
         <div :data-artworks="project?.artworks.slice(0, 3).length" class="artworks-contain flex relative max-w-[300px]">
            <div @click="handleProjectDetailsNav(project.number)" v-for="(artwork, ind) in project?.artworks.slice(0, 3)" :key="ind" :data-index="ind" class="each-artwork absolute">
               <div v-if="artwork.type == 'image'">
                  <img  class="rounded-3xl cursor-pointer mx-auto shadow-custom-light artwork-image opacity-0" :src="assetUrl + 'artworks/' + artwork.data + '?tr=w-200,h-300'" alt="status app home">
               </div>
               <div v-else class="flex items-center justify-center">
                  <div v-if="artwork.thumbnail == ''" class="m-auto cursor-pointer rounded-full border border-theme-gray-4 flex items-center justify-center w-[60px] h-[60px]">
                     <font-awesome-icon icon="fa-solid fa-play" class="icon text-black ml-1" size="xl" />
                  </div>
                  <div v-else class="w-[250px] h-[400px]">
                     <img class="rounded-3xl cursor-pointer mx-auto shadow-custom-light artwork-image opacity-0" :src="assetUrl + 'video_thumbnails/' + artwork.thumbnail + '?tr=w-250,h-400'" alt="status app home">
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="m-3 mt-[8px] flex flex-col">
         <div class="flex flex-row items-center gap-3">
            <div class="flex items-start gap-3">
               <div class="min-h-[37px] max-h-[37px] min-w-[37px] max-w-[37px] rounded-full bg-no-repeat bg-cover bg-center" :style="{ backgroundImage: `url(${project.user.image})` }"></div>
               <div class="flex flex-col">
                  <div class="flex flex-row items-center gap-1">
                     <span @click="$router.push({ name: 'ProjectDetails', params: { number: project.number } })" class="text-black text-head-1 whitespace-nowrap font-satoshi-medium cursor-pointer leading-[19px]">
                        {{ truncateText(project.title, 30) }}
                     </span>
                  </div>
                  <div v-if="hasCreator" class="leading-lh-1 text-theme-gray-4 font-satoshi-regular text-size-2 cursor-pointer">
                     {{ truncateText(project.user.name, 25) }}
                  </div>
               </div>
            </div>
            <div class="flex flex-row items-center justify-center ml-auto mr-1 gap-3">
               <div v-if="hasComments" @click="$router.push({ name: 'ProjectDetails', params: { number: project.number } })" class="flex items-center gap-2 ml-auto cursor-pointer">
                  <img class="w-[20px]" src="/assets/icons/comment-rounded.svg" alt="">
                  <span class="text-md text-[#aaaaaa] font-satoshi-medium">{{ project.comments }}</span>
               </div>
            </div>
         </div>
      </div>
      <ModalFit :open="openModal" :hasShadow="true" overlay="dark" @closed="handleModalClose">
         <template v-slot:content>
            <div class="flex flex-col relatiive">
               <ProjectMedia :media="media" :index="activeIndex" @closed="handleModalClose" />
            </div>
         </template>
      </ModalFit>
   </div>

</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/effect-cards'
import { EffectCards } from 'swiper/modules'
import Project from '@/composables/Project'
import ProjectMedia from '@/components/ProjectMedia.vue'
import ModalFit from '@/widgets/ModalFit.vue'
import { encodeString, getAssetBaseUrl, truncateText } from '@/utils/common'
import { useRouter } from 'vue-router'
import { Project as ProjectType } from '@/types/project'
// @ts-ignore
import $ from 'jquery'

const modules = [EffectCards]
const props = defineProps(['project', 'hasComments', 'hasLikes', 'hasCreator'])
const emit = defineEmits(['error'])
const openModal = ref(false)
const media = ref<string[] | null>(null)
const assetUrl : string = getAssetBaseUrl()
const activeIndex = ref<number>(0)
const projectData = ref<ProjectType>(props.project)
const router = useRouter()
const { toggleLike } = Project()
let auth = localStorage.getItem('inFlightAuthUser')
let authUser = JSON.parse(auth as string)
const handleSlideClick = (artworks: string[], index: number) => {

   media.value = artworks
   activeIndex.value = index
   openModal.value = true
}

const handleProjectDetailsNav = (number: string) => {
   
   if (number != '') {
      router.push({ name: 'ProjectDetails', params: { number: number } })
   }
}

const handleLike = async (e: Event) => {
   e.preventDefault()

   const response = await toggleLike(props.project.id)
   if (response.status == 200) {

      projectData.value.likes = response.data.likes
   } else {
      const error = handleApiError(response)
      emit('error', error)
   }
}

const handleApiError = (error : any) => {
   if(error.response){
      const message = error.response.data ? error.response.data.error : error.response.statusText
      return `status code: ${error.response.status} - message: ${message}`
   }

   return error.message
}

const hasUserLiked = () : boolean => {

   const likes = projectData.value.likes
   return authUser && likes?.includes(authUser.id)
}

const handleModalClose = () => openModal.value = false

onMounted(() => {

   setTimeout(() => {

      $('.artwork-image').removeClass('opacity-0')
      $('.artwork-empty-card').remove()
   }, 1000)
})

</script>

<style scoped>

.each-artwork[data-index="0"] {
   z-index: 3;
}

.each-artwork[data-index="1"] {
   z-index: 2;
   transform: translate3d(50px, 40px, -14px);
}

.each-artwork[data-index="2"] {
   z-index: 1;
   transform: translate3d(100px, 80px, -28px);
}

.artworks-contain[data-artworks="1"] {
   min-width: 200px;
   margin: 0 auto;
}

.artworks-contain[data-artworks="2"] {
   min-width: 200px;
   margin: 0 60px;
}

.artworks-contain[data-artworks="3"] {
   min-width: 200px;
   margin: 0 40px;
}

</style>
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
   apiKey: "AIzaSyDGeI7QBNPq4spn2R883QMUIQoYs5VyJtU",
   authDomain: "status-8633a.firebaseapp.com",
   projectId: "status-8633a",
   storageBucket: "status-8633a.appspot.com",
   messagingSenderId: "353658171960",
   appId: "1:353658171960:web:d70fa2187abca792daeccd",
   measurementId: "G-TTEBKHVD2Y"
};
const fb = initializeApp(firebaseConfig)
const db = getFirestore(fb)
const auth = getAuth(fb)

export { fb, db, auth, firebaseConfig }
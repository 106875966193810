<template> 
   
   <div class="flex flex-col items-center justify-start py-8 px-12 bg-[#f2f7ff] max-h-[400px] shadow-custom-symetric-4 rounded-2xl border-[3px] border-dashed border-theme-blue-3">
      <div class="relative flex">
         <div class="relative flex items-center justify-center">
            <img src="/assets/icons/3in1.svg" alt="">
         </div>
      </div>
      <div class="text-center text-black font-satoshi-medium text-head-10 mb-3">{{ title }}</div>
      <div class="text-center text-black mb-8 font-satoshi-regular">
         Upload wireframes, prototypes, landing pages, any of your work that is in flight
      </div>
      <div class="flex items-center bg-theme-blue-3 gap-2 rounded-full px-6 py-3 cursor-pointer">
         <img class="h-[25px]" src="/assets/icons/upload-white-2.svg" alt="">
         <div class="text-white text-sm font-satoshi-regular">Upload work</div>
      </div>
   </div>   

</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

const props = defineProps(['title'])
onMounted(() => {

})

</script>
<template>
   <div class="w-[24rem] rounded-3xl py-8 shadow-custom-symetric bg-white flex flex-col items-start">
      <div class="font-bold text-head-6 mb-2 leading-lh-2 text-center w-full">
         Recent Activity
      </div>
      <div class="flex flex-col w-full divide-y divide-gray-100 max-h-[500px] overflow-y-auto">
         <div v-if="notifications && notifications.length == 0" class="text-center text-md mt-4 text-theme-gray">You have no activities to show</div>
         <div v-else v-for="(notification, index) in notifications" :key="index" class="flex flex-row items-start gap-5 text-black text-size-2 p-8">
            <img class="min-w-[50px] max-w-[50px] h-[50px] rounded-full" :src="notification.avatar" alt="">
            <div class="flex flex-col items-start w-full gap-2">
               <div @click="handleNotificationClick(notification)" class="flex items-start w-full">
                  <div class="font-bold text-head-1 leading-lh-2">{{ notification.heading }}</div>
                  <div class="ml-auto text-theme-gray font-bold whitespace-nowrap">{{ timeElapsedString(notification.createdAt, 1, 'small').replace(/\s*ago|\s/g, '') }}</div>
               </div>
               <div v-if="notification.details" class="text-size-3 font-medium text-theme-gray-4" v-html="notification.details.replace(/\n/g, '<br>')"></div>
               <div v-if="notification.type == 'expert-feedback'" class="flex gap-2">
                  <div @click="notificationSeen(notification)" :class="isBusy ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'" class="flex items-center justify-center bg-theme-gray-3 px-3 rounded-lg">
                     <font-awesome-icon icon="fa-solid fa-times" class="icon text-black text-head-2 text-theme-bright-red" />
                  </div>
                  <div @click="giveFeedback(notification.metaValue, notification.metaValueTwo)" class="bg-theme-blue text-white py-2 px-6 rounded-lg text-size-3 cursor-pointer font-medium">
                     Go to Project
                  </div>
               </div>
               <div v-if="notification.type == 'friend-new-project'" class="flex gap-2">
                  <div @click="$router.push({name: 'ProjectDetails', params: {number: notification.metaValue}})" class="bg-theme-blue text-white py-2 px-6 rounded-lg text-size-3 cursor-pointer font-medium">
                     Go to Project
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang=ts>
import { onMounted, ref } from 'vue'
import { timeElapsedString } from '@/utils/common'
import { useRouter } from 'vue-router'
import { NotificationType } from '@/types/notification'
import User from '@/composables/User'

const router = useRouter()
const props = defineProps(['data'])
const { tagNotificationSeen } = User()
const notifications = ref(props.data)
const isBusy = ref<boolean>(false)

const giveFeedback = (project: string, feedback: string) => {

   router.push({name: 'ProjectDetails', params: {number: project, feedbackRequest: feedback}})
}

const notificationSeen = async (notification: NotificationType) => {

   if (!isBusy.value) {
      
      isBusy.value = true
      const response = await tagNotificationSeen(notification.id)
   
      if (response.status == 200) {
         const index = notifications.value.findIndex((notif: any) => notif.id === notification.id)
         if (index !== -1) {
            notifications.value.splice(index, 1)
         }
      }
   }
}

const handleNotificationClick = (notification: NotificationType) => {
   if (notification.type == 'comment' || notification.type == 'like') {
      router.push({name: 'ProjectDetails', params: {number: notification.metaValue}})
   }
}

</script>

<style scroped>
::-webkit-scrollbar { width: 2px; }
::-webkit-scrollbar-thumb { background: #000000;  border-radius: 4px; }
::-webkit-scrollbar-thumb:hover { background: #000000; }
::-webkit-scrollbar-track { background: #ffffff; }
</style>
import axios from "axios"
import Auth from './Auth'
import { getBackendBaseUrl } from "@/utils/common"
import { ProjectArtwork, ProjectArtworkType } from "@/types/project"

const baseUrl : string = getBackendBaseUrl()
const { getToken } = Auth()

const Project = () => {

   const getTokens = () => {
      let tokenData = localStorage.getItem('inFlightAuthUserTokens')
      let tokens = tokenData ? JSON.parse(tokenData) : null
      return tokens
   }

   const hasTokens = () => {
      return localStorage.getItem('inFlightAuthUserTokens') !== null
   }

   const getHeaders = () => { 
      const headers : any = { 'Content-Type': 'application/json'}

      if (hasTokens()) {
         headers['Authorization'] = `Bearer ${getTokens().access}`
      }

      return headers
   }

   const createProject = async (project : any) => {
      try {
         const response = await axios.post(baseUrl + '/save-project', { title: project.title, isPrivate: project.isPrivate }, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.post(baseUrl + '/save-project', { title: project.title, isPrivate: project.isPrivate }, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const getProject = async (id : string) => {

      try {
         const response = await axios.get(baseUrl + '/get-project?id=' + id, {headers: {}})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.get(baseUrl + '/get-project?id=' + id, {})
            return response
         }else{
            return err
         }
      }
   }

   const getProjects = async () => {

      try {
         const response = await axios.get(baseUrl + '/get-projects', {headers: {}})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.get(baseUrl + '/get-projects', {})
            return response
         }else{
            return err
         }
      }
   }
   
   const getMyProjects = async () => {
      try {
         const response = await axios.get(baseUrl + '/get-my-projects', {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.get(baseUrl + '/get-my-projects', {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const updateProject = async (id : string, title: string, isPrivate: boolean) => {
      try {
         const response = await axios.put(baseUrl + '/save-project', { project: id, title: title, isPrivate: isPrivate }, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.put(baseUrl + '/save-project', { project: id, title: title, isPrivate: isPrivate }, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const uploadProjectArtwork = async (project : string, data: string, type: ProjectArtworkType, order: number, title: string, description: string, width: any, height: any) => {
      try {
         const response = await axios.post(baseUrl + '/upload-project-artwork', { project: project, data: data, type: type, order: order, title: title, description: description, width: width, height: height}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.post(baseUrl + '/upload-project-artwork', { project: project, data: data, type: type, order: order, title: title, description: description}, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }
   
   const updateProjectArtwork = async (project : string, file: string, title: string, description: string, order: number) => {
      try {
         const response = await axios.put(baseUrl + '/update-project-artwork', { project: project, file: file, title: title, description: description, order: order}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.put(baseUrl + '/update-project-artwork', { project: project, file: file, title: title, description: description, order: order}, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const removeArtwork = async (project : string, artwork: string, type: ProjectArtworkType) => {
      try {
         const response = await axios.delete(baseUrl + '/remove-artwork', { headers: getHeaders(), data: { project: project, artwork: artwork, type: type } })
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.delete(baseUrl + '/remove-artwork', { headers: getHeaders(), data: { project: project, artwork: artwork, type: type } })
            return response
         }else{
            return err
         }
      }
   }

   const removeProject = async (project : string) => {
      try {
         const response = await axios.delete(baseUrl + '/remove-project', { headers: getHeaders(), data: { project: project } })
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.delete(baseUrl + '/remove-project', { headers: getHeaders(), data: { project: project } })
            return response
         }else{
            return err
         }
      }
   }

   const uploadProjectAudio = async (project : string, audio: string) => {
      try {
         const response = await axios.put(baseUrl + '/upload-project-audio', { project: project, audio: audio}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.put(baseUrl + '/upload-project-audio', { project: project, audio: audio}, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }
   
   const uploadAudioTemp = async (audio: string) => {
      try {
         const response = await axios.post(baseUrl + '/upload-audio-temp', { audio: audio}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.post(baseUrl + '/upload-audio-temp', { audio: audio}, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const uploadCreateAudioComment = async (project : string, audio: string, comment: string | null) => {
      try {
         const response = await axios.put(baseUrl + '/upload-create-audio-comment', { project: project, audio: audio, comment: comment}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.put(baseUrl + '/upload-create-audio-comment', { project: project, audio: audio}, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const removeProjectAudio = async (project : string) => {
      try {
         const response = await axios.delete(baseUrl + '/remove-project-audio', { headers: getHeaders(), data: { project: project } })
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.delete(baseUrl + '/remove-project-audio', { headers: getHeaders(), data: { project: project } })
            return response
         }else{
            return err
         }
      }
   }
   
   const removeTempAudio = async (filename : string) => {
      try {
         const response = await axios.delete(baseUrl + '/remove-temp-audio', { headers: getHeaders(), data: { filename: filename } })
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.delete(baseUrl + '/remove-temp-audio', { headers: getHeaders(), data: { filename: filename } })
            return response
         }else{
            return err
         }
      }
   }

   const removeComment = async (comment : string) => {
      try {
         const response = await axios.delete(baseUrl + '/remove-comment', { headers: getHeaders(), data: { comment: comment } })
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.delete(baseUrl + '/remove-comment', { headers: getHeaders(), data: { comment: comment } })
            return response
         }else{
            return err
         }
      }
   }

   const uploadCommentImage = async (image : string) => {
      try {
         const response = await axios.put(baseUrl + '/upload-comment-image', { image: image}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.put(baseUrl + '/upload-comment-image', { image: image}, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const toggleLike = async (project : string) => {
      try {
         const response = await axios.post(baseUrl + '/toggle-project-like', { project: project}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.post(baseUrl + '/toggle-project-like', { project: project}, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   return {
      createProject,
      updateProject,
      uploadProjectArtwork,
      updateProjectArtwork,
      removeArtwork,
      removeProject,
      uploadProjectAudio,
      uploadCreateAudioComment,
      removeProjectAudio,
      removeComment,
      uploadCommentImage,
      getProject,
      getProjects,
      getMyProjects,
      toggleLike,
      uploadAudioTemp,
      removeTempAudio
   }
}

export default Project
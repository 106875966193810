<template> 
   <div class="flex flex-col gap-12 justify-center h-screen"> 
      <h1 v-if="platform == 'figma'" class="text-black font-medium text-head-4 text-center">
         {{ success ? 'Connected to Figma' : 'Connect to Figma' }}
      </h1>
      <h1 v-else-if="platform == 'sketch'" class="text-black font-medium text-head-4 text-center">
         {{ success ? 'Connected to Sketch' : 'Connect to Sketch' }}
      </h1>
      <img v-if="!platform || platform == ''" class="w-[9rem] mx-auto mb-6" src="/assets/logo.svg" alt="Application logo" />
      <div v-if="platform == 'figma' || platform == 'sketch'" class="mx-auto max-w-theme flex flex-col items-center gap-12">
         <div class="flex flex-row items-center gap-6">
            <img src="/assets/InFlight.svg" alt="">
            <img class="w-[72px] h-[25px]" src="/assets/spinner-red.gif" alt="">
            <img v-if="platform == 'figma'" class="w-[23px] h-[34px]" src="/assets/figma.png" alt="">
            <img v-else-if="platform == 'sketch'" class="h-[34px]" src="/assets/sketch-icon.png" alt="">
         </div>
         <div v-if="!success" class="text-center">
            Create and update your projects directly<br> in {{ platform }}
         </div>
         <div v-else-if="platform == 'figma'" class="text-center">
            You can now close this tab and head back to Figma
         </div>
         <div v-else-if="platform == 'sketch'" class="text-center">
            You can now close this tab and head back to Sketch
         </div>
      </div>
      
      <div v-if="!success" class="xs:w-[82%] sm:w-[75%] md:max-w-[300px] mx-auto flex flex-col gap-8 text-center" :class="screenHeight > 700 ? 'relative' : 'fixed z-10 sm:bottom-1 lg:bottom-10 left-1/2 transform -translate-x-1/2'">
         <div class="flex flex-col mx-auto w-full">
            <a @click="handleGoogleSignin" class="cursor-pointer bg-black py-3 rounded-lg gap-5 flex flex-row items-center justify-center">
               <img class="w-[20px]" src="/assets/icons/google.svg" alt="sign in with google">
               <span class="text-white">Continue with Google</span>
            </a>
            <div class="text-theme-error text-sm text-center" v-if="error">{{ error }}</div>
         </div>
         <div class="flex items-center justify-between gap-8">
            <div class="border-1 border-t border-theme-gray flex-1"></div>
            <div class="text-black font-satoshi-regular">or sign in with email</div>
            <div class="border-1 border-t border-theme-gray flex-1"></div>
         </div>
         <div class="relative flex flex-col gap-2">
            <input v-model="signinWithEmail.email" class="border border-2 border-theme-gray-darker rounded-xl w-full h-[45px] pl-5 outline-none" type="text" placeholder="Email">
            <div class="text-theme-error text-sm text-left" v-if="signinWithEmail.stepOneError">{{ signinWithEmail.stepOneError }}</div>
         </div>
         <div v-if="signinWithEmail.step == 2" class="relative flex flex-col gap-2">
            <input v-model="signinWithEmail.passcode" class="border border-2 border-theme-gray-darker rounded-xl w-full h-[45px] pl-5 outline-none" type="text" placeholder="Enter code from your email">
            <div class="text-theme-error text-sm text-left" v-if="signinWithEmail.stepTwoError">{{ signinWithEmail.stepTwoError }}</div>
         </div>
         <div v-if="signinWithEmail.step == 1" @click="handleEmailSignin(1)" :class="!signinWithEmail.isBusy ? 'cursor-pointer' : 'cursor-not-allowed opacity-60'" class="text-black border-2 border-theme-gray rounded-full w-12 h-12  mx-auto flex items-center justify-center">
            <font-awesome-icon icon="fa-solid fa-arrow-right" class="icon text-head-1" />
         </div>
         <div v-if="signinWithEmail.step == 2" class="flex flex-col gap-4 items-center justify-center">
            <div @click="handleEmailSignin(2)" :class="!signinWithEmail.isBusy ? 'cursor-pointer' : 'cursor-not-allowed opacity-60'" class="bg-black pt-[0.9rem] pb-[0.8rem] px-10 text-white rounded-3xl">Continue</div>
            <div class="font-bold flex flex-row gap-1 items-center">
               <span @click="handleResendCode" :class="signinWithEmail.resend.isActive ? 'text-theme-blue cursor-pointer' : 'text-theme-gray cursor-not-allowed'">Resend it</span>
               <span v-if="signinWithEmail.resend.time > 0" class="text-theme-gray">(00:{{signinWithEmail.resend.time}})</span>
            </div>
         </div>
      </div>
   </div>
   <PageLoader :show="isLoading" />
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { Error as ErrorType } from '../types/common'
import { Project as ProjectType } from '../types/project'
import PageLoader from '@/widgets/PageLoader.vue'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { useRouter, useRoute } from 'vue-router'
import { fb, db, auth, firebaseConfig } from '../firebase'
import { collection, addDoc } from 'firebase/firestore'
import Project from '@/components/Project.vue'
import User from '@/composables/User'
import { getLoginRedirect, isValidEmail, makeUserAuthorized, removeLoginRedirect } from '@/utils/common'

const props = defineProps(['pairId', 'platform'])
const error = ref<ErrorType>(null)
const router = useRouter()
const route = useRoute()
const pairId = computed(() => route.params.pairId)
const platform = computed(() => route.params.platform)
const success = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const { addUser, handleSigninWithEmail, savePairResponsee } = User()
const signinWithEmail = ref<any>({
   step: 1,
   email: '',
   passcode: '',
   stepOneError: null,
   stepTwoError: null,
   isBusy: false,
   resend: {
      time: 59,
      isActive: false
   }
})
const project : ProjectType = {
   id: '',
   number: 0,
   isPrivate: false,
   title: 'Tinder Paywalls',
   user: { id: '', name: 'Kyle Barber'},
   artworks: [],
   comments: 0,
   updated: 1694521836100
}

const handleGoogleSignin = async () => {

   const auth = getAuth(fb)
   const provider = new GoogleAuthProvider()
   try {
      isLoading.value = true
      const result = await signInWithPopup(auth, provider);
      const user = JSON.stringify(result.user)
      const userObj = JSON.parse(user)

      if (userObj.emailVerified !== true) {
         throw Error('Error: Your email address is not verified at google')
      }

      const newUserResponse = await addUser(userObj.uid, userObj.displayName, userObj.email, userObj.photoURL, null)

      if (newUserResponse.status == 201) {

         const userr = newUserResponse.data.user
         const response = await savePairResponse('successful', userObj.stsTokenManager.accessToken, userObj.stsTokenManager.refreshToken, userr.id)
   
         if(!response){
            throw Error('Error: Could not save pairing response')
         }
   
         saveUserCredentials(userObj, userr)

         if(pairId.value && platform.value){

            isLoading.value = false
            success.value = true
         } else {

            const loginRedirect = getLoginRedirect()
            if (loginRedirect) {
               removeLoginRedirect()
               makeUserAuthorized()
               window.location.href = loginRedirect
            } else {
               router.push({name: 'AdditionalAuthorization'})
            }
         }
      } else {
         
         isLoading.value = false
      }
   } catch (err) {

      isLoading.value = false
      error.value = authError(err)
      await savePairResponse('failed', null, null, null)
   }
}

const handleResendCode = () => {
   if (signinWithEmail.value.resend.isActive) {
      signinWithEmail.value.resend.isActive = false
      handleEmailSignin(1)
   }
}

const handleEmailSignin = async (step : number) => {

   if (!signinWithEmail.value.isBusy) {

      resetForm()
      const error = validateForm(step)

      if (!error) {

         if (step == 1) {
            signinWithEmail.value.step = 2
            startResendTimer()
         } else {
            isLoading.value = true
         }

         signinWithEmail.value.isBusy = true
         const response = await handleSigninWithEmail(step, signinWithEmail.value.email, signinWithEmail.value.passcode)
         signinWithEmail.value.isBusy = false
   
         if (response.status == 200) {
            
            const responsee = response.data
            if (step == 1) {

            } else if (step == 2) {

               const newUserResponse = await addUser(responsee.userObj.uid, responsee.userObj.displayName, responsee.userObj.email, responsee.userObj.photoURL, null)

               if (newUserResponse.status == 201) {

                  const response = await savePairResponse('successful', responsee.userObj.stsTokenManager.accessToken, responsee.userObj.stsTokenManager.refreshToken, responsee.userObj.uid)
   
                  if(!response){
                     throw Error('Error: Could not save pairing response')
                  }

                  const userr = newUserResponse.data.user
                  saveUserCredentials(responsee.userObj, userr)

                  if(pairId.value && platform.value){

                     isLoading.value = false
                     success.value = true
                  } else {

                     const loginRedirect = getLoginRedirect()
                     if (loginRedirect) {
                        removeLoginRedirect()
                        makeUserAuthorized()
                        window.location.href = loginRedirect
                     } else {
                        router.push({name: 'AdditionalAuthorization'})
                     }
                  }
               }
               
               isLoading.value = false
            }
         } else {

            if (step == 1) {

               signinWithEmail.value.step = 1
               signinWithEmail.value.stepOneError = handleApiError(response)
            } else {
               
               signinWithEmail.value.stepTwoError = handleApiError(response)
               isLoading.value = false
            }
         }
      }
   }
}

const resetForm = () => {

   signinWithEmail.value.stepOneError = null
   signinWithEmail.value.stepTwoError = null
}

const validateForm = (step: number) => {

   let error = false

   if (step == 1 && signinWithEmail.value.email == '') {
      signinWithEmail.value.stepOneError = 'Email is required'
      error = true
   } else if (step == 1 && !isValidEmail(signinWithEmail.value.email)) {
      signinWithEmail.value.stepOneError = 'Email is invalid'
      error = true
   } else if (step == 2 && signinWithEmail.value.passcode == '') {
      signinWithEmail.value.stepTwoError = 'Passcode is required'
      error = true
   }
   
   return error
}

const handleSlideClick = (image: string) => {
   console.log(image)
}

const handleApiError = (error : any) => {
   if(error.response){
      const message = error.response.data ? error.response.data.error : error.response.statusText
      return `status code: ${error.response.status} - message: ${message}`
   }

   return error.message
}

const saveUserCredentials = (data : any, user: any) => {
   localStorage.setItem('inFlightAuthUser', JSON.stringify({
      'id' : user.id, 
      'name' : user.name, 
      'email' : user.email, 
      'number' : user.number, 
      'image' : user.photo, 
      'bio' : user.bio, 
      'type' : 'admin'
   }))
   localStorage.setItem('inFlightAuthUserTokens', JSON.stringify({'access' : data.stsTokenManager.accessToken, 'refresh' : data.stsTokenManager.refreshToken}))
}

const authError = (error : any) => {
   if (error.code && error.message) {
      return error.code + ':' + error.message
   } else {
      return error
   }
}

const savePairResponse = async (response: string, token : string | null, refreshToken : string | null, user: string | null) => {
   if(pairId.value && platform.value){

      try {
         const idToken = response == 'successful' ? token : null

         return await savePairResponsee(pairId.value as string, response, idToken, refreshToken, user)
      } catch (error) {
         return false
      }
   }

   return true
}

const screenHeight : any = computed(() => {
   return window.innerHeight
})

const startResendTimer = () => {
   let seconds = 59
 
   const intervalId = setInterval(() => {
      seconds--
      signinWithEmail.value.resend.time = seconds > 9 ? seconds : `0${seconds}`
      if (seconds === 0) {
         signinWithEmail.value.resend.isActive = true
         clearInterval(intervalId)
      }
   }, 1000)
}

onMounted(() => {

})

</script>
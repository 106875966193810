<template>
   
   <div v-if="isLoading" class="animate-pulse grid grid-cols-12 gap-6 my-8 pb-[80px] md:mx-theme-desktop mx-theme">
      <ProjectSkeletonNew class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
      <ProjectSkeletonNew class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
      <ProjectSkeletonNew class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
      <ProjectSkeletonNew class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
   </div>
   <div v-else-if="projects && projects.length">
      <div class="grid grid-cols-12 gap-6 my-8 pb-[80px] md:mx-theme-desktop mx-theme">
         <ProjectStartGrid @click="$router.push({name: 'SaveProject', params: {id: ''}})" title="Get Instant Feedback" class="cursor-pointer col-span-12 lg-2:col-span-3 md-3:col-span-4 md:col-span-6" />
         <ProjectGrid class="col-span-12 lg-2:col-span-3 md-3:col-span-4 md:col-span-6 mb-5" v-for="(project, index) in projects" :key="index" :project="project" :hasComments="true" :hasLikes="true" :hasCreator="true" />
      </div>
   </div>
   <div v-else class="flex flex-col gap-6 pb-[80px]">
      <div class="grid grid-cols-12 gap-6 my-8 pb-[80px] md:mx-theme-desktop mx-theme">
         <ProjectStartGrid title="Start your first project" class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
         <ProjectSkeletonNew class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
         <ProjectSkeletonNew class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
         <ProjectSkeletonNew class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
         <ProjectSkeletonNew class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
         <ProjectSkeletonNew class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
         <ProjectSkeletonNew class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
         <ProjectSkeletonNew class="col-span-12 md-2:col-span-4 md:col-span-6 lg:col-span-3" />
      </div>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import ProjectGrid from '@/components/ProjectGrid.vue'
import ProjectStartGrid from '@/components/ProjectStartGrid.vue'
import ProjectSkeletonNew from '@/skeletons/ProjectSkeletonNew.vue'
import { Project as ProjectType } from '../types/project'
import Project from '@/composables/Project'
import { getFriend, handleApiError, removeFriend } from '@/utils/common'
import UserTemamate from '@/composables/UserTeammate'
import Notification from '@/widgets/Notification.vue'
import { NotificationType } from '@/types/common'

const projects = ref<ProjectType[] | null>(null)
const isLoading = ref<boolean>(true)
const { getProjects, getMyProjects } = Project()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { createTeammate } = UserTemamate()
const friend = ref<any>({
   loading: false
})

const mounted = async () => {

   const friendUserNumber = getFriend()
   if (friendUserNumber) {
      removeFriend()
      friend.value.loading = true
      createTeammate(friendUserNumber).then((response) => {
         friend.value.loading = false
         
         if (response.status == 201) {
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Friend Added Successfully'}
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
         }

         setTimeout(() => notifClosed(), 3000)
      })
   }

   await fetchUserProjects()
   isLoading.value = false
}

const notifClosed = () => {
   notif.value.show = false
}

const fetchUserProjects = async () => {
   projects.value = []

   const response = await getMyProjects()
   if (response.status == 200) {
      projects.value = response.data.projects
   }
}

onMounted(mounted)

</script>
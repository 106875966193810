<template>
   <div class="flex flex-col flex-grow">
      <div class="m-auto bg-transparent">
         <Swiper :slidesPerView="1" :navigation="hasNavigators" :initialSlide="index" :keyboard="{enabled: true }" :modules="modules" class="my-swiper xs:w-screen md:!max-w-screen-2xl">
            <SwiperSlide v-for="(artwork, index) in media" :key="index" v-slot="{isActive}" class="my-auto h-fit">
               <div class="relative w-fit mx-auto">
                  <div @click="closed" class="z-10 absolute shadow-custom-light top-4 left-6 md:left-4 flex items-center justify-center text-center cursor-pointer ml-auto">
                     <div class="rounded-md bg-white w-10 h-10"></div>
                     <div class="absolute flex items-center justify-center">
                        <font-awesome-icon icon="fa-solid fa-xmark" size="lg" class="icon text-theme-coral" />
                     </div>
                  </div>
                  <img v-if="artwork.type == 'image'" @click="toggleNavigators()" class="max-h-screen mx-auto" :src="artwork.source == 'local' ? artwork.data : assetUrl + 'artworks/' + artwork.data" />
                  <div v-else class="px-2 md:px-0">
                     <video controls :autoplay="isActive ? true : false" class="max-h-[600px]" :src="artwork.source == 'local' ? artwork.data : `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/videos%2F${artwork.data}?alt=media`"></video>
                  </div>
               </div>
            </SwiperSlide>
         </Swiper>
      </div>
   </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { EffectFlip, Pagination, Navigation, Mousewheel, Keyboard } from 'swiper/modules'
import { getAssetBaseUrl } from '@/utils/common'
import { fb, db, auth, firebaseConfig } from '@/firebase'

const props = defineProps(['media', 'index', 'hasFullPath'])
const emit = defineEmits(['closed'])
const modules = [Navigation, Pagination, Mousewheel, Keyboard, EffectFlip]
const assetUrl : string = getAssetBaseUrl()
const hasNavigators = ref(false)

const toggleNavigators = () => {
   hasNavigators.value = !hasNavigators.value
}

const closed = () => emit('closed')

onMounted(() => {

})

</script>
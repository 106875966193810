<script setup>
import { onMounted, ref } from "vue";

const props = defineProps({
  values: {
    type: Array,
    required: true,
  },
  typeSpeed: {
    type: Number,
    default: 200,
  },
  delay: {
    type: Number,
    default: 1000,
  },
  eraseSpeed: {
    type: Number,
    default: 100,
  },
  intervals: {
    type: Number,
    default: 500,
  },
  start: {
    type: Number,
    default: 0,
  },
});

const typeValue = ref("");
const arrayIndex = ref(0);
const charIndex = ref(0);

const typewriter = () => {
  if (charIndex.value < props.values[arrayIndex.value].length) {
    typeValue.value += props.values[arrayIndex.value].charAt(charIndex.value);
    charIndex.value++;
    setTimeout(typewriter, props.typeSpeed);
  } else {
    setTimeout(eraser, props.delay);
  }
};
const eraser = () => {
  if (charIndex.value > 0) {
    charIndex.value--;
    typeValue.value = props.values[arrayIndex.value].substring(
      0,
      charIndex.value
    );
    setTimeout(eraser, props.eraseSpeed);
  } else {
    arrayIndex.value += 1;
    if (arrayIndex.value >= props.values.length) arrayIndex.value = 0;
    setTimeout(typewriter, props.typeSpeed + props.intervals);
  }
};

onMounted(() => {
  setTimeout(typewriter, props.start);
});
</script>

<template>
  <div>
    <span>{{ typeValue }}</span>
  </div>
</template>

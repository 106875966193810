<template>
   
   <!-- THREE STEP FEEDBACK WIZARD -->
   
   <div :class="`w-step-${feedback.step}`" class="z-60 fixed h-[95%] md:h-[80%] m-auto top-1/2 transform -translate-y-1/2 rounded-3xl font-satoshi-regular">
      <div class="flex flex-col h-full">
         
         <!-- HEADER BEGIN -->
         <div class="h-[100px] w-full">
            <div v-if="feedback.step == 1" class="h-full flex items-center justify-between px-6 md:px-12 w-full bg-white rounded-tl-3xl rounded-tr-3xl">
               <div :class="openSearch ? 'w-full' : 'w-fit'" class="flex items-center border border-theme-gray-light rounded-full px-4 py-2 h-[45px] gap-2">
                  <input v-model="search" :class="openSearch ? 'animate__animated animate__bounceInRight' : 'hidden md:flex'" class="order-2 outline-none md:min-w-[250px]" type="text" placeholder="Search experts">
                  <img @click="toggleSearch" class="w-[17px] order-1" src="/assets/icons/search.svg" alt="">
               </div>
               <div class="ml-auto gap-2 md:gap-4 flex items-center">
                  <Menu v-if="!openSearch" as="div" class="relative">
                     <MenuButton class="flex items-center justify-center rounded-full text-gray-500 hover:text-gray-600 outline-none">
                        <div class="border border-theme-gray-light h-[45px] px-4 md:px-8 rounded-full flex items-center gap-2 md:gap-6">
                           <div v-if="switchState == 'ai'" class="text-black">AI Feedback</div>
                           <div v-else-if="switchState == 'expert'" class="text-black">Experts</div>
                           <div v-if="switchState == 'teammate'" class="text-black">Teammates</div>
                           <img src="/assets/icons/chev-down.svg" alt="">
                        </div>
                     </MenuButton>
                     <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                        <MenuItems class="absolute translate-y-[5px] translate-x-[0%] right-0 z-10 mt-0.5 w-full origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                           <MenuItem @click="handleSwitchState('expert')" class="hover:bg-gray-100 cursor-pointer px-4 py-2">
                              <div>Experts</div>
                           </MenuItem>
                           <MenuItem @click="handleSwitchState('ai')" class="hover:bg-gray-100 cursor-pointer px-4 py-2">
                              <div>AI Feedback</div>
                           </MenuItem>
                           <MenuItem @click="handleSwitchState('teammate')" class="hover:bg-gray-100 cursor-pointer px-4 py-2">
                              <div>Teammates</div>
                           </MenuItem>
                        </MenuItems>
                     </transition>
                  </Menu>
                  <div @click="handleClose" :class="openSearch ? 'ml-2' : ''" class="cursor-pointer flex items-center justify-center rounded-full bg-white border border-theme-gray-2 min-w-[45px] min-h[45px] max-w-[45px] max-h-[45px] min-h-[45px]">
                     <img class="w-[17px]" src="/assets/icons/cross.svg" alt="back">
                  </div>
               </div>
            </div>
            <div v-else-if="feedback.step == 2" class="w-full relative flex items-center h-full px-6 md:px-12">
               <div @click="feedback.step = 1" class="cursor-pointer absolute left-6 flex items-center justify-center md:rounded-full md:bg-white md:border md:border-theme-gray-2 md:min-w-[45px] md:min-h[45px] md:max-w-[45px] md:max-h-[45px] md:min-h-[45px]">
                  <img class="w-[10px] mr-1" src="/assets/icons/chev-left.svg" alt="back">
               </div>
               <div class="mx-auto font-satoshi-medium text-head-2">Your Feedback Request</div>
            </div>
            <div v-else-if="feedback.step == 3" class="w-full relative flex items-center h-full px-12">
               <div class="mx-auto font-satoshi-medium text-head-2">Requests Sent</div>
            </div>
         </div>
         <!-- HEADER END -->

         <!-- MAIN CONTENT BEGIN -->
         <div class="flex-1 h-full flex-grow overflow-x-hidden overflow-y-auto px-6 py-4 md:p-12">
            <div v-if="feedback.step == 1" class="h-full">
               <div v-if="isLoading" class="grid grid-cols-4 gap-4 md:gap-7">
                  <UserFeedbackSkeleton />
                  <UserFeedbackSkeleton />
                  <UserFeedbackSkeleton />
               </div>
               <div v-else class="h-full">
                  <div v-if="switchState == 'ai' || switchState == 'expert'" class="grid grid-cols-4 gap-4 md:gap-7">
                     <div :class="isAddedInAnotherRole(user) ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer bg-white'" @click="handleUserClick(user)" v-for="user in users" :key="user.id" class="col-span-4 md:col-span-1 p-6 flex flex-col rounded-3xl">
                        <div class="flex flex-row items-center">
                           <div class="flex flex-row gap-3">
                              <div class="md:min-h-[40px] md:max-h-[40px] md:min-w-[40px] md:max-w-[40px] w-[35px] h-[35px] rounded-full bg-no-repeat bg-cover bg-center" :style="{ backgroundImage: `url(${getUserPhoto(user)})` }"></div>
                              <div class="flex flex-col">
                                 <div class="font-satoshi-medium text-head-1 whitespace-nowrap">{{ truncateText(user.name, 20) }}</div>
                                 <div class="text-theme-gray-11 text-size-4">{{ user.subText }}</div>
                              </div>
                           </div>
                           <div v-if="isUserAdded(user)" class="w-[30px] h-[30px] ml-auto bg-theme-blue-3 flex items-center justify-center rounded-full">
                              <img class="w-[16px]" src="/assets/icons/checked.svg" alt="">
                           </div>
                           <div v-else class="w-[30px] h-[30px] ml-auto bg-transparent border border-theme-gray-light flex items-center justify-center rounded-full"></div>
                        </div>
                        <div v-if="user.about" class="mt-6 text-theme-gray-11 text-size-2" v-html="truncateText(user.about, 90)"></div>
                        <div class="flex items-center gap-2 mt-6 overflow-x-hidden whitespace-nowrap">
                           <div v-if="getUserSpecialities(user).length" v-for="speciality in getUserSpecialities(user)" :key="speciality" class="bg-theme-gray-light text-black text-size-2 rounded-full py-2 px-4 whitespace-nowrap mb-[5px]">{{ truncateText(speciality, 25) }}</div> 
                        </div>
                        <div class="flex items-center mt-8">
                           <div class="text-sm md:text-lg flex items-center gap-1">
                              <div v-if="user.rate" class="text-theme-blue-3 font-satoshi-medium">${{ user.rate }}</div>
                              <div v-if="user.deliveryTime">{{ user.deliveryTime }} Delivery</div>
                           </div>
                           <div v-if="user.popularity && user.popularity.rating" class="text-sm md:text-lg flex ml-auto items-start gap-2">
                              <img class="mt-[1px] md:mt-[4px]" src="/assets/icons/star-1.svg" alt="">
                              <div class="font-satoshi-medium">{{ user.popularity.rating }} reviews</div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div v-else class="h-full w-full my-auto">
                     <div v-if="users.length" class="grid grid-cols-4 gap-4 md:gap-7">
                        <div @click="copyInvitationLink" class="relative col-span-4 md:col-span-1 p-6 flex flex-row items-center gap-3 bg-white rounded-3xl cursor-pointer">
                           <div class="min-h-[40px] max-h-[40px] bg-[#D9CEFF] flex items-center justify-center min-w-[40px] max-w-[40px] rounded-full">
                              <img class="w-[19px] ml-1" src="/assets/icons/user-rou.svg" alt="">
                           </div>
                           <div class="flex flex-col">
                              <div class="font-satoshi-medium text-head-1 text-black">Invite Teammates</div>
                              <div class="text-theme-gray-11 text-size-4">{{ invitationLink }}</div>
                           </div>
                           <div class="ml-auto">
                              <img src="/assets/icons/link.svg" alt="">
                           </div>
                           <div id="link-copied" style="display: none" class="absolute top-0 left-0 w-full h-full bg-gray-300 opacity-90 text-black flex items-center justify-center rounded-xl">Link copied</div>
                        </div>
                        <div @click="handleUserClick(user)" v-for="user in users" :key="user.id" class="col-span-4 md:col-span-1 p-6 flex flex-row items-center gap-3 bg-white rounded-3xl cursor-pointer">
                           <div class="min-h-[40px] max-h-[40px] min-w-[40px] max-w-[40px] rounded-full bg-no-repeat bg-cover bg-center" :style="{ backgroundImage: `url(${getUserPhoto(user)})` }"></div>
                           <div class="flex flex-col">
                              <div class="font-satoshi-medium text-head-1 text-black whitespace-nowrap">{{ truncateText(user.name, 20) }}</div>
                           </div>
                           <div v-if="isUserAdded(user)" class="w-[30px] h-[30px] ml-auto bg-theme-blue-3 flex items-center justify-center rounded-full">
                              <img class="w-[16px]" src="/assets/icons/checked.svg" alt="">
                           </div>
                           <div v-else class="w-[30px] h-[30px] ml-auto bg-transparent border border-theme-gray-light flex items-center justify-center rounded-full"></div>
                        </div>
                     </div>
                     <div v-else class="flex flex-col items-center justify-center w-full gap-8 my-auto h-full my-auto">
                        <div class="text-head-2 text-black font-satoshi-medium">Sharing work is better with friends</div>
                        <div class="w-[110px] h-[110px] rounded-full bg-[#F4F1FF] flex items-center justify-center">
                           <div class="w-[80px] h-[80px] rounded-full bg-[#D9CEFF] flex items-center justify-center">
                              <img class="w-[29px] ml-1" src="/assets/icons/user-rou.svg" alt="">
                           </div>
                        </div>
                        <div class="text-size-2 text-theme-gray-11 text-center">Share this link with someone you trust to<br> invite them to your feedback circle</div>
                        <div @click="copyInvitationLink" class="relative border border-theme-blue-3 flex flex-row items-center rounded-xl px-6 py-4 bg-theme-gray-13 gap-6 cursor-pointer">
                           <div class="flex items-center justify-center rounded-full bg-[#CEE1FF] p-2">
                              <img class="w-[12px]" src="/assets/icons/chain.svg" alt="">
                           </div>
                           <div class="text-size-2 text-black">{{ invitationLink }}</div>
                           <div id="link-copied" style="display: none;" class="absolute top-0 left-0 w-full h-full bg-gray-300 opacity-90 text-black flex items-center justify-center rounded-xl">Link copied</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div v-else-if="feedback.step == 2">
               <div class="flex flex-col w-full pb-6 border-b border-theme-gray-2 gap-8">
                  <div v-for="(user, index) in feedback.users" :key="user.user.id" class="flex flex-row items-center">
                     <div class="flex flex-row items-center gap-3">
                        <div class="md:h-[40px] md:w-[40px] w-[35px] h-[35px] rounded-full bg-no-repeat bg-cover bg-center" :style="{ backgroundImage: `url(${getUserPhoto(user.user)})` }"></div>
                        <div class="flex flex-col">
                           <div class="font-satoshi-medium text-head-1 md:text-head-3">{{ user.user.name }}</div>
                           <div class="text-size-4 text-theme-gray-11 leading-lh-small">{{ user.user.subText }}</div>
                        </div>
                     </div>
                     <div class="ml-auto flex flex-col text-right">
                        <div v-if="user.user.rate && user.role == 'expert'" class="font-satoshi-medium text-head-1 md:text-head-2">${{ user.user.rate }}</div>
                        <div v-else class="font-satoshi-medium text-head-1 md:text-head-2">Free</div>
                        <div v-if="user.user.deliveryTime" class="text-size-4 text-theme-gray-11 leading-lh-small">{{ user.user.deliveryTime }} delivery</div>
                     </div>
                  </div>
               </div>
            </div>
            <div v-else-if="feedback.step == 3" class="h-full">
               <div class="flex flex-col items-center justify-center w-full gap-8 my-auto h-full">
                  <div class="text-head-2 text-black font-satoshi-medium">Your feedback is on the way</div>
                  <div class="w-[110px] h-[110px] rounded-full bg-[#ECFDF3] flex items-center justify-center">
                     <div class="w-[80px] h-[80px] rounded-full bg-[#D1FADF] flex items-center justify-center">
                        <img src="/assets/icons/check-icon.svg" alt="">
                     </div>
                  </div>
                  <div class="text-size-2 text-theme-gray-11">We'll notify you when it has landed</div>
               </div>
            </div>
         </div>
         <!-- MAIN CONTENT END -->

         <!-- FOOTER BEGIN -->
         <div v-if="users.length" class="min-h-[100px] px-6 md:px-12 w-full bg-white rounded-bl-3xl rounded-br-3xl shadow-custom-symetric flex">
            <div v-if="feedback.step == 1" class="flex items-center justify-center w-full">
               <div class="relative flex my-auto items-center h-full overflow-x-auto w-[50%] md:w-[80%]">
                  <div v-for="(user, index) in feedback.users" :key="user.user.id" class="h-[45px] w-[45px] rounded-full bg-no-repeat bg-cover bg-center absolute" :style="{ left: `${index == 0 ? '0' : index * 20}px`, backgroundImage: `url(${getUserPhoto(user.user)})` }"></div>
               </div>
               <div class="ml-auto">
                  <button @click="feedback.step = 2" v-bind:disabled="feedback.users.length ? false : true" class="next-btn text-white bg-theme-blue-3 rounded-3xl px-8 py-3">Continue</button>
               </div>
            </div>
            <div v-else-if="feedback.step == 2" class="flex flex-col gap-12 w-full py-8">
               <div>
                  <textarea v-model="feedback.comments" class="w-full resize-none border rounded-xl border-theme-gray-2 p-4 outline-none" rows="5" placeholder="Anything else you want to share about your project?"></textarea>
                  <div v-if="commentError" class="text-sm text-theme-bright-red mt-1 font-medium">Required</div>
               </div>
               <div class="flex flex-row items-center justify-between">
                  <div class="flex flex-col md:flex-row items-center md:gap-4">
                     <div class="text-size-2 text-theme-gray-11">Total</div>
                     <div class="hidden md:flex text-head-5 font-satoshi-medium text-theme-gray-12 strikethrough">${{ feedback.total }}</div>
                     <div class="text-head-2 md:text-head-5 font-satoshi-medium text-black">$0</div>
                  </div>
                  <div class="ml-auto">
                     <button @click="handleFeedbackSubmit" class="text-white bg-theme-blue-3 rounded-3xl px-8 py-3">Get Feedback</button>
                  </div>
               </div>
            </div>
            <div v-if="feedback.step == 3" class="flex items-center justify-center w-full">
               <div class="mx-auto">
                  <button @click="handleClose" class="text-white bg-theme-blue-3 rounded-3xl px-8 py-3">Back to my project</button>
               </div>
            </div>
         </div>
         <!-- FOOTER END -->
      </div>
   </div>

</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { FeedbackCheckout } from '@/types/feedback'
import { UserType, UserRole, CheckoutUser } from '@/types/user'
import { getFirstName, handleApiError, getAssetBaseUrl, shareOnX, truncateText, copyToClipboard, getUserSpecialities } from '@/utils/common'
import Expert from '@/composables/Expert'
import UserTeammate from '@/composables/UserTeammate'
import UserFeedbackSkeleton from '@/skeletons/UserFeedbackSkeleton.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useRouter } from 'vue-router'
// @ts-ignore
import $ from 'jquery'

const props = defineProps(['projectId', 'title'])
const emit = defineEmits(['initFeedback', 'close'])
const auth = localStorage.getItem('inFlightAuthUser')
const authUser = auth ? JSON.parse(auth) : []
const experts = ref<UserType[]>([])
const aiExperts = ref<UserType[]>([])
const teammates = ref<UserType[]>([])
const users = ref<UserType[]>([])
const switchState = ref<UserRole>('ai')
const assetUrl = getAssetBaseUrl()
const isBusy = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const projectUrl = ref<string>(window.location.href)
const openSearch = ref<boolean>(false)
const search = ref<string>()
const router = useRouter()
const { allExperts, allAiExperts } = Expert()
const { getTeammates } = UserTeammate()
const feedback = ref<FeedbackCheckout>({
   step: 1,
   users: [],
   comments: '',
   total: 0,
   response: {
      success: false,
      error: ''
   }
})
const commentError = ref<boolean>(false)

const invitationLink = computed(() => {
   const domain = window.location.hostname
   const port = window.location.port ? `:${window.location.port}` : ''
   const www = domain.includes('localhost') ? '' : ''

   return `${www}${domain}${port}${router.resolve({
      name: 'InviteFriend',
      params: {name: authUser.name.replace(' ', '').toLowerCase(), number: authUser?.number}
   }).href}`
})

const copyInvitationLink = () => {
   copyToClipboard(invitationLink.value)
   
   $('#link-copied').fadeIn()
   setTimeout(() => {

      $('#link-copied').fadeOut()
   }, 1000)
}

const fetchExperts = async () => {
   const response = await allExperts()
   if (response.status == 200) {
      experts.value = response.data.users
   }
}

const toggleSearch = () => {
   if (window.innerWidth < 768) {
      openSearch.value = !openSearch.value
   }
}

const fetchAiExperts = async () => {
   const response = await allAiExperts()
   if (response.status == 200) {
      aiExperts.value = response.data.users
   }
}

const fetchTeammates = async () => {
   const response = await getTeammates()
   if (response.status == 200) {
      teammates.value = response.data.teammates
   }
}

const handleClose = () => {
   emit('close', 1)
}

const handleUserClick = (user: UserType) => {
   
   const index = feedback.value.users.findIndex((u: CheckoutUser) => u.user.id === user.id && u.role == switchState.value)
   if (index !== -1) {
      feedback.value.users.splice(index, 1)
   } else {

      const isUserAlreadyAdded = isAddedInAnotherRole(user)
      if (feedback.value.users.length < 5 && !isUserAlreadyAdded) {
         feedback.value.users.push({ user: user, role: switchState.value })
      }
   }
}

const isUserAdded = (user: UserType) => {

   const index = feedback.value.users.findIndex((u: CheckoutUser) => u.user.id === user.id && u.role == switchState.value)
   return index !== -1
}

const isAddedInAnotherRole = (user: UserType) => {

   const index = feedback.value.users.findIndex((u: CheckoutUser) => u.user.id === user.id && u.role !== switchState.value)
   return index !== -1
}

const getUserPhoto = (user: UserType) => {

   if (user.role == 'ai') {
      return assetUrl + 'ai-experts/' + user.image
   } else if (user.role == 'expert') {
      return assetUrl + 'teammates/' + user.image
   } else {
      return user.image
   }
}

const handleSwitchState = (state: UserRole) => {
   switchState.value = state
}

const handleFeedbackSubmit = () => {

   emit('initFeedback', feedback.value)
   feedback.value.step = 3
}

const roleBasedUsers = (role: UserRole) => {

   if (role == 'expert') {
      return experts.value
   } else if (role == 'ai') {
      return aiExperts.value
   } else if (role == 'teammate') {
      return teammates.value
   } else {
      return []
   }
}

watch([switchState], ([newValue], [oldValue]) => {
   
   users.value = roleBasedUsers(newValue)
})

watch(feedback.value.users, (newValue, oldValue) => {

   feedback.value.total = 0

   feedback.value.users.forEach((user: CheckoutUser) => {
      feedback.value.total += user.user.rate && user.role == 'expert' ? parseFloat(user.user.rate.toString()) : 0
   })
}, { deep: true })

watch(search, (newValue, oldValue) => {

   if (!newValue || newValue == '') {
      users.value = roleBasedUsers(switchState.value)
   } else {
      users.value = roleBasedUsers(switchState.value)
      users.value = users.value.filter((user: UserType) => user.name.toLowerCase().includes(newValue.toLowerCase()) || user.subText?.toLowerCase().includes(newValue.toLowerCase()))
   }
})

onMounted(async () => {

   await fetchExperts()
   await fetchAiExperts()
   await fetchTeammates()
   isLoading.value = false
   
   users.value = roleBasedUsers(switchState.value)
})

</script>

<style scoped>

::-webkit-scrollbar { width: 2px; }
::-webkit-scrollbar-thumb { background: #ffffff;  border-radius: 4px; }
::-webkit-scrollbar-thumb:hover { background: #ffffff; }
::-webkit-scrollbar-track { background: #000000; }

.diagonal-line {
   position: relative;
   display: inline-block;
}

.next-btn:disabled {
   opacity: 0.5;
   cursor: not-allowed;
}

.diagonal-line::before {
   content: '';
   position: absolute;
   top: 50%;
   left: 0;
   right: 0;
   border-top: 1.5px solid white;
   transform: translateY(-50%) rotate(-45deg);
}

@media only screen and (min-width: 768px) {
   .w-step-3 { width: 50%; background: #ffffff; }
   .w-step-2 { width: 50%; background: #ffffff; }
   .w-step-1 { width: 90%; background: #FAFAFA; }
}

@media only screen and (max-width: 767px) {
   .w-step-3 { width: 90%; background: #ffffff; }
   .w-step-2 { width: 90%; background: #ffffff; }
   .w-step-1 { width: 90%; background: #FAFAFA; }
}

.strikethrough { position: relative; }
.strikethrough:before { position: absolute; content: ""; left: 0; top: 50%; right: 0; border-top: 1px solid black; border-color: black; -webkit-transform:rotate(-20deg); -moz-transform:rotate(-20deg); -ms-transform:rotate(-20deg); -o-transform:rotate(-20deg); transform:rotate(-20deg); }

::-webkit-scrollbar { width: 3px; height: 3px; }
::-webkit-scrollbar-track { background: #f1f1f1; }
::-webkit-scrollbar-thumb { background: #888; }
::-webkit-scrollbar-thumb:hover { background: #888; }

</style>
import { getBackendBaseUrl } from "@/utils/common"
import axios from "axios"
import Auth from "./Auth"

const baseUrl : string = getBackendBaseUrl()
const { getToken } = Auth()

const Admin = () => {

   const getTokens = () => {
      let tokenData = localStorage.getItem('inFlightAuthUserTokens')
      let tokens = tokenData ? JSON.parse(tokenData) : null
      return tokens
   }

   const hasTokens = () => {
      return localStorage.getItem('inFlightAuthUserTokens') !== null
   }

   const getHeaders = () => { 

      const headers : any = { 'Content-Type': 'application/json'}

      if (hasTokens()) {
         headers['Authorization'] = `Bearer ${getTokens().access}`
      }

      return headers
   }

   const allUsers = async () => {
      try {
         const response = await axios.get(baseUrl + '/all-users', {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.get(baseUrl + '/all-users', {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }
   
   const allAiExperts = async () => {
      try {
         const response = await axios.get(baseUrl + '/all-ai-experts', {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.get(baseUrl + '/all-ai-experts', {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const updateUser = async (id : string, name: string, photo: string | null, additionalInfo: any) => {
      try {
         const response = await axios.put(baseUrl + '/save-user', { id: id, name: name, photo: photo, additionalInfo: additionalInfo}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.put(baseUrl + '/save-user', { id: id, name: name, photo: photo, additional_info: additionalInfo}, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }
   
   const updateAiExpert = async (id : string, photo: string, name: string, subText: string | null, specialities: string | null, about: string | null, persona: string) => {
      try {
         const response = await axios.put(baseUrl + '/save-ai-expert', { id: id, photo: photo, name: name, subText: subText, specialities: specialities, about: about, persona: persona}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.put(baseUrl + '/save-ai-expert', { id: id, photo: photo, name: name, subText: subText, persona: persona}, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const removeUser = async (id: string) => {
      try {
         const response = await axios.delete(`${baseUrl}/delete-user?id=${id}`, { headers: getHeaders(), data: { } })
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.delete(`${baseUrl}/delete-user?id=${id}`, { headers: getHeaders(), data: { } })
            return response
         }else{
            return err
         }
      }
   }

   return {
      updateUser,
      removeUser,
      allUsers,
      allAiExperts,
      updateAiExpert
   }
}

export default Admin
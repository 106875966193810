<template>
   <div class="md:flex md:h-screen items-start md:gap-5 bg-theme-primary pt-8 p-5 relative overflow-hidden">
      <div class="z-10 relative flex flex-col py-5 bg-white rounded-3xl flex-1 h-full overflow-hidden">
         <div class="flex flex-row items-center justify-between px-5 md:absolute top-5 left-0 w-full mb-5 md:mb-0">
            <div @click="$router.push({name: 'Home'})" class="cursor-pointer flex items-center justify-center rounded-full bg-white border border-theme-gray-2 min-w-[40px] min-h[40px] max-w-[40px] max-h-[40px] min-h-[40px]">
               <img class="w-[17px]" src="/assets/icons/cross.svg" alt="back">
            </div>
            <div v-if="project && authUser && project.user?.id === authUser.id" @click="$router.push({ name: 'SaveProject', params: { id: project.number } })" class="ml-auto cursor-pointer rounded-full bg-white border border-theme-gray-2 min-w-[40px] min-h[40px] max-w-[40px] max-h-[40px] min-h-[40px] flex items-center justify-center">
               <img class="w-[16px]" src="/assets/icons/pencil.svg" alt="edit">
            </div>
            <div v-else @click="handleCopyToClipboard" class="ml-auto h-[23px] cursor-pointer flex flex-col md:flex-row items-end md:items-center">
               <font-awesome-icon icon="fa-solid fa-link" size="lg" class="icon md:order-2" />
               <span v-if="textCopied" class="text-theme-gray-4 md:mr-4 md:order-1">Copied to clipboard</span>
            </div>
         </div>
         <div class="flex flex-col flex-grow md:my-10 xs:px-theme md:px-0 justify-start md:justify-center">
            <div class="relative">
               <swiper :navigation="true" :slidesPerView="'auto'" :keyboard="{enabled: true }" :lazy="true" :mousewheel="true" :spaceBetween="screenWidth == 'mobile' && displayMode == 'browser' ? 15 : 20" :centeredSlides="true" :modules="modulesTwo" @slideChange="onSlideChange" class="my-swiper flex items-center">
                  <swiper-slide v-for="(artwork, index) in project?.artworks" :key="index" v-slot="{isActive}" class="w-auto flex justify-center">
                     <ArtworkFront :canRemove="false" :artwork="artwork" :isActive="isActive" :artworks="project?.artworks" :artworkIndex="index" @clicked="handleSlideClick" :screenWidth="screenWidth" />
                  </swiper-slide>
               </swiper>
            </div>
         </div>
         <div class="flex flex-row items-center justify-between w-full px-6 z-10 mt-6 md:mt-0 md:absolute bottom-5 left-0">
            <div class="flex flex-col items-start w-full">
               <div class="flex w-full items-center justify-between">
                  <div class="flex flex-row items-start gap-3">
                     <div @click="handleSlideMove('back')" class="flex items-center justify-center cursor-pointer rounded-full bg-white border border-theme-gray min-w-[40px] min-h[40px] max-w-[40px] max-h-[40px] min-h-[40px]">
                        <img class="transform rotate-180" src="/assets/icons/arrow-right.svg" alt="">
                     </div>
                     <div @click="handleSlideMove('next')" class="flex items-center justify-center cursor-pointer rounded-full bg-white border border-theme-gray min-w-[40px] min-h[40px] max-w-[40px] max-h-[40px] min-h-[40px]">
                        <img class="" src="/assets/icons/arrow-right.svg" alt="">
                     </div>
                  </div>
                  <div @click="toggleComments" class="md:flex hidden items-center justify-center cursor-pointer rounded-full bg-white border border-theme-gray min-w-[40px] min-h[40px] max-w-[40px] max-h-[40px] min-h-[40px]">
                     <img class="" :src="openComments ? '/assets/icons/resize-full.svg' : '/assets/icons/resize-less.svg'" alt="">
                  </div>
               </div>
            </div>
         </div>
         <ModalFit :open="openModal" :hasShadow="false" overlay="dark" @closed="handleModalClose">
            <template v-slot:content>
               <div class="flex flex-col relative">
                  <ProjectMedia :media="media" :index="activeIndex" :hasFullPath="false" @closed="handleModalClose" />
               </div>
            </template>
         </ModalFit>
         <ConfirmationModal :show="modal" @closed="modalClosed">
            <template v-slot:buttons>
               <button @click="askingConfirmed" type="button" :class="isBusy ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer'" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">Yes, continue</button>
               <button @click="askingDeclined" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">Cancel</button>
            </template>
         </ConfirmationModal>
      </div>
      <div :class="openComments === false ? 'md:w-[0px] md:opacity-0' : 'comment-expand opacity-1'" class="z-20 h-full transition-all delay-100 mt-5 md:mt-0">
         <CommentBox :creator="project?.user" :project="project" :activeArtwork="activeArtwork" :feedbackRequest="feedbackRequest" @initFeedback="initializeFeedback" @giveFeedback="provideFeedback" @close="openComments = false" @success="handleCommentSuccess" @error="handleCommentError" />
      </div>
      <div v-if="project && (showFeedback || feedbackMobileClicked)" class="absolute top-0 w-screen h-screen bottom-0 left-0 right-0 bottom-6 z-50 flex items-center justify-center">
         <div @click="closeFeedback" class="fixed inset-0 bg-gray-400 opacity-60 transition-opacity"></div>
         <div class="flex flex-col items-center z-[60] w-full h-full m-12">
            <ProjectFeedbackNew :projectId="project.id" @close="closeFeedback" @initFeedback="handleInitFeedback" />
         </div>
      </div>
      <div v-if="project && giveFeedback" class="absolute top-0 w-screen h-screen bottom-0 left-0 right-0 bottom-6 z-50 flex items-center justify-center">
         <div @click="giveFeedback = false" class="fixed inset-0 bg-gray-400 opacity-60 transition-opacity"></div>
         <div class="flex flex-col gap-4 items-center z-[60] w-full m-12">
            <GiveFeedback :projectId="project.id" :feedbackRequest="feedbackRequest" @success="handleFeedbackSuccess" @close="closeMenu" />
            <div @click="giveFeedback = false" class=" flex w-[50px] h-[50px] items-center justify-center rounded-full bg-theme-gray-darker cursor-pointer">
               <font-awesome-icon icon="fa-solid fa-xmark" class="icon text-white text-head-5" />
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { copyToClipboard, disableScroll, enableScroll } from '@/utils/common'
import { ProjectArtwork, Project as ProjectType } from '@/types/project'
import { Navigation, FreeMode, Pagination, Keyboard, Mousewheel } from 'swiper/modules'
import ArtworkFront from '@/components/ArtworkFront.vue'
import ProjectMedia from '@/components/ProjectMedia.vue'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import ModalFit from '@/widgets/ModalFit.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/effect-cards'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { NotificationType } from '@/types/common'
import CommentBox from '@/components/CommentBox.vue'
import ProjectFeedbackNew from '@/components/ProjectFeedbackNew.vue'
import GiveFeedback from '@/components/GiveFeedback.vue'
import Project from '@/composables/Project'
import { DEFAULT_FEEDBACK_COMMENT } from '@/utils/constants'

// @ts-ignore
import $ from 'jquery'
import { db, firebaseConfig } from '@/firebase'
import { useRouter } from 'vue-router'
import Expert from '@/composables/Expert'
import { FeedbackCheckout } from '@/types/feedback'
const props = defineProps(['number', 'feedbackRequest'])
const textCopied = ref<boolean>(false)
const userData = localStorage.getItem('inFlightAuthUser')
const authUser = userData ? JSON.parse(userData) : null
const project = ref<ProjectType | null>(null)
const activeArtwork = ref<ProjectArtwork>()
const displayMode = ref<string>('browser')
const modulesTwo = [Keyboard, Mousewheel, FreeMode, Pagination, Navigation]
const isBusy = ref<boolean>(false)
const openModal = ref(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { getProject, toggleLike, removeArtwork, removeProject } = Project()
const media = ref<ProjectArtwork[]>()
const activeIndex = ref<number>(0)
const isLoading = ref<boolean>(true)
const del = ref<string | null>(null)
const hasFullDescription = ref<boolean>(false)
const router = useRouter()
const modal = ref<boolean>(false)
const openComments = ref<boolean>(window.innerWidth < 768 ? false : true)
const showFeedback = ref<boolean>(false)
const giveFeedback = ref<boolean>(false)
const feedbackMobileClicked = ref<boolean>(false)
const { sendFeedbackRequest } = Expert()
const audio = ref<any>({
   player: null,
   isPLaying: false
})

const handleCopyToClipboard = () => {
   copyToClipboard(window.location.href)
   animateCopyText()
}

const handleCommentSuccess = async () => {
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Your comment has been added'}
   await fetchProject()
   setTimeout(() => { notifClosed() }, 2000)
}

const handleFeedbackSuccess = async () => {
   await fetchProject()
}

const closeFeedback = () => {
   feedbackMobileClicked.value = false
   showFeedback.value = false
   enableScroll()
}

const initializeFeedback = () => {
   showFeedback.value = true
   disableScroll()
}

const provideFeedback = () => {
   giveFeedback.value = true
}

const toggleComments = () => {

   if (screenWidth.value == 'mobile') {
      openComments.value = !openComments.value
   } else {
      openComments.value = !openComments.value
   }
}

const handleInitFeedback = async (feedback: FeedbackCheckout) => {
   
   const comments = feedback.comments == '' ? DEFAULT_FEEDBACK_COMMENT : feedback.comments
   const response = await sendFeedbackRequest(project.value?.id as string, feedback.users, comments)
   if (response.status !== 201) {
      const error = handleApiError(response)
   } else {
      handleFeedbackSuccess()
   }
}

const closeMenu = () => {
   $('#menuBtn').trigger('click')
}

const animateCopyText = () => {

   textCopied.value = true
   setTimeout(() => {
      textCopied.value = false
   }, 2000)
}

const handleCommentError = (error: string) => {
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error }
   setTimeout(() => { notifClosed() }, 2000)
}

const askConfirmation = (type: string) => {
   del.value = type
   modal.value = true
}

const askingConfirmed = async () => {
   if (del.value && !isBusy.value && project.value) {
      if (del.value == 'media' && activeArtwork.value) {
         isBusy.value = true
         const response = await removeArtwork(project.value.id, activeArtwork.value.data, activeArtwork.value.type)
         
         if (response.status == 204) {
            mounted()
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Media removed successfully'}
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error as string}
         }

         setTimeout(() => { notifClosed() }, 3000)
         isBusy.value = false
      } else if (del.value == 'project') {
         isBusy.value = true
         const response = await removeProject(project.value.id)
         
         if (response.status == 204) {
            router.push({name: 'Home'})
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error as string}
         }

         setTimeout(() => { notifClosed() }, 3000)
         isBusy.value = false
      }

      modalClosed()
      del.value = null
   }
}

const handleSlideMove = (action: string) => {

   if (action == 'back') {
      $('.swiper-button-prev').click()
   } else if (action == 'next') {
      $('.swiper-button-next').click()
   }
}

const handleReadMoreBtn = () => {

   hasFullDescription.value = !hasFullDescription.value
}

const askingDeclined = () => {
   modal.value = false
   del.value = null
}

const modalClosed = () => {
   modal.value = false
}

const handleSlideClick = (artworks: ProjectArtwork[], index: number) => {

   media.value = artworks
   activeIndex.value = index
   openModal.value = true
}

const toggleAudio = () => {

   if (audio.value.player && audio.value.isPlaying) {
      audio.value.player.pause()
      audio.value.isPlaying = false
   } else {

      const source = project.value?.audio ? `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/audios%2F${project.value.audio}?alt=media` : null
      if (source) {

         if (!audio.value.player) {
            audio.value.player = new Audio(source)
         }
         
         audio.value.player.play()
         audio.value.isPlaying = true

         audio.value.player.addEventListener('ended', () => {
            audio.value.isPlaying = false
         })
      }
   }
}

const handleLike = async (e: Event) => {

   if (authUser) {
      if (!isBusy.value) {
         e.preventDefault()

         isBusy.value = true
         const response = await toggleLike(project.value?.id as string)
         isBusy.value = false

         if (response.status == 200) {
            if (project.value) {
               project.value.likes = response.data.likes
            }
         } else { console.log('error')
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error as string}
         }
      }
   } else {
      alert('you are not logged in')
   }
}

const hasUserLiked = () : boolean => {

   const likes = project.value?.likes
   return authUser && likes?.includes(authUser.id)
}

const screenWidth = computed(() => {
   const screenWidth = window.innerWidth;
  
   if (screenWidth < 768) {
      return 'mobile'
   } else if (screenWidth >= 768 && screenWidth <= 1260) {
      return 'medium-1'
   } else if (screenWidth >= 1261 && screenWidth <= 1650) {
      return 'medium-2'
   } else {
      return 'large'
   }
})

const onSlideChange = (swiper: any) => {

   $('.slider-img-holder').addClass('custom-transition')

   const activeIndex = swiper.activeIndex
   activeArtwork.value = project.value?.artworks.find((artwork: ProjectArtwork, index: number) => index === activeIndex)
}

const fetchProject = async () => {
   try {

      const response = await getProject(props.number)
      if (response.status == 200) {
         project.value = response.data.project
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error as string}
         setTimeout(() => { notifClosed() }, 2000)
      }
   } catch (error) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error as string }
      setTimeout(() => { notifClosed() }, 2000)
   }

   isLoading.value = false
}

const notifClosed = () => {
   notif.value.show = false
}

const handleApiError = (error : any) => {
   if(error.response){
      const message = error.response.data ? error.response.data.error : error.response.statusText
      return `status code: ${error.response.status} - message: ${message}`
   }

   return error.message
}

const handleModalClose = () => openModal.value = false

const mounted = async () => {
   
   if (window.matchMedia('(display-mode: standalone)').matches) {
      displayMode.value = 'standalone'
   }

   await fetchProject()

   if (project.value?.artworks.length) {
      activeArtwork.value = project.value?.artworks[0]
   }
}

onMounted(mounted)

</script>

<style scoped>
   .my-swiper, .my-swiper .swiper-slide { overflow: visible; }
   .my-swiper .swiper-slide { height: auto; }
   .animate__animated.animate__fadeInUp { animation-duration: 0.3s; }

   .h-screen-custom-two { min-height: calc(100vh - 60px); }

   @media only screen and (min-width: 768px) {
      .swiper-slide:has(~ .swiper-slide-active){
         opacity: 0.3;
      }

      .swiper-slide-active ~ .swiper-slide {
         opacity: 0.3;
      }

      .comment-expand { width: 20%; }
   }

   @media only screen and (max-width: 767px) {
   
      .comment-expand { width: 100%; }
   }

   :root {
      --animate-duration: 800ms;
      --animate-delay: 0.9s;
   }
</style>
<template>
   <div class="homeNewPage font-satoshi-regular">
     <div class="gradientMask"></div>
     <div :class="['flex justify-center absolute left-1/2 transform -translate-x-1/2 transition-top ease-in-out duration-500 z-50 hidden lg:block',animateBanner ? 'top-[30px]' : 'top-[-100px]',]">
       <div class="bg-black mx-auto w-[590px] h-[64px] rounded-2xl py-3 px-7 flex items-center justify-between">
         <img alt="" class="h-[22px] w-[22px]" src="/assets/images/redsirren.png" />
         <p class="text-white font-bold text-base">
           New Release: Upload designs directly from Figma
         </p>
         <button @click="handleDownload" class="bg-white py-3 px-5 text-sm flex justify-center items-center rounded-full font-medium">
           <img alt="" class="h-[20px] mr-3" src="/assets/images/figma.svg" />
           Download
         </button>
         <button @click="animateBanner = false">
           <img alt="" class="min-w-[10px]" src="/assets/images/cross.png" />
         </button>
       </div>
     </div>
     <div class="grid grid-cols-6 mt-20 lg:mx-20 md-2:mx-16 mx-5">
       <div class="md-2:col-start-1 md-2:col-end-4 col-span-6 md-2:pt-[30%] lg:pt-[25%]">
         <button class="flex gap-1.5 md-2:px-6 md-2:py-3.5 py-2 px-4 text-base leading-4 text-white whitespace-nowrap bg-blue-600 rounded-full">
           <img alt="..." class="aspect-square md-2:w-[25px] md-2:h-[25px] w-[15px] h-[15px]" loading="lazy" src="/assets/images/button-icon.png" />
           <span class="grow my-auto md-2:text-[15px] text-[10px] font-normal">POWERED BY AI</span>
         </button>
         <div class="w-full lg:mt-8 mt-4">
           <div class="lg:text-[120px] md-2:text-[100px] text-[54px] font-normal tracking-tight lg:leading-[150px] md-2:leading-[110px] leading-[74px] bg-white relative">
             Instant
             <span class="text-blue-500 inline-flex whitespace-nowrap overflow-hidden">
               <TextWriter :values="['design', 'product', 'UI', 'UX']" class="absolute top-0" />
             </span>
             <br />
             feedback.
           </div>
         </div>
       </div>
       <div class="md-2:col-start-4 md-2:col-end-7 col-span-6 lg:my-0 md-2:my-10 my-20 md-2:py-0 py-20 relative gradientMaskSm">
         <div class="bg-no-repeat bg-center md-2:bg-right lg:bg-center bg-contain hovertableBg">
           <img alt="..." class="opacity-0 md-2:w-[220px] md-2:h-[350px] lg:w-fit lg:h-fit" src="/assets/images/hovertablebg.png" />
           <HovertableMessageWithoutImage imageUrl="/assets/images/aly-kima.png" class="float-right lg:right-20 md-2:right-0 right-32 bottom-[90%] anime-card-1 absolute" message="Icon Specialist" name="Aly Kima" />
           <HovertableMessage
             :bodyClass="[
               animeCard2Completed ? 'bg-blue-600' : 'bg-black',
               {
                 'lg:w-[317px] w-[230px]': animeCard2Completed,
                 'lg:w-[260px] w-[180px]': !animeCard2Completed,
                 'max-h-[70px]': !animeCard2Completed,
                 'lg:max-h-[160px] max-h-[200px]': animeCard2Completed,
               },
             ]"
             imageUrl="/assets/images/top-right.png"
             :isVector="true"
             :message="
               animeCard2Completed
                 ? 'The alignment of these icons feels a bit off, maybe try more spacing as well?'
                 : ''
             "
             btnClass="text-blue-600 bg-white" btnText="Expert" class="md-2:bottom-[60%] bottom-[40%] lg:left-[-6%] md-2:left-0 left-0 anime-card-2 absolute" description="Senior Designer, Meta" name="Jacob K" />
           <HovertableMessageWithoutImage imageUrl="/assets/images/veer.png" :isVectorTop="true" class="absolute lg:bottom-0 lg:right-20 md-2:right-8 right-32 bottom-[-10%] anime-card-3" message="Typography Expert" name="Veer G" />
         </div>
       </div>
     </div>
 
     <div class="grid grid-cols-2 md-2:mt-20 relative z-40">
       <div class="md-2:bg-[#F9F9F9] lg:col-span-1 col-span-2 border-r-2 border-[#EEEEEE] pb-10 pt-14">
         <div class="text-center md-2:text-[36px] text-[22px] font-normal text-[#414141]">
           <span class="opacity-[52%]">An extra</span>
           <span class="opacity-100"> set of eyes </span>
           <span class="opacity-[52%]">on your work</span>
         </div>
         <div class="relative mt-44 lg:mx-0 md-2:mx-56 mx-0">
           <div class="ellipsisBg"></div>
           <div>
             <div class="flex gap-0 items-start justify-center p-2">
               <div class="p-4 rounded-2xl bg-[#131313] md-2:max-w-[490px] max-w-[311px] absolute -top-32 m-2 lg:m-0">
                 <div class="p-4 rounded-2xl shadow-lg bg-[#1F1F1F] text-white" style="transition: width 0.5s ease-in-out, max-height 0.5s ease-in-out;">
                   <div class="flex md-2:gap-5 gap-3">
                     <img src="/assets/images/kyle.png" alt="..." class="md-2:w-[42px] md-2:h-[42px] w-[38px] h-[38px] aspect-square md-2:mt-2 mt-1" loading="lazy" />
                     <div
                       class="lg:text-[22px] text-[14px] font-normal md-2:leading-[41px] leading-[26px] tracking-tighter md-2:tracking-normal text-opacity-50">
                       I'm stuck on this landing page design, can anyone help?
                     </div>
                   </div>
                 </div>ƒv
               </div>
             </div>
 
             <div ref="sectionRef" class="section pt-40 lg:mt-20">
               <div class="flex lg:justify-between justify-around mx-4">
                 <img :ref="setImgRef(1)" src="/assets/images/top-left.png" alt="..." class="aspect-square popout-image -mt-28 md-2:ml-28 -ml-12 md-2:w-[70px] md-2:h-[70px] w-[55px] h-[55px]" loading="lazy" />
                 <img :ref="setImgRef(2)" src="/assets/images/top-center.png" alt="..." class="aspect-square popout-image md-2:-mt-24 -mt-24 md-2:ml-12 ml-16 md-2:w-[110px] md-2:h-[110px] w-[86px] h-[86px]" loading="lazy" />
                 <img :ref="setImgRef(3)" src="/assets/images/top-right.png" alt="..." class="aspect-square popout-image -mt-16 lg:mr-16 md-2:w-[110px] md-2:h-[110px] w-[73px] h-[73px]" loading="lazy" />
               </div>
               <div class="flex justify-around mt-14">
                 <img :ref="setImgRef(4)" src="/assets/images/bottom-left.png" alt="..." class="aspect-square popout-image md-2:-mt-24 md-2:ml-20 -mt-16 w-[68px] h-[68px] md-2:w-[102px] md-2:h-[102px]" loading="lazy" />
                 <img :ref="setImgRef(5)" src="/assets/images/bottom-right.png" alt="..." class="aspect-square popout-image md-2:-mt-12 md-2:mr-28 -mt-12 mr-12 md-2:w-[78px] md-2:h-[78px] w-[42px] h-[42px]" loading="lazy" />
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="bg-[#F9FAFF] pb-10 md-2:pt-16 pt-12 lg:col-span-1 col-span-2 md-2:px-10">
         <div class="text-center md-2:text-[36px] text-[22px] font-normal text-[#414141]">
           <span class="opacity-[52%]">Your design </span>co-pilot
         </div>
         <div class="md-2:max-w-[550px] max-w-[355px] mx-auto md-2:my-20 my-12">
           <ToolsCards iconUrl="/assets/images/tools-icon-1.svg" title="Upload work in progress" />
           <ToolsCards iconUrl="/assets/images/tools-icon-2.svg" title="Get instant design feedback" />
           <ToolsCards iconUrl="/assets/images/tools-icon-3.png" :isVerticalLineBelow="false" title="Improve, ship, repeat" />
         </div>
       </div>
     </div>
 
     <div class="lg:mt-10 lg:pt-10 lg:mx-20 md-2:mx-16">
       <video poster="/assets/images/vidthumbnail.png" class="w-full h-full md-2:rounded-3xl" controls preload="none" src="/assets/videos/Inflight_Rough.mp4" />
     </div>
 
     <div class="md-2:my-24 my-16 font-thin m-2 lg:mx-20 md-2:mx-16 mx-5">
       <div class="text-center md-2:text-[50px] font-normal text-[24px] text-[#777777]">
         A design critique in your pocket.
       </div>
       <div class="text-center md-2:text-[32px] text-[14px] text-black opacity-[39%] md-2:mt-6 mt-3">
         Add jet fuel to your work at a fraction of the time and cost
       </div>
     </div>
 
     <div class="lg:border-t-2 lg:border-[#EBEBEB]">
       <div class="lg:mx-20 md-2:mx-16 mx-5 md-2:py-12 py-8">
         <div class="flex justify-between items-center">
           <div>
             <a @click="$router.push({ name: 'SiteHome' })" href="javascript:void(0)">
               <img alt="Logo" class="h-8 w-auto cursor-pointer" src="/assets/images/logo.png" />
             </a>
           </div>
           <div class="flex items-center">
             <div class="justify-center px-8 py-4 text-[13px] tracking-tight leading-4 text-black whitespace-nowrap bg-zinc-100 rounded-[999px] cursor-pointer" @click="$router.push({ name: 'SignIn' })">
               Log In
             </div>
             <div class="justify-center px-7 py-4 text-[13px] tracking-tight leading-4 text-white whitespace-nowrap bg-blue-600 rounded-full ml-2 cursor-pointer hidden md-2:block" @click="$router.push({ name: 'Apply' })">
               Sign Up
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </template>
 
 <script lang="ts" setup>
import HovertableMessageWithoutImage from "@/components/HovertableMessageWithoutImage.vue"
import HovertableMessage from "@/components/HovertableMessage.vue"
import ToolsCards from "@/components/ToolsCards.vue"
// @ts-ignore
import anime from "animejs/lib/anime.es.js"
import { onMounted, ref } from "vue"
import TextWriter from "@/components/textWriter.vue"

const animeCard2Completed = ref<any>(false)
const animateBanner = ref(false)

const animeCards = () => {
   anime({
      targets: ".anime-card-1",
      translateY: [0, 100, 130],
      translateX: [0, 50, 50],
      direction: "alternate",
      loop: true,
      easing: "easeInOutSine",
      duration: 5000,
   })

   anime({
      targets: ".anime-card-2",
      translateY: -150,
      translateX: 50,
      direction: "alternate",
      loop: 1,
      easing: "easeInOutSine",
      duration: 5000,
      complete: function () {
      animeCard2Completed.value = true
      },
   })

   anime({
      targets: ".anime-card-3",
      translateY: -200,
      translateX: 50,
      direction: "alternate",
      loop: true,
      easing: "easeInOutSine",
      duration: 4000,
   })
}

onMounted(() => {
   animeCards()
})

const sectionRef = ref<any>(null)
const imgRefs = ref<any>([])
const hasEntered = ref<any>(false)

const setImgRef = (index: any) => (el: any) => {
   imgRefs.value[index] = el
}

const handleScroll = () => {
   const section = sectionRef.value
   if (!section || hasEntered.value) return

   const sectionTop = section.getBoundingClientRect().top
   const windowHeight = window.innerHeight

   if (sectionTop < windowHeight) {
      imgRefs.value.forEach((imgRef : any, index: any) => {
      setTimeout(() => {
         anime({
            targets: imgRef,
            scaleX: 1,
            scaleY: 1,
            duration: 500,
            easing: "easeInOutQuad",
         })
      }, 200 * index)
      })
      hasEntered.value = true
      window.removeEventListener("scroll", handleScroll)
   }
}

const handleDownload = () => {

   window.location.href = 'https://www.figma.com/community/plugin/1367643619220298603/inflight-instant-design-feedback'
}

onMounted(() => {
   window.addEventListener("scroll", handleScroll)
   handleScroll()

   setTimeout(() => {
      animateBanner.value = true
   }, 3000)
})
</script>
 
<style lang="scss" scoped>
.homeNewPage {
   .gradientMask {
      @media (min-width: 1024px) {
         position: absolute;
         top: 0;
         right: 0;
         height: 95%;
         width: 80%;
         opacity: 1;
         background-image: url("../../public/assets/images/industry-experts-ai-bg.png");
         background-position: right top;
         z-index: 0;
         mask-image: linear-gradient(to right, transparent, white);
      }
   }

   .section {
      width: 100%;
      overflow: hidden;
   }

   .popout-image {
      transform: scale(0);
      transition: transform 0.5s ease-in-out;
   }

   .gradientMaskSm {
      @media (max-width: 1023px) {
         background: url("../../public/assets/images/industry-experts-ai-bg.png") center
         bottom;
      }
   }

   .industryExpertsBg {
      background: url("../../public/assets/images/industry-experts-ai-bg.png");
   }

   .ellipsisBg {
      background: url("../../public/assets/images/ellipsis.png") center top no-repeat;
      background-size: cover;
      opacity: 0.25;
      position: absolute;
      width: 100%;
      height: 100%;
      /*object-fit: cover;*/
   }

   .hovertableBg {
      background-image: url("../../public/assets/images/hovertablebg.png");
   }
}
</style>
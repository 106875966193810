<template>

   <div class="font-regular mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12">
      <div class="mx-auto max-w-3xl">
         <div class="space-y-12">
            <div class="border-b border-gray-900/10 pb-12">
               <h2 class="text-xl font-semibold leading-7 text-gray-900">ChatGPT 4.0 vision preview example</h2>
               <p v-if="currentExpert" class="mt-1 text-sm leading-6 text-gray-600">Persona: {{ currentExpert.persona }}</p>

               <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="col-span-full">
                     <label for="url" class="block text-md font-medium leading-6 text-gray-900">Select AI Expert</label>
                     <div class="mt-2">
                        <div class="flex rounded-md shadow-sm border ring-0 border-gray-300 outline-none w-full">
                           <select v-model="currentExpert" class="w-full h-[30px] bg-transparent border-gray-300 outline-none">
                              <option v-for="aiExpert in aiExperts" :key="aiExpert.id" :value="aiExpert">{{ aiExpert.name }} ({{ aiExpert.subText }})</option>
                           </select>
                        </div>
                     </div>
                  </div>
                  <div class="col-span-full">
                     <label for="url" class="block text-md font-medium leading-6 text-gray-900">Image URL</label>
                     <div class="mt-2">
                        <div class="flex rounded-md shadow-sm border ring-0 border-gray-300 outline-none w-full">
                           <input v-model="form.imageUrl" type="text" id="url" autocomplete="url" class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 outline-none pl-3 sm:text-sm sm:leading-6" />
                        </div>
                     </div>
                  </div>
                  <div class="col-span-full">
                     <label for="url2" class="block text-md font-medium leading-6 text-gray-900">Image URL</label>
                     <div class="mt-2">
                        <div class="flex rounded-md shadow-sm border ring-0 border-gray-300 outline-none w-full">
                           <input v-model="form.imageUrl2" type="text" id="url2" autocomplete="url" class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 outline-none pl-3 sm:text-sm sm:leading-6" />
                        </div>
                     </div>
                  </div>
                  <div class="col-span-full">
                     <label for="url3" class="block text-md font-medium leading-6 text-gray-900">Image URL</label>
                     <div class="mt-2">
                        <div class="flex rounded-md shadow-sm border ring-0 border-gray-300 outline-none w-full">
                           <input v-model="form.imageUrl3" type="text" id="url3" autocomplete="url" class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 outline-none pl-3 sm:text-sm sm:leading-6" />
                        </div>
                     </div>
                  </div>

                  <div class="col-span-full">
                     <label for="about" class="block text-md font-medium leading-6 text-gray-900">Text Prompt</label>
                     <div class="mt-2">
                        <textarea v-model="form.textInput" id="about" rows="3" class="block w-full text-md rounded-md pl-3 pt-3 py-1.5 text-gray-900 shadow-sm border border-gray-300 outline-none sm:text-sm sm:leading-6" />
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="mt-6 flex items-center justify-end gap-x-6">
            <button type="button" @click="handleSubmit" :class="isLoading ? 'cursor-not-allowed opacity-70' : ''" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
         </div>
      </div>

      <div class="space-y-12 mt-12">
         <div class="pb-12">
            <h2 class="text-xl font-semibold leading-7 text-gray-900">Response</h2>
            <div v-if="output" class="text-md" v-html="output.message.content"></div>
         </div>
      </div>
   </div>

</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import axios from "axios"
import { getBackendBaseUrl } from "@/utils/common"
import Expert from '@/composables/Expert'

const currentExpert = ref<any>()
const output = ref<any>(null)
const isLoading = ref<boolean>(false)
const persona = computed(() => {
   if (currentExpert.value) {
      return currentExpert.value.persona
   }
   return ''
})
const baseUrl = getBackendBaseUrl()
const aiExperts = ref<any>([])
const { allAiExperts } = Expert()
const form = ref<any>({
   imageUrl: '',
   imageUrl2: '',
   imageUrl3: '',
   textInput: ''
})

const mounted = async () => {

   await fetchAiExperts()
}

const fetchAiExperts = async () => {
   const response = await allAiExperts()
   if (response.status == 200) {
      aiExperts.value = response.data.users
      currentExpert.value = response.data.users[0]
   }
}

const validateForm = () => {

   let valid = true 

   if (form.value.imageUrl == '') {
      valid = false
   }
   
   if (form.value.textInput == '') {
      valid = false
   }

   return valid
}

const handleSubmit = async () => {

   const isValid = validateForm()

   if (isValid && !isLoading.value) {

      isLoading.value = true
      output.value = null
      const response  = await getFeedback(persona.value, form.value.textInput, form.value.imageUrl, form.value.imageUrl2, form.value.imageUrl3)
   
      if (response.status == 200) {
   
         output.value = response.data.output
         console.log(response)
      } else {
         console.log(response)
      }

      isLoading.value = false
   }
}

const getFeedback = async (persona: string, textInput: string, imageUrl: string, imageUrl2: string, imageUrl3: string) => {

   try {
      const response = await axios.post(baseUrl + '/gpt/feedback', {persona: persona, textInput: textInput, imageData: imageUrl , imageDataTwo: imageUrl2 , imageDataThree: imageUrl3, imageType: 'url'}, {headers: {}})
      return response
   } catch (err : any) {
      return err
   }
}


onMounted(mounted)
</script>
 
<template>
   <div class="flex flex-col pt-8 h-screen">
      <div class="flex flex-row items-center justify-between xs:px-theme md:px-theme-desktop mb-6 md:mb-0">
         <div @click="$router.back()" class="cursor-pointer flex-1">
            <img class="w-[17px]" src="/assets/icons/cross.svg" alt="back">
         </div>
         <div class="flex items-center font-medium text-head-3">
            <div @click="handleMainTabs('preview')" :class="program.activeTab == 'preview' ? 'bg-skeleton-1 rounded-full' : 'cursor-pointer'" class="py-2 w-[100px] text-center">Preview</div>
            <div @click="handleMainTabs('save')" :class="program.activeTab == 'save' ? 'bg-skeleton-1 rounded-full' : 'cursor-pointer'" class="py-2 w-[100px] text-center">Edit</div>
         </div>
         <div class="flex items-center gap-2 text-head-3 font-medium flex-1 justify-end" :class="isLoading || !isActive ? 'text-theme-gray' : 'text-theme-blue cursor-pointer'">
            <!--<Menu v-slot="{ open }" as="div" class="relative">
               <MenuButton class="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600 outline-none">
                  <div class="flex flex-row items-center md:mr-5 gap-3">
                     <img class="hidden md:block w-[12px]" src="/assets/icons/sort-down.svg" alt="">
                     <img v-if="project && project.isPrivate" class="w-[23px]" src="/assets/icons/private.svg" alt="">
                     <img v-else-if="project && !project.isPrivate" class="w-[23px]" src="/assets/icons/public.svg" alt="">
                  </div>
               </MenuButton>
               <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="z-10 w-max absolute left-0 top-0 -translate-x-[75%] translate-y-[15%] transform origin-top-right bg-transparent outline-none focus:outline-none">
                     <MenuItem v-slot="{ active }">
                        <ProjectPrivateOption :data="project ? project.isPrivate : true" @submit=handlePrivateOutput @click.stop="handleMenuItemClick" />
                     </MenuItem>
                  </MenuItems>
               </transition>
            </Menu>-->
            <span @click="handleSubmit">Save</span>
         </div>
      </div>
      <input multiple class="hidden" ref="fileInput" type="file" @change="uploadMedia" name="file" accept="image/*,video/*, .hevc" />
      <div :class="program.project.artworks.length && program.activeTab == 'preview' ? 'justify-start md:justify-center' : ''" class="flex flex-col flex-grow md:my-16 xs:px-theme md:px-0">
         <div v-if="program.project.artworks.length == 0" class="relative flex flex-col h-full w-full">
            <div v-if="program.activeTab == 'save'" class="w-full flex flex-col items-center justify-center gap-4">
               <ArtworkSkeleton />
               <ArtworkSkeleton />
               <ArtworkSkeleton />
            </div>
            <div v-else>
               <p class="tailwind-css flex flex-col"></p>
            </div>
         </div>
         <div v-else-if="program.activeTab == 'preview'" class="relative">
            <div v-if="activeArtwork" class="md-2:flex flex-col hidden absolute left-0 bg-white items-start justify-center h-full pr-[100px] pl-theme-desktop max-w-[400px] z-10">
               <div class="font-bold text-black text-head-8 break-all">{{ activeArtwork.title }}</div>
               <div class="text-head-3 font-medium text-[#757575] max-h-[250px] overflow-y-auto" v-html="!hasFullDescription ? truncateText(activeArtwork.description.replace(/\n/g, '<br>'), 100) : activeArtwork.description.replace(/\n/g, '<br>')"></div>
               <div v-if="activeArtwork.description.length > 100" @click="handleReadMoreBtn" class="hover:underline text-theme-blue cursor-pointer mt-2 font-medium">Read {{ hasFullDescription ? 'less' : 'more' }}</div>
            </div>
            <swiper :navigation="true" :slidesPerView="'auto'" :keyboard="{enabled: true }" :lazy="true" :mousewheel="true" :spaceBetween="screenWidth == 'mobile' && displayMode == 'browser' ? 15 : 20" :centeredSlides="true" :modules="modulesTwo" @slideChange="onSlideChange" @swiper="onSwiper" class="my-swiper flex items-center">
               <swiper-slide v-for="(artwork, index) in program.project.artworks" :key="index" v-slot="{isActive}" class="w-auto flex justify-center">
                  <ArtworkFront :artwork="artwork" :isActive="isActive" :artworks="program.project.artworks" :artworkIndex="index" @clicked="handleSlideClick" :screenWidth="screenWidth" />
               </swiper-slide>
            </swiper>
         </div>
         <div v-else class="relative w-full flex flex-col items-center justify-center mb-3">
            <div v-if="program.stage == 'two' && program.isEdit == false && program.activeTab == 'save'" class="hidden absolute left-1/2 transform -translate-x-[250%] top-[100px] md:flex flex-col gap-8">
               <img class="w-[78px] ml-auto" src="/assets/icons/arrow.svg" alt="">
               <div class="text-skeleton-3 text-head-5 font-medium text-center">Press and hold<br>to reorder</div>
            </div>
            <draggable v-model="program.project.artworks" item-key="sourceIndex" @end="handleDragEnd" class="flex flex-col gap-6 max-h-[500px] md:max-h-[600px] overflow-x-hidden overflow-y-auto w-full md:w-fit pr-2">
               <template #item="{element}">
                  <ArtworkBack :id="element.sourceIndex" :title="element.title" :description="element.description" :artwork="element" @removed="handleRemoveArtwork" @save="handleArtworkDetailsSave" />
               </template>
            </draggable>
         </div>
      </div>
      <div class="flex flex-row items-center justify-between w-full xs:px-theme md:px-theme-desktop z-50 fixed bottom-4 md:bottom-8 left-0">
         <div class="flex flex-col w-full">
            <div class="flex flex-col items-start">
               <div v-if="program.activeTab == 'preview'" class="flex relative rounded-full w-[110px] h-[110px] mb-2 cursor-pointer items-center justify-center shadow-custom-symetric-2">
                  <div class="min-h-[110px] max-h-[110px] min-w-[110px] max-w-[110px] overflow-hidden bg-center bg-no-repeat bg-cover rounded-full" :style="{ backgroundImage: `url(${project.user?.image})` }"></div>
                  <div class="absolute top-0 left-0 w-full h-full rounded-full bg-black opacity-50"></div>
                  <div v-if="project.audio || tempAudio || audio.data">
                     <div @click="toggleAudio()">
                        <font-awesome-icon v-if="audio.isPlaying" icon="fa-solid fa-pause" class="icon text-white text-head-5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                        <font-awesome-icon v-else icon="fa-solid fa-play" class="icon text-white text-head-5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ml-[0.125rem]" />
                     </div>
                     <div @click="openConfirmModal = true" class="absolute top-0 right-0 w-[35px] h-[35px] rounded-full border border-white bg-black">
                        <font-awesome-icon icon="fa-solid fa-times" class="icon text-white text-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                     </div>
                  </div>
               </div>
               <div class="flex w-full items-center justify-between">
                  <div class="flex flex-col gap-1">
                     <div class="flex flex-row items-center">
                        <input v-model="project.title" type="text" id="edit-name" placeholder="Name your project" class="w-[150px] border-0 outline-none bg-transparent text-head-1 text-black font-medium">
                        <label for="edit-name">
                           <img src="/assets/icons/pencil.svg" class="w-[14px] h-[14px] cursor-pointer" alt="">
                        </label>
                     </div>
                     <div class="text-theme-gray-4 text-size-2">Created by {{ authUser.name }}</div>
                  </div>
                  <div v-if="program.activeTab == 'preview' && program.project.artworks.length > 1" class="hidden md:flex items-center gap-28">
                     <div @click="handleSlideMove('back')" class="cursor-pointer">
                        <font-awesome-icon icon="fa-solid fa-chevron-left" class="icon text-[#BFBFBF] text-head-2" />
                     </div>
                     <div @click="handleSlideMove('next')" class="cursor-pointer">
                        <font-awesome-icon icon="fa-solid fa-chevron-right" class="icon text-[#BFBFBF] text-head-2" />
                     </div>
                  </div>
                  <div v-else-if="program.activeTab == 'save'" class="hidden md:flex items-center gap-28">
                     <div @click="openDeleteConfirmModal = true" class="cursor-pointer text-theme-red text-head-1 font-satoshi-medium">Delete Project</div>
                  </div>
                  <div class="relative">
                     <div v-if="program.stage == 'one' && program.isEdit == false && program.activeTab == 'save'" class="hidden md:flex absolute -top-[150%] -left-[300%]">
                        <div class="text-skeleton-3 text-head-5 font-medium text-center">Add screens or<br>videos</div>
                        <div class="absolute -bottom-[30%] -right-[75%]">
                           <img class="w-[78px] ml-auto rotate-90" src="/assets/icons/arrow.svg" alt="">
                        </div>
                     </div>
                     <div v-if="program.activeTab == 'save'">
                        <Popover v-slot="{ open }" class="relative">
                           <PopoverButton @click.stop="handleMenuClick(open)" class="main-menu-btn outline-none flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                              <div class="flex bg-black shadow-custom-symetric-3 rounded-full w-[65px] h-[65px] cursor-pointer items-center justify-center">
                                 <img v-if="!open" src="/assets/icons/plus.svg" alt="">
                                 <font-awesome-icon v-else icon="fa-solid fa-times" class="icon text-white text-head-4" />
                              </div>
                           </PopoverButton>
                           <TransitionRoot v-if="mainMenuOpen" as="template" :show="open">
                              <TransitionChild as="template" leave="transition duration-300" leave-from="translate-y-0" leave-to="translate-y-full">
                                 <PopoverPanel class="w-max absolute left-0 top-0 -translate-y-[105%] md:-translate-y-[70%] md:-translate-x-[110%] -translate-x-[70%] transform origin-top-right bg-transparent outline-none focus:outline-none">
                                    <div v-if="showAudioRecorder">
                                       <AudioRecorder class="md:-translate-y-[15%] transform" :isBusy="isLoading" heading="Share the story of this project" :profilePhoto="authUser.image" @click.stop="handleMenuItemClick" @submit="handleAudioSubmit" />
                                    </div>
                                    <div v-else class="flex gap-5 flex-col md:flex-row md:h-[12.5rem]">  
                                       <div ref="recordVoiceRef" @click="initAudioRecorder" class="opacity-0 bg-black px-6 py-4 w-[11.5rem] cursor-pointer hover:bg-theme-blue text-white rounded-3xl shadow-lg flex flex-col gap-[1.5rem] items-start justify-center">
                                          <img class="h-[30px] md:h-[36px]" src="/assets/icons/mic.svg" alt="">
                                          <div class="text-lg md:text-head-2">Record<br>Voiceover</div>
                                       </div>
                                       <div ref="addScreensRef" @click="handleAddArtworks" @animationend="handleAnimationEnd" class="bg-black px-6 py-4 w-[11.5rem] cursor-pointer hover:bg-theme-blue text-white rounded-3xl shadow-lg flex flex-col gap-[1.5rem] items-start justify-center animate__animated animate__fadeInUp">
                                          <div class="relative flex flex-row items-center">
                                             <img class="h-[24px] md:h-[36px]" src="/assets/icons/rect-2.svg" alt="">
                                          </div>
                                          <div class="text-lg md:text-head-2">Add<br>Screens</div>
                                       </div>
                                    </div>
                                 </PopoverPanel>
                              </TransitionChild>
                           </TransitionRoot>
                        </Popover>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div @click="closeMainMenu" v-if="mainMenuOpen" class="z-20">
         <TransitionRoot as="template" :show="true">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100">
               <div class="fixed backdrop-blur-sm inset-0 bg-white/40 transition-opacity" />
            </TransitionChild>
         </TransitionRoot>
      </div>
      <ModalFit :open="openModal" :hasShadow="false" overlay="dark" @closed="handleModalClose">
         <template v-slot:content>
            <div class="flex flex-col relative">
               <ProjectMedia :media="media" :index="activeIndex" :hasFullPath="id != '' ? false : true" @closed="handleModalClose" />
            </div>
         </template>
      </ModalFit>
      <Notification v-if="notif.show" :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
      <ConfirmationModal :show="openConfirmModal" @closed="handleConfirmClose">
         <template v-slot:buttons>
            <button @click="askingConfirmed" type="button" :class="isBusy ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer'" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">Remove audio</button>
            <button @click="openConfirmModal = false" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">Cancel</button>
         </template>
      </ConfirmationModal>
      <ConfirmationModal :show="openDeleteConfirmModal" @closed="handleConfirmClose">
         <template v-slot:buttons>
            <button @click="askingConfirmed" type="button" :class="isBusy ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer'" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">Yes, continue</button>
            <button @click="openDeleteConfirmModal = false" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">Cancel</button>
         </template>
      </ConfirmationModal>
   </div>
   <PageLoader :show="isLoading" />
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch, onBeforeUnmount } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/effect-cards'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Navigation, EffectCards, FreeMode, Pagination, Keyboard, Mousewheel } from 'swiper/modules'
import { generateVideoThumbnail, getAssetBaseUrl, getAudioBase64, isImageFile, isVideoFile, isHEVCFile, truncateText, disableScroll, enableScroll } from '@/utils/common'
import Project from '@/composables/Project'
import Notification from '@/widgets/Notification.vue'
import { NotificationType } from '@/types/common'
import { collection, getDocs, query, where, orderBy, doc, getDoc } from "firebase/firestore"
import { auth, db, firebaseConfig } from '@/firebase'
import router from '@/router'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ProjectArtwork, ProjectArtworkSource, Project as ProjectType, ProjectMedia as ProjectMediaType } from '@/types/project'
import ModalFit from '@/widgets/ModalFit.vue'
import ProjectMedia from '@/components/ProjectMedia.vue'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'
import AudioRecorder from '@/components/AudioRecorder.vue'
import ArtworkSkeleton from '@/skeletons/ArtworkSkeleton.vue'
import ProjectPrivateOption from '@/components/ProjectPrivateOption.vue'
import ArtworkFront from '@/components/ArtworkFront.vue'
import ArtworkBack from '@/components/ArtworkBack.vue'
import PageLoader from '@/widgets/PageLoader.vue'
import draggable from 'vuedraggable'
import 'jquery-ui/ui/widgets/sortable'
// @ts-ignore
import $ from 'jquery'
import 'animate.css'
import { TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

const props = defineProps(['id'])
const fileInput = ref<HTMLInputElement | null>(null)
const errorMessage = ref<string | null>(null)
const openModal = ref(false)
const media = ref<ProjectArtwork[] | null>(null)
const activeIndex = ref<number>(0)
const modulesTwo = [Keyboard, Mousewheel, FreeMode, Pagination, Navigation]
const selectedArtworks = ref<ProjectMediaType[] | null>(null)
const swipperInstance = ref<any>(null)
const projects = ref<Array<{ id: string; name: string; }>>([])
const openConfirmModal = ref<boolean>(false)
const openDeleteConfirmModal = ref<boolean>(false)
const recordVoiceRef = ref<HTMLElement | null>(null)
const addScreensRef = ref<HTMLElement | null>(null)
const mainMenuOpen = ref<boolean>(false)
const hasFullDescription = ref<boolean>(false)
const showAudioRecorder = ref<boolean>(false)
let deletedArtworks : string[] = []
const audio = ref<any>({
   player: null,
   data: null,
   isPLaying: false,
   blob: null
})
const tempAudio = ref<string | null>(null)
const userData = localStorage.getItem('inFlightAuthUser')
const authUser = userData ? JSON.parse(userData) : null
const project = ref<ProjectType>({ 
   id: '', 
   number: 0,
   isPrivate: true,
   comments: 0,
   title : 'Name your project',
   user: {
      id: authUser.id,
      name: authUser.name,
      image: authUser.image
   },
   artworks: []
})
let projectTitle = ref<string>(project.value.title)
let projectPrivate = ref<boolean>(project.value.isPrivate)
const { createProject, uploadProjectArtwork, updateProjectArtwork, updateProject, removeArtwork, uploadProjectAudio, removeProjectAudio, removeTempAudio, getProject, getMyProjects, uploadAudioTemp, removeProject } = Project()
const isLoading = ref(false)
const isBusy = ref(false)
const isActive = ref(false)
const displayMode = ref<string>('browser')
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const projectArtworks = ref<ProjectArtwork[] | null>(null)
const program = ref({
   project: project.value,
   isEdit: props.id != '' ? true : false,
   activeTab: 'save',
   stage: 'one'
})
const combinedArtworks = ref<ProjectArtwork[]>()
const activeArtwork = ref<ProjectArtwork | null>(null)

const closeMainMenu = () => {
   mainMenuOpen.value = false
   enableScroll()
}

const handleDragEnd = () => {
   isActive.value = true
}

const handleReadMoreBtn = () => {

   hasFullDescription.value = !hasFullDescription.value
}

const toggleAudio = () => {

   if (audio.value.player && audio.value.isPlaying) {
      audio.value.player.pause()
      audio.value.isPlaying = false
   } else {

      let source = null
      if (tempAudio.value){
         source = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/audios-temp%2F${tempAudio.value}?alt=media`
      } else if (audio.value.data) {
         source = URL.createObjectURL(audio.value.blob)
      } else if (project.value.audio) {
         source = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/audios%2F${project.value.audio}?alt=media`
      }

      if (source) {

         if (!audio.value.player) {
            audio.value.player = new Audio(source)
         }
         
         audio.value.player.play()
         audio.value.isPlaying = true
   
         audio.value.player.addEventListener('ended', () => {
            audio.value.isPlaying = false
         })
      }
   }
}

const handleSlideMove = (action: string) => {

   if (action == 'back') {
      $('.swiper-button-prev').click()
   } else if (action == 'next') {
      $('.swiper-button-next').click()
   }
}

const onSlideChange = (swiper: any) => {

   $('.slider-img-holder').addClass('custom-transition')
   
   const activeIndex = swiper.activeIndex
   activeArtwork.value = program.value.project.artworks.find((artwork: ProjectArtwork, index: number) => index === activeIndex) as ProjectArtwork
}

const handleAnimationEnd = () => {
   if (recordVoiceRef.value) {
      recordVoiceRef.value.classList.add('animate__animated', 'animate__fadeInUp')
   }
}

const initAudioRecorder = () => {
   showAudioRecorder.value = !showAudioRecorder.value
}

const handleMenuClick = (open: boolean) => {
   mainMenuOpen.value = !open
   showAudioRecorder.value = false
   
   if (mainMenuOpen.value) {
      disableScroll()
   } else {
      enableScroll()
   }
}

const mounted = async () => {
   //fetchUserProjects()
   
   const id = props.id
   if (id !== '') {
      await fetchUserProject(id)
      
      const projectt = project.value
      const projectCopy = JSON.parse(JSON.stringify(projectt))
      projectArtworks.value = projectCopy.artworks
      projectTitle.value = projectCopy.title
      projectPrivate.value = projectCopy.isPrivate
   } else {

   }

   if (window.matchMedia('(display-mode: standalone)').matches) {
      displayMode.value = 'standalone'
   }
}
    
const handleMenuItemClick = (e: Event) => {
   e.preventDefault();
}

const handlePrivateOutput = (isPrivate: boolean) => {
   project.value.isPrivate = isPrivate
}

const fetchUserProject = async (id: string) => {
   
   try {

      const response = await getProject(id)
      if (response.status == 200) {

         project.value = response.data.project as ProjectType
         program.value.project = project.value
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error as string}
         setTimeout(() => { notifClosed() }, 2000)
      }
   } catch (error) {
      isLoading.value = false
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error as string }
   }
}

const handleSlideClick = (artworks: ProjectArtwork[], index: number) => {

   media.value = artworks
   activeIndex.value = index
   openModal.value = true
}

const handleModalClose = () => openModal.value = false

const handleConfirmClose = () => openConfirmModal.value = false

const fetchUserProjects = async () => {
   const q = query(collection(db, 'projects'), where('user_id', '==', authUser.id), orderBy('created_at', 'desc'))
   const snap = await getDocs(q)
   snap.forEach(async (doc) => {
      projects.value.push({ 'id': doc.id, 'name' : doc.data().title})
   })
}

const getProjectDet = () => {
   let artworks : ProjectArtwork[] = []
   for (const artwork of project.value.artworks) {
      artworks.push(artwork)
   }

   return {title: project.value.title, artworks: artworks, isPrivate: project.value.isPrivate}
}

const onSwiper = (swiper : any) => {
   swipperInstance.value = swiper
}

const handleAddArtworks = () => {
   fileInput.value?.click()
   $('.main-menu-btn').trigger('click')
}

const handleRemoveArtwork = (sourceIndex : number, source: ProjectArtworkSource) => {

   const targetIndex = program.value.project.artworks.findIndex((artwork, ind) => sourceIndex === artwork.sourceIndex)

   if (targetIndex >= 0) {
      if (source == 'database') {
         const target = program.value.project.artworks.filter((artwork: ProjectArtwork, ind: number) => sourceIndex === artwork.sourceIndex)
         deletedArtworks.push(target[0].data)
      }

      program.value.project.artworks.splice(targetIndex, 1)
      activeArtwork.value = program.value.project.artworks.length ? program.value.project.artworks[0] : null
   }

   //swipperInstance.value.update()
}

const uploadMedia = (e: Event) => {

   selectedArtworks.value = []
   const inputElement = e.target as HTMLInputElement

   if (inputElement.files) {

      for (let i = 0; i < inputElement.files.length; i++) {

         errorMessage.value = null
         const file = inputElement.files[i]
         const reader = new FileReader()

         if (!isImageFile(file) && !isVideoFile(file) && !isHEVCFile(file)) {
            errorMessage.value = 'One of the files is not a valid image or video'
         } else if (isImageFile(file) && file.size > 3145728) {
            errorMessage.value = 'Image must be under 3MB in size'
         } else if (isVideoFile(file) && file.size > 20971520) {
            errorMessage.value = 'Video must be under 20MB in size'
         }

         if (errorMessage.value) {
            notif.value = { show: true, type: 'error', message: 'Some artworks could not be uploaded', messageDetails: 'Reason: ' + errorMessage.value}
            setTimeout(() => { notifClosed() }, 4000)
            continue
         }

         const mediaType = isImageFile(file) ? 'image' : (isHEVCFile(file) ? 'hevc' : 'video')
         
         reader.onloadend = () => {
            
            if (mediaType == 'image') {
                
               const img = new Image()
               img.src = reader.result as string

               img.onload = () => {
                  program.value.project.artworks.push({
                     type: mediaType,
                     thumbnail: '',
                     data: reader.result as string,
                     source: 'local',
                     sourceIndex: 0,
                     title: '',
                     description: '',
                     width: img.width.toString(),
                     height: img.height.toString(),
                  })

                  updateSourceIndex()
               }
            } else {

               program.value.project.artworks.push({
                  type: mediaType,
                  data: reader.result as string,
                  thumbnail: '',
                  source: 'local',
                  sourceIndex: 0,
                  title: '',
                  description: '',
                  width: '',
                  height: '',
               })
            }
         }

         reader.readAsDataURL(file)
         program.value.stage = 'two'
      }
   }
}

const updateSourceIndex = () => {
   
   const seenIndices = new Set()

   for (const artwork of program.value.project.artworks) {
      while (seenIndices.has(artwork.sourceIndex)) {
         artwork.sourceIndex++
      }
      
      seenIndices.add(artwork.sourceIndex)
   }

   console.log(program.value.project.artworks)
}

const handleSubmit = async () => {

   let success
   if (!isLoading.value && isActive.value) {
      
      isLoading.value = true
      if (project.value.id === '') {
         const response = await createProject(project.value)
         if (response.status == 201) {
            await uploadAudio(response.data.project)
            await uploadArtworks(response.data.project)
            router.push({name: 'Home'})
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
         }
      }else{

         success = await deleteArtworks(project.value.id)
         success = true
         if (success) {
            const response = await updateProject(project.value.id, project.value.title, project.value.isPrivate)
            if (response.status == 200) {
               await uploadArtworks(response.data.project)
               await updateArtworks(response.data.project)
               await uploadAudio(response.data.project)
            } else {
               const error = handleApiError(response)
               notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
               success = false
            }
         }
         
         if (success) {
            router.push({name: 'Home'})
         }
      }
      
      isLoading.value = false
   }
}

const uploadArtworks = async (projectId: string) => {
   
   await Promise.all(program.value.project.artworks.map(async (artwork, index) => {
      if (artwork.source === 'local') {
         await uploadProjectArtwork(projectId, artwork.data, artwork.type, (index + 1), artwork.title, artwork.description, artwork.width, artwork.height);
      }
   }))
}

const updateArtworks = async (projectId: string) => {
   const artworks = program.value.project.artworks.filter(artwork => artwork.source == 'database')
   if (artworks.length) {
      const artworksState = projectArtworks.value

      for (let index = 0; index < artworks.length; index++) {
         const item = artworks[index]
         if (artworksState) {
            const artwork = artworksState.filter((artwork: ProjectArtwork, ind: number) => artwork.data === item.data)
            const targetIndex = program.value.project.artworks.findIndex((artwork, ind) => item.sourceIndex === artwork.sourceIndex)
            if (artwork.length && (item.title != artwork[0].title || item.description != artwork[0].description || item.sourceIndex != targetIndex)) {
               await updateProjectArtwork(projectId, item.data, item.title, item.description, targetIndex)
            }
         }
      }
   }
}

const uploadAudio = async (projectId : string) => {
   if (audio.value.data) {
      const audioResponse = await uploadProjectAudio(projectId, audio.value.data)

      if (audioResponse.status == 201) {
         audio.value.data = null
      } else {
         const error = handleApiError(audioResponse)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      }
   }
}

const askingConfirmed = async () => {

   if (openDeleteConfirmModal.value) {

      if (!isBusy.value && project.value) {

         isBusy.value = true

         const response = await removeProject(project.value.id)
         if (response.status == 204) {
            router.push({name: 'Home'})
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error as string}
         }

         isBusy.value = false
      }      
   } else if (openConfirmModal.value) {

      if (!isBusy.value && project.value && project.value.audio) {
   
         isBusy.value = true
         const response = await removeProjectAudio(project.value.id)
         if (response.status == 204) {
            project.value.audio = null
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Audio removed successfully'}
            setTimeout(() => { notifClosed() }, 3000)
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
         }
      } else if (!isBusy.value && audio.value && audio.value.data) {
   
         isBusy.value = true
         if (tempAudio.value) { 
            const response = await removeTempAudio(tempAudio.value)
            if (response.status == 204) {
               tempAudio.value = null
            }
         }
   
         audio.value = { player: null, data: null, isPLaying: false, blob: null }
      }
   
      isBusy.value = false
      openConfirmModal.value = false
   }
}

const deleteArtworks = async (id : string) => {
   let success : boolean = true
   if (deletedArtworks.length) {
      for (const artwork of deletedArtworks) {
         const matchedArtwork = projectArtworks.value?.find((a: ProjectArtwork) => a.data == artwork)
         if (matchedArtwork) {
            const response = await removeArtwork(id, artwork, matchedArtwork.type)
            if (response.status !== 204) {
               const error = handleApiError(response)
               notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
               success = false
            }
         }
      }
   }

   return success
}

const handleArtworkDetailsSave = (artworkIndex: number, title: string, description: string) => {

   const artwork = program.value.project.artworks?.find((artwork: ProjectArtwork) => artwork.sourceIndex == artworkIndex)
   if (artwork) {
      artwork.title = title
      artwork.description = description

      isActive.value = true
   }
}

const screenWidth = computed(() => {
   const screenWidth = window.innerWidth;
  
   if (screenWidth < 768) {
      return 'mobile'
   } else if (screenWidth >= 768 && screenWidth <= 1260) {
      return 'medium-1'
   } else if (screenWidth >= 1261 && screenWidth <= 1650) {
      return 'medium-2'
   } else {
      return 'large'
   }
})

const handleMainTabs = (tab: string) => {
   activeArtwork.value = program.value.project.artworks.length ? program.value.project.artworks[0] : null
   program.value.activeTab = tab
}

const swiperSlidesPerView = computed(() => {

   const totalLength = combinedArtworks.value ? combinedArtworks.value.length + 1 : 1
   if (screenWidth.value == 'mobile' || project.value.id == '' || totalLength == 1 || totalLength == 2) {
      return 'auto'
   } else if (totalLength == 3) {
      return 3
   }

   return 4
})

const handleApiError = (error : any) => {
   if(error.response){
      const message = error.response.data ? error.response.data.error : error.response.statusText
      return `status code: ${error.response.status} - message: ${message}`
   }

   return error.message
}

const notifClosed = () => {
   notif.value.show = false
}

const handleAudioSubmit = async (blob: Blob) => {
   audio.value.data = await getAudioBase64(blob)
   audio.value.blob = blob

   isLoading.value = true
   const response = await uploadAudioTemp(audio.value.data)
   isLoading.value = false

   if (response.status == 201) {

      $('.main-menu-btn').trigger('click')
      
      if (props.id != '') {
         isActive.value = true
      }

      tempAudio.value = response.data.file
   }
}

watch([program], ([newValue], [oldValue]) => {
   
   isActive.value = true
},{ deep: true })

onMounted(mounted)

</script>

<style scoped>
   .my-swiper, .my-swiper .swiper-slide { overflow: visible; }
   .my-swiper .swiper-slide { height: auto; }
   .animate__animated.animate__fadeInUp { animation-duration: 0.3s; }
   
   @media only screen and (min-width: 768px) {
      .swiper-slide:has(~ .swiper-slide-active){
         opacity: 0;
      }
   }

   :root {
      --animate-duration: 800ms;
      --animate-delay: 0.9s;
   }
</style>
import axios from "axios"
import Auth from './Auth'
import { getBackendBaseUrl } from "@/utils/common"

const baseUrl : string = getBackendBaseUrl()
const { getToken } = Auth()

const Comment = () => {

   const getTokens = () => {
      let tokenData = localStorage.getItem('inFlightAuthUserTokens')
      let tokens = tokenData ? JSON.parse(tokenData) : null
      return tokens
   }

   const hasTokens = () => {
      return localStorage.getItem('inFlightAuthUserTokens') !== null
   }

   const getHeaders = () => { 
      const headers : any = { 'Content-Type': 'application/json'}

      if (hasTokens()) {
         headers['Authorization'] = `Bearer ${getTokens().access}`
      }

      return headers
   }

   const createComment = async (project : string, comment: string, image: string | null) => {
      try {
         const response = await axios.put(baseUrl + '/create-comment', { project: project, comment: comment, image: image }, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.put(baseUrl + '/create-comment', { project: project, comment: comment, image: image }, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const getComments = async (project : string) => {
      try {
         const response = await axios.get(baseUrl + '/get-comments?project=' + project, {headers: {}})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.get(baseUrl + '/get-comments?project=' + project, {headers: {}})
            return response
         }else{
            return err
         }
      }
   }

   return {
      createComment,
      getComments
   }
}

export default Comment
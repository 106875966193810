<template>
   <div v-if="type == 'user'" class="flex flex-col mx-auto mb-24 mt-4 h-full xs:px-6 md:px-4">
      <div class="w-full mb-5">
         <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div class="space-y-1 text-center">
               <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
               </svg>
               <div class="flex justify-center text-sm text-gray-600">
                  <label @click="handleChooseAvatar" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 outline-none">
                     <button type="button">Click to upload avatar</button>
                     <input class="hidden" ref="fileInput" type="file" @change="uploadPhoto" accept="image/*">
                  </label>
               </div>
               <p class="text-xs text-gray-500"><span>(Files up to 3MB)</span></p>
               <p v-if="photoName != ''" class="text-xs text-gray-500"><span>{{ photoName }}</span></p>
            </div>
         </div>
      </div>
      <div class="w-full mb-5">
         <label class="block text-sm font-medium text-gray-700">Is this user VIP</label>
         <select v-model="user.vip" class="block mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:text-sm sm:leading-6">
            <option value="yes">Yes</option>
            <option value="no">No</option>
         </select>
      </div>
      <div class="w-full mb-5">
         <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
         <input v-model="user.name" id="name" type="text" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div v-if="!userr" class="w-full mb-5">
         <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
         <input v-model="user.email" id="email" type="text" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div v-if="user.vip == 'yes'" class="w-full mb-5">
         <label for="sub-text" class="block text-sm font-medium text-gray-700">Sub Text</label>
         <input v-model="user.subText" id="sub-text" type="text" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div v-if="user.vip == 'yes'" class="w-full mb-5">
         <label for="specialities" class="block text-sm font-medium text-gray-700">Specialities</label>
         <div class="text-sm text-gray-500 mt-2">For multiple items, add comma between them e.g Design,AI</div>
         <input v-model="user.specialities" id="specialities" type="text" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div v-if="user.vip == 'yes'" class="w-full mb-5">
         <label for="rate" class="block text-sm font-medium text-gray-700">Rate</label>
         <input v-model="user.rate" id="rate" type="text" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div v-if="user.vip == 'yes'" class="w-full mb-5">
         <label for="rate" class="block text-sm font-medium text-gray-700">Delivery time</label>
         <input v-model="user.deliveryTime" id="rate" type="text" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div v-if="user.vip == 'yes'" class="w-full mb-5">
         <label for="specialities" class="block text-sm font-medium text-gray-700">Average rating</label>
         <input v-model="user.popularity!.rating" id="specialities" type="text" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div v-if="user.vip == 'yes'" class="w-full mb-5">
         <label for="specialities" class="block text-sm font-medium text-gray-700">No. of reviews</label>
         <input v-model="user.popularity!.reviewsCount" id="specialities" type="text" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div class="w-full mb-5">
         <label for="bio" class="block text-sm font-medium text-gray-700">Bio</label>
         <textarea v-model="user.about" id="bio" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm h-[150px] p-2"></textarea>
      </div>
      <button @click=saveUser type="button" :class="isBusy ? 'cursor-not-allowed opacity-70' : 'hover:bg-indigo-500'" class="block rounded-md bg-indigo-600 px-3 py-2 w-fit ml-auto text-center text-md font-semibold text-white shadow-sm outline-none">
         Submit
      </button>
   </div>
   <div v-else class="flex flex-col mx-auto mb-24 mt-4 h-full xs:px-6 md:px-4">
      <div class="w-full mb-5">
         <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div class="space-y-1 text-center">
               <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
               </svg>
               <div class="flex justify-center text-sm text-gray-600">
                  <label @click="handleChooseAvatar" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 outline-none">
                     <button type="button">Click to upload avatar</button>
                     <input class="hidden" ref="fileInput" type="file" @change="uploadPhoto" accept="image/*">
                  </label>
               </div>
               <p class="text-xs text-gray-500"><span>(Files up to 3MB)</span></p>
               <p v-if="photoName != ''" class="text-xs text-gray-500"><span>{{ photoName }}</span></p>
            </div>
         </div>
      </div>
      <div class="w-full mb-5">
         <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
         <input v-model="user.name" id="name" type="text" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div class="w-full mb-5">
         <label for="sub-text" class="block text-sm font-medium text-gray-700">Sub Text</label>
         <input v-model="user.subText" id="sub-text" type="text" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div class="w-full mb-5">
         <label for="specialities" class="block text-sm font-medium text-gray-700">Specialities</label>
         <input v-model="user.specialities" id="specialities" type="text" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div class="w-full mb-5">
         <label for="bio" class="block text-sm font-medium text-gray-700">About</label>
         <textarea v-model="user.about" id="bio" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm h-[150px] p-2"></textarea>
      </div>
      <div class="w-full mb-5">
         <label for="persona" class="block text-sm font-medium text-gray-700">Persona</label>
         <textarea v-model="user.persona" id="persona" class="mt-1 border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm h-[150px] p-2"></textarea>
      </div>
      <button @click=saveUser type="button" :class="isBusy ? 'cursor-not-allowed opacity-70' : 'hover:bg-indigo-500'" class="block rounded-md bg-indigo-600 px-3 py-2 w-fit ml-auto text-center text-md font-semibold text-white shadow-sm outline-none">
         Submit
      </button>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { UserType } from '@/types/user'
import { onMounted, ref } from 'vue'
import { generateUserId, isImageFile, handleApiError } from '@/utils/common'
import Notification from '@/widgets/Notification.vue'
import { NotificationType } from '@/types/common'
import User from '@/composables/User'
import Admin from '@/composables/Admin'

const props = defineProps(['userr', 'type'])
const emit = defineEmits(['success', 'error'])
const isBusy = ref<boolean>(false)
const photoName = ref<string>('')
const photoData = ref<string | null>(null)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { addUser } = User()
const { updateUser, updateAiExpert } = Admin()
const fileInput = ref<HTMLInputElement | null>(null)
const user = ref<UserType>({
   id: '',
   name: '',
   email: '',
   image: '',
   about: '',
   subText: '',
   rate: 0,
   specialities: '',
   deliveryTime: '',
   popularity: {
      rating: '',
      reviewsCount: ''
   },
   vip: 'no'
})

onMounted(() => {

   if (props.userr) {
      user.value = JSON.parse(JSON.stringify(props.userr))
   }
})

const handleChooseAvatar = () => {
   fileInput.value?.click()
}

const uploadPhoto = (e: Event) => {

   photoData.value = null
   photoName.value = ''
   const inputElement = e.target as HTMLInputElement

   if (!inputElement.files || inputElement.files.length === 0) {
      return
   }

   const file = inputElement.files[0]
   const reader = new FileReader()

   if (!isImageFile(file)) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'File must be an image of type JPG or PNG'}
      return
   } else if (file.size > 3145728) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'Image must be 3MB or less'}
      return
   }

   photoName.value = file.name
   reader.onloadend = () => {
      photoData.value = reader.result as string
   }

   reader.readAsDataURL(file)
}

const saveUser = async () => {

   if (isBusy.value == false) {
      
      isBusy.value = true
      if (props.type == 'user') {
         const additionalInfo = {
            isVip: user.value.vip,
            subText: user.value.subText,
            specialities: user.value.specialities,
            rate: user.value.rate,
            deliveryTime: user.value.deliveryTime,
            averageRating: user.value.popularity?.rating,
            reviewsCount: user.value.popularity?.reviewsCount,
            about: user.value.about,
         }
      
         if (!props.userr) {
      
            const userId = generateUserId(28)
            const response = await addUser(userId, user.value.name, user.value.email, photoData.value, additionalInfo)
            if (response.status == 201) {
               emit('success')
            } else {
               const error = handleApiError(response)
               emit('error', error)
            }
         } else {
            const response = await updateUser(user.value.id, user.value.name, photoData.value, additionalInfo)
            if (response.status == 200) {
               emit('success')
            } else {
               const error = handleApiError(response)
               emit('error', error)
            }
         }
      } else {
         const response = await updateAiExpert(user.value.id, photoData.value as string, user.value.name, user.value.subText as string, user.value.specialities as string, user.value.about, user.value.persona as string)
         if (response.status == 200) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }
      }
   
      isBusy.value = false
   }
}

const notifClosed = () => {
   notif.value.show = false
}

</script>
import { getBackendBaseUrl } from "@/utils/common"
import axios from "axios"
import Auth from "./Auth"
import { CheckoutUser, UserType } from "@/types/user"

const baseUrl : string = getBackendBaseUrl()
const { getToken } = Auth()

const Admin = () => {

   const getTokens = () => {
      let tokenData = localStorage.getItem('inFlightAuthUserTokens')
      let tokens = tokenData ? JSON.parse(tokenData) : null
      return tokens
   }

   const hasTokens = () => {
      return localStorage.getItem('inFlightAuthUserTokens') !== null
   }

   const getHeaders = () => { 

      const headers : any = { 'Content-Type': 'application/json'}

      if (hasTokens()) {
         headers['Authorization'] = `Bearer ${getTokens().access}`
      }

      return headers
   }

   const allExperts = async () => {
      try {
         const response = await axios.get(baseUrl + '/all-experts', {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.get(baseUrl + '/all-experts', {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }
   
   const allAiExperts = async () => {
      try {
         const response = await axios.get(baseUrl + '/all-ai-experts', {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.get(baseUrl + '/all-ai-experts', {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const sendFeedbackRequest = async (project: string, users: CheckoutUser[], comments: string) => {
      try {
         const response = await axios.post(baseUrl + '/feedback-request', { project: project, users: users, comments: comments }, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.post(baseUrl + '/feedback-request', { project: project, users: users, comments: comments }, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const getFeedbackRequest = async (feedback: string) => {
      try {
         const response = await axios.get(baseUrl + '/get-feedback-request?feedback=' + feedback, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.get(baseUrl + '/get-feedback-request?feedback=' + feedback , {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const createFeedback = async (feedback: string, comment: string, audio: any, image: string) => {
      try {
         const response = await axios.post(baseUrl + '/feedback-response', { feedback: feedback, comment: comment, audio: audio, image: image }, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.post(baseUrl + '/feedback-response', { feedback: feedback, comment: comment, audio: audio, image: image }, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   return {
      allExperts,
      sendFeedbackRequest,
      getFeedbackRequest,
      createFeedback,
      allAiExperts
   }
}

export default Admin
<template>
   <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10" @close="closed">
         <div class="fixed inset-0" />

         <div class="fixed inset-0 overflow-hidden">
         <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
               <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                  <DialogPanel class="pointer-events-auto w-screen max-w-md">
                     <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                        <div class="">
                           <div class="flex items-start justify-between">
                              <div class="ml-3 flex h-7 items-center">
                                 <button type="button" class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 outline-none" @click="closed">
                                    <img class="w-[17px]" src="/assets/icons/cross.svg" alt="back">
                                 </button>
                              </div>
                           </div>
                        </div>
                        <div class="relative mt-6 flex-1">
                           <slot name="content" />
                        </div>
                     </div>
                  </DialogPanel>
               </TransitionChild>
            </div>
         </div>
         </div>
      </Dialog>
   </TransitionRoot>
 </template>
 
 <script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

const props = defineProps(['open'])
const emit = defineEmits(['closed'])

const closed = () => emit('closed')
 </script>
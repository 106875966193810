<template>
   <div class="flex flex-col gap-20 font-satoshi-regular"> 
      <img class="w-[9rem] mt-[4rem] mx-auto mb-4" src="/assets/logo.svg" alt="Application logo" />
      <div class="font-satoshi-bold flex flex-col text-center text-[32px] max-w-[320px] md:max-w-full mx-auto text-[#111] gap-2">
         <span>{{ headerText }}</span>
         <div class="flex flex-col justify-center text-center max-w-[360px] mx-auto font-light text-head-2 text-[#9c9c9c] leading-lh-2 md:leading-[29px]">
            <div v-html="subHeaderText"></div>
         </div>
      </div>
      <form id="my-form" action="https://formspree.io/f/maygzjaw" method="post" class="flex flex-col mx-auto justify-center gap-4 w-[320px]">
         <input type="text" class="border-2 border-black rounded-xl py-3 px-4 w-full" name="field1" placeholder="Full Name" />
         <input type="text" class="border-2 border-black rounded-xl py-3 px-4 w-full" name="field2" placeholder="Email" />
         <textarea class="customTextarea border-2 border-black rounded-xl py-3 px-4 w-full h-32" name="field3" placeholder="Share a link or description of what you’re working on"></textarea>
         <button type="submit" class="bg-black py-3 mt-3 px-12 w-fit mx-auto rounded-xl text-white font-bold text-head-2">Apply</button>
      </form>
   </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const headerText = ref<string>('Sign up as a builder')
const subHeaderText = ref<any>('The first 1000 builders will get $100 in feedback credits on Inflight')

document.addEventListener("DOMContentLoaded", function () {

   var form = document.getElementById('my-form') as any
   async function handleSubmit(event: any) {

      if (form.length){

         event.preventDefault()
         var data = new FormData(event.target)
         fetch(event.target.action, {
            method: form.method,
            body: data,
            headers: {
               Accept: "application/json",
            },
         })
         .then((response) => {
            if (response.ok) {
               form.style.display = "none"
               headerText.value = "Thanks for applying!"
               subHeaderText.value = 'We are adding new builders every day, in the meantime, keep up to date with our progress on '
               subHeaderText.value += '<a style="color: #5AD8FD; font-weight: bold;" href="https://x.com/useinflight" target="_blank">X.com</a>'
               subHeaderText.value += ' :)'
            }
         })
      }
   }

   form.addEventListener('submit', handleSubmit)
})

</script>
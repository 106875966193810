<template> 
   <div class="flex flex-col h-screen"> 
      <img class="w-[8rem] mx-auto fixed top-12 md:top-24 left-1/2 transform -translate-x-1/2" src="/assets/logo.svg" alt="Application logo" />
      <div class="xs:w-[82%] sm:w-[75%] md:max-w-[275px] mx-auto my-auto flex flex-col gap-8 m-auto text-center" :class="screenHeight > 700 ? 'relative' : 'fixed z-10 sm:bottom-1 lg:bottom-10 left-1/2 transform -translate-x-1/2'">
         <div class="relative flex flex-col gap-2">
            <input v-model="code" class="border border-2 border-theme-gray-darker rounded-xl w-full h-[48px] pl-4 outline-none" type="text" placeholder="Invite Code">
            <div class="text-theme-error text-sm text-left" v-if="errors.code">The code is required</div>
         </div>
         <div @click="handleSubmit()" :class="!isBusy ? 'cursor-pointer' : 'cursor-not-allowed opacity-60'" class="text-black border-2 border-theme-gray rounded-full w-12 h-12  mx-auto flex items-center justify-center">
            <font-awesome-icon icon="fa-solid fa-arrow-right" class="icon text-head-1" />
         </div>
      </div>
      <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
   </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { Error as ErrorType } from '@/types/common'
import Auth from '@/composables/Auth'
import { useRouter } from 'vue-router'
import { NotificationType } from '@/types/common'
import Notification from '@/widgets/Notification.vue'

const isBusy = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const code = ref<string>('')
const { authorizeUser } = Auth()
const router = useRouter()
const errors = ref<any>({
   code: false
})

const handleSubmit = async () => {

   const isValid = validateForm()

   if (isValid) {
      const response = await authorizeUser(code.value)

      if (response.status == 200) {
         saveUserAddAuthorization()
         router.push({name: 'Home'})
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      } 
   }
}

const validateForm = () : boolean => {

   let success = true
   errors.value.code = false

   if (code.value == '') {
      success = false
      errors.value.code = true
   }
   
   return success
}

const saveUserAddAuthorization = () => {
   localStorage.setItem('inFlightUserAddAuth', btoa(code.value))
}

const screenHeight : any = computed(() => {
   return window.innerHeight
})

const handleApiError = (error : any) => {
   if(error.response){
      const message = error.response.data ? error.response.data.error : error.response.statusText
      return `status code: ${error.response.status} - message: ${message}`
   }

   return error.message
}

const notifClosed = () => {
   notif.value.show = false
}

onMounted(() => {

})

</script>
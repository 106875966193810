<template>
   <div class="flex flex-col flex-grow h-full w-full h-full relative gap-5 bg-transparent">
      <div :class="isExpanded ? 'h-[250px] md:h-[35%]' : 'h-[80px] md:h-[10%]'" class="flex flex-col bg-white rounded-3xl p-5 gap-8 artwork-details transition-all duration-500">
         <div class="flex items-center">
            <div class="flex items-start gap-4"> 
               <div class="relative max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]">
                  <img class="rounded-full" :src="creator?.image" alt="">
                  <div v-if="project?.audio" @click="toggleAudio" class="cursor-pointer min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center border border-white absolute -bottom-1 -right-2 rounded-full bg-theme-blue-3">
                     <font-awesome-icon v-if="audio.isPlaying" icon="fa-solid fa-pause" class="icon text-white text-xs pl-[2px]" />
                     <font-awesome-icon v-else icon="fa-solid fa-play" class="icon text-white text-xs pl-[2px]" />
                  </div>
               </div>
               <div class="flex flex-col">
                  <div v-if="project" class="text-black text-head-1 md:text-head-3 font-satoshi-medium whitespace-nowrap">{{ truncateText(project?.title, 25) }}</div>
                  <div v-if="creator" class="text-theme-gray-4 text-size-1 md:text-size-3 font-satoshi-regular">By {{ creator?.name }}</div>
               </div>
            </div>
            <div v-if="activeArtwork?.title || activeArtwork?.description" @click="toggleArtworkDetails" class="max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px] rounded-full border border-gray-2 cursor-pointer flex items-center justify-center ml-auto">
               <font-awesome-icon icon="fa-solid fa-chevron-up" :class="isExpanded ? 'rotate-180' : ''" class="icon transition-all duration-500" />
            </div>
         </div>
         <div v-if="activeArtwork" :class="isExpanded ? 'opacity-1' : 'opacity-0'" class="transition-opacity duration-[800ms] flex flex-col gap-2">
            <div class="text-black text-head-3 md:text-head-2 font-satoshi-medium">{{ activeArtwork?.title }}</div>
            <div class="text-theme-gray-4 text-size-1 md:text-size-3 font-satoshi-regular leading-lh-4 md:leading-lh-3" v-html="!hasFullDescription ? truncateText(activeArtwork?.description.replace(/\n/g, '<br>'), 120) : activeArtwork?.description.replace(/\n/g, '<br>')"></div>
            <div v-if="activeArtwork.description && activeArtwork.description.length > 120" @click="handleReadMoreBtn" class="hover:underline text-theme-blue cursor-pointer mt-2 text-size-3 font-satoshi-medium">Read {{ hasFullDescription ? 'less' : 'more' }}</div>
         </div>
      </div>
      <div :class="isExpanded ? 'h-[500px] md:h-[65%]' : 'h-[700px] md:h-[90%]'" class="flex flex-col bg-white rounded-3xl p-5 gap-8 relative transition-all duration-500">
         <div v-if="selectedPhoto.data !== null" class="max-h-[400px] block h-full w-auto mx-auto overflow-y-auto">
            <img :src="selectedPhoto.data" alt="">
         </div>
         <div v-else class="flex flex-col justify-between h-[88%]">
            <div v-if="authUser && creator?.id == authUser.id" class="flex items-center mb-[10px]">
               <div class="text-black text-head-3 md:text-head-2 font-satoshi-medium">Feedback</div>
               <div @click="handleFeedback" class="cursor-pointer ml-auto flex items-center py-2 px-4 rounded-3xl border border-gray-4 gap-1">
                  <img src="/assets/icons/stars-dark.svg" class="w-[17px]" alt="">
                  <div class="text-black text-size-3 font-satoshi-regular">Ask</div>
               </div>
            </div>
            <div v-else-if="project && authUser && feedbackRequest && feedbackRequest !== ''" class="flex items-center mb-[10px]">
               <div class="text-black text-head-2 font-satoshi-medium">Feedback</div>
               <div @click="handleGiveFeedback" class="cursor-pointer ml-auto flex items-center py-2 px-4 rounded-3xl border border-gray-4 gap-1">
                  <img src="/assets/icons/stars-dark.svg" class="w-[17px]" alt="">
                  <div class="text-black text-size-3 font-satoshi-regular">Provide</div>
               </div>
            </div>
            <div class="flex flex-col w-full overflow-y-auto pt-8 pr-[3px]">
               <div v-for="(comment, index) in comments?.slice().reverse()" :key="index" :class="authUser && comment.creator.id == authUser.id ? 'flex-row-reverse' : 'flex-row'" class="flex items-start gap-3 mb-5 max-w-full">
                  <div>
                     <img v-if="comment.type == 'ai-feedback'" class="rounded-full w-[25px] h-[25px]" :src="assetUrl + 'ai-experts/' + comment.creator.photo" alt="comment creator photo">
                     <img v-else class="rounded-full w-[25px] h-[25px]" :src="comment.creator.photo" alt="comment creator photo">
                  </div>
                  <div class="w-[80%] flex flex-col gap-1 font-satoshi-medium">
                     <div :class="authUser && comment.creator.id == authUser.id ? 'flex-row-reverse' : 'flex-row'" class="flex items-center gap-2">
                        <span class="text-black text-size-3 md:text-head-6">
                           {{ comment.creator.name }}
                           <span v-if="comment.creator.id == creator.id">(Creator)</span>
                           <span v-else-if="comment.isExpert">
                              {{ comment.type == 'ai-feedback' ? '(AI Expert)' : '(Expert)' }}
                           </span>
                        </span>
                     </div>
                     <div :class="authUser && comment.creator.id == authUser.id ? 'text-right bg-white border border-[#f5f5f5]' : 'text-left bg-[#f5f5f5]'" class="flex flex-col p-4 rounded-2xl">
                        <div v-if="comment.type == 'text' || comment.type == 'expert-feedback' || comment.type == 'ai-feedback' || (comment.type == 'audio' && comment.comment !== null)">
                           <div v-html="comment.comment.replace(/\n/g, '<br>')" class="leading-lh-3 text-black text-size-3 font-satoshi-regular"></div>
                           <div v-if="comment.image" class="mt-2">
                              <img @click="handleImageClick(comment)" class="rounded-3xl shadow-custom-light max-h-72 cursor-pointer" :src="getCommentImageSource(comment)" alt="">
                           </div>
                        </div>
                        <div v-if="comment.type == 'audio' || (comment.type == 'expert-feedback' && comment.audio != null)" @click="handlePlayAudio(comment)" :class="authUser && comment.creator.id == authUser.id ? 'flex-row-reverse' : 'flex-row'" class="flex items-center gap-3 cursor-pointer mt-2">
                           
                           <div class="min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center border border-white rounded-full bg-theme-blue-3">
                              <font-awesome-icon v-if="comment.isPlaying" icon="fa-solid fa-pause" class="icon text-white text-xs" />
                              <font-awesome-icon v-else icon="fa-solid fa-play" class="icon text-white text-xs" />
                           </div>
                           <Waveform />
                        </div>
                        <span class="text-theme-gray-4 text-xs md:text-sm mt-1">{{ timeElapsedString(comment.createdAt, 1) }}</span>
                     </div>
                  </div>
                  <div v-if="authUser && authUser.id == comment.creator.id" class="ml-auto relative">
                     <Menu as="div" class="relative">
                        <MenuButton class="flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600 outline-none">
                           <font-awesome-icon icon="fa-solid fa-ellipsis" class="icon text-head-1" />
                        </MenuButton>
                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                           <MenuItems class="absolute translate-y-[10%] translate-x-[80%] right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                              <MenuItem class="hover:bg-gray-100 cursor-pointer py-1 px-4">
                                 <div @click="handleRemoveComment(comment.id)">Delete</div>
                              </MenuItem>
                           </MenuItems>
                        </transition>
                     </Menu>
                  </div>
               </div>
            </div>
         </div>
         <div :class="isExpanded ? 'bottom-3 md:bottom-5' : 'bottom-3 md:bottom-10'" class="absolute left-0 w-full transition-all duration-1000 bg-white">
            <div class="flex flex-col">
               <div :class="audioData ? 'border-t border-gray-300' : ''" class="flex flex-row items-end mt-2 gap-3 relative">
                  <div class="flex flex-col justify-end w-full">
                     <div v-if="audioData" class="py-4 w-[70%] bg-theme-gray-2 rounded-2xl pl-4 mx-5 my-4">
                        <div class="flex items-center gap-3 cursor-pointer flex-row relative">
                           <div class="min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center border border-white rounded-full bg-theme-blue-3">
                              <font-awesome-icon icon="fa-solid fa-play" class="icon text-white text-xs" />
                           </div>
                           <Waveform />
                           <div @click="audioData = null" title="Remove audio" class="absolute top-1/2 transform -translate-y-1/2 right-6 cursor-pointer">
                              <font-awesome-icon icon="fa-solid fa-xmark" class="icon text-black" />
                           </div>
                        </div>
                     </div>
                     <div class="flex flex-row items-center justify-between flex-1 mx-5">
                        <div class="w-2/3 flex flex-row bg-theme-gray-2 rounded-3xl items-start p-2">
                           <div class="flex items-center justify-center w-full">
                              <textarea v-model="comment" @input="autoResizeTextarea" :disabled="isBusy" id="auto-resize-textarea" class="w-full resize-none text-sm bg-transparent outline-none p-1 pl-3" rows="1" placeholder="Leave a comment"></textarea>
                           </div>
                        </div>
                        <transition v-if="showRecorder" enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                           <div class="flex flex-col divide-y divide-theme-gray-9 bg-black w-max absolute left-4 top-0 transform origin-top-right -translate-y-[110%] rounded-2xl outline-none focus:outline-none">
                              <AudioRecorder heading="" :isBusy=isBusy :profilePhoto="creator ? creator.image : null" @submit="handleAudioSubmit" />
                           </div>
                        </transition>
                        <Menu as="div" class="relative" v-slot="{ open }">
                           <input class="hidden" ref="fileInput" @change="handlePhotoChoosen" type="file" name="file" accept="image/*" />
                           <MenuButton @click="handleAddMenuClick" class="flex items-center rounded-full text-gray-500 hover:text-gray-600 outline-none">
                              <div class="bg-white text-center w-[40px] h-[40px] rounded-full flex items-center justify-center cursor-pointer border border-theme-gray pl-[1px] pb-[1px]">
                                 <font-awesome-icon v-if="!open && !showRecorder && !selectedPhoto.data" icon="fa-solid fa-plus" size="lg" class="icon text-black" />
                                 <font-awesome-icon v-else icon="fa-solid fa-xmark" class="icon text-black text-head-2" />
                              </div>
                           </MenuButton>
                           <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                              <MenuItems class="flex flex-col divide-y divide-theme-gray-9 bg-black w-max absolute left-0 top-0 transform origin-top-right -translate-y-[120%] -translate-x-[80%] rounded-2xl outline-none focus:outline-none">
                                 <MenuItem>
                                    <div @click="showRecorder = true" :class="[open ? '' : '', 'py-5 px-10 text-white flex items-center gap-6 text-center text-size-2 cursor-pointer']">
                                       <font-awesome-icon icon="fa-solid fa-microphone" class="icon text-white text-head-2" />
                                       <span class="text-head-3">Record audio</span>
                                    </div>
                                 </MenuItem>
                                 <MenuItem>
                                    <div @click="handleChoosePhoto" :class="[open ? '' : '', 'py-5 px-10 text-white flex items-center gap-6 text-center text-size-2 cursor-pointer']">
                                       <font-awesome-icon icon="fa-solid fa-image" class="icon text-white text-head-2" />
                                       <span class="text-head-3">Add a photo</span>
                                    </div>
                                 </MenuItem>
                              </MenuItems>
                           </transition>
                        </Menu>
                        <div @click="handleSubmit" :class="isBusy ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer'" class="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-theme-blue-3">
                           <img class="w-[17px] mt-[1px]" src="/assets/icons/paper-plane.svg" alt="send comment">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <ConfirmationModal :show="openConfirmModal" @closed="handleConfirmClose">
      <template v-slot:buttons>
         <button @click="askingConfirmed" type="button" :class="isBusy ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer'" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">Remove comment</button>
         <button @click="openConfirmModal = false" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">Cancel</button>
      </template>
   </ConfirmationModal>
   <ModalFit :open="openModal" overlay="dark" :hasShadow="true" @closed="handleModalClose">
      <template v-slot:content>
         <div class="flex flex-col relative">
            <div class="flex flex-col flex-grow">
               <div class="m-auto bg-white md:bg-transparent">
                  <div class="relative w-fit mx-auto">
                     <div @click="handleModalClose" class="z-10 absolute shadow-custom-light top-2 left-2 flex items-center justify-center text-center cursor-pointer ml-auto">
                        <div class="rounded-md bg-white w-10 h-10"></div>
                        <div class="absolute flex items-center justify-center">
                           <font-awesome-icon icon="fa-solid fa-xmark" size="lg" class="icon text-theme-coral" />
                        </div>
                     </div>
                     <img class="max-h-screen mx-auto" :src="(selectedImage as string)">
                  </div>
               </div>
            </div>
         </div>
      </template>
   </ModalFit>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { getAudioBase64, isImageFile, timeElapsedString, isLoggedIn, getAssetBaseUrl, truncateText } from '@/utils/common'
import { collection, addDoc, getDocs, query, where, orderBy } from "firebase/firestore"
import { Comment as CommentType } from '@/types/project'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import AudioRecorder from '@/components/AudioRecorder.vue'
import Project from '@/composables/Project'
import { fb, db, auth, firebaseConfig } from '@/firebase'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'
import Comment from '@/composables/comment'
import ModalFit from '@/widgets/ModalFit.vue'
import Waveform from '@/widgets/Waveform.vue'

const comment = ref<string>('')
const props = defineProps(['creator', 'project', 'activeArtwork', 'feedbackRequest'])
const emit = defineEmits(['success', 'error', 'initFeedback', 'close', 'giveFeedback'])
const isUserLoggedIn = isLoggedIn()
const isBusy = ref<boolean>(false)
const comments = ref<CommentType[] | null>(null)
const showRecorder = ref<boolean>(false)
const audioData = ref<string | null>(null)
const audio = ref<any>({
   player: null,
   isPLaying: false
})
const openConfirmModal = ref<boolean>(false)
const assetUrl = getAssetBaseUrl()
const userData = localStorage.getItem('inFlightAuthUser')
const authUser = userData ? JSON.parse(userData) : null
const openModal = ref<boolean>(false)
const isExpanded = ref<boolean>(false)
const deleteComment = ref<string | null>(null)
const selectedImage = ref<string | null>(null)
const { uploadCreateAudioComment, removeComment, uploadCommentImage } = Project()
const fileInput = ref<HTMLInputElement | null>(null)
const hasFullDescription = ref<boolean>(false)
const selectedPhoto = ref<any>({
   data: null,
   error: ''
})
let currentAudioElement: HTMLAudioElement | null = null
const { createComment, getComments } = Comment()

// @ts-ignore
import $ from 'jquery'

onMounted(async () => {

   if (props.project) {
      fetchProjectComments(props.project.id)
   }
})

const handleClose = () => {
   emit('close')
}

const handleFeedback = () => {
   emit('initFeedback', 1)
}

const handleGiveFeedback = () => {
   emit('giveFeedback', 1)
}

watch(() => props.project, (newVal, oldVal) => {
   fetchProjectComments(newVal.id)
})

const handleConfirmClose = () => openConfirmModal.value = false

const handleModalClose = () => openModal.value = false

const handleReadMoreBtn = () => {
   hasFullDescription.value = !hasFullDescription.value
}

const fetchProjectComments = async (id: string) => {

   const response = await getComments(props.project.id)

   if (response.status == 200) {
      
      comments.value = response.data.comments
   } else {
      const error = handleApiError(response)
      emit('error', error)
   }
}

const handleImageClick = (comment: CommentType) => {

   selectedImage.value = getCommentImageSource(comment)
   openModal.value = true
}

const autoResizeTextarea = () => {
   const textarea : HTMLElement = document.getElementById('auto-resize-textarea') as HTMLElement
   textarea.style.height = 'auto'
   textarea.style.height = textarea.scrollHeight + 'px'
}

const toggleArtworkDetails = () => {
   isExpanded.value = !isExpanded.value
}

const toggleAudio = () => {

   if (audio.value.player && audio.value.isPlaying) {
      audio.value.player.pause()
      audio.value.isPlaying = false
   } else {

      const source = props.project.audio ? `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/audios%2F${props.project.audio}?alt=media` : null
      if (source) {

         if (!audio.value.player) {
            audio.value.player = new Audio(source)
         }
         
         audio.value.player.play()
         audio.value.isPlaying = true

         audio.value.player.addEventListener('ended', () => {
            audio.value.isPlaying = false
         })
      }
   }
}

const handleSubmit = async () => {

   if (isUserLoggedIn) {
      
      if ((comment.value != '' || selectedPhoto.value.data || audioData.value) && !isBusy.value) {
   
         isBusy.value = true
         const imageName = await uploadImage()
         
         try {
   
            if (audioData.value) {

               await uploadAudio()
            } else {

               const commentResponse = await createComment(props.project.id, comment.value, imageName)
               if (commentResponse.status == 201) {
                  clearPhotoInput()
                  await fetchProjectComments(props.project.id)
                  emit('success')
               } else {
                  const error = handleApiError(commentResponse)
                  emit('error', error)
               }
            }
   
            document.getElementById('auto-resize-textarea')!!.style.height = 'auto'
         } catch (error) {
            emit('error', error)
         }
         
         clearForm()
         isBusy.value = false
      }
   } else {
      alert('you are not logged in')
   }
}

const handleAudioSubmit = async (blob: Blob) => {
   audioData.value = await getAudioBase64(blob)
   showRecorder.value = false
}

const uploadAudio = async () => {
   if (audioData.value) {
      const audioResponse = await uploadCreateAudioComment(props.project.id, audioData.value, comment.value)

      if (audioResponse.status == 201) {
         audioData.value = null
         emit('success')
         await fetchProjectComments(props.project.id)
      } else {
         const error = handleApiError(audioResponse)
         emit('error', error)
      }
   }
}

const uploadImage = async () : Promise<string | null> => {
   if (selectedPhoto.value.data != null) {
      const imageResponse = await uploadCommentImage(selectedPhoto.value.data)

      if (imageResponse.status == 201) {
         return imageResponse.data.image
      } else {
         const error = handleApiError(imageResponse)
         return null
      }
   }

   return null
}

const handleApiError = (error : any) => {
   if(error.response){
      const message = error.response.data ? error.response.data.error : error.response.statusText
      return `status code: ${error.response.status} - message: ${message}`
   }

   return error.message
}

const handlePlayAudio = (comment: CommentType) => {

   let stopPlay = false

   if (currentAudioElement) {
      currentAudioElement.pause()
      currentAudioElement = null
   }

   comments.value?.forEach((comm: CommentType) => {
      if (comm.isPlaying && comm.id == comment.id) {
         stopPlay = true
      }
      comm.isPlaying = false
   })

   if (!stopPlay) {

      comment.isPlaying = true
      const audioFile = comment.audio
      const source = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/audios%2F${audioFile}?alt=media`
      const audioElement = new Audio(source)
      currentAudioElement = audioElement
      
      audioElement.addEventListener('ended', () => {
         comment.isPlaying = false
      })
   
      audioElement.play()
   }
}

const getCommentImageSource = (comment: CommentType) => {
   return `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/comments%2F${comment.image}?alt=media`
}

const handleRemoveComment = async (commentId: string) => {

   deleteComment.value = commentId
   openConfirmModal.value = true
}

const askingConfirmed = async () => {
   if (!isBusy.value && deleteComment.value) {
      isBusy.value = true
      const response = await removeComment(deleteComment.value)

      if (response.status == 204) {
         await fetchProjectComments(props.project.id)
         emit('success')
      } else {
         const error = handleApiError(response)
         emit('error', error)
      }

      isBusy.value = false
      openConfirmModal.value = false
   }  
}

const handleAddMenuClick = () => {
   showRecorder.value = false
   clearPhotoInput()
}

const handleChoosePhoto = () => {
   fileInput.value?.click()
}

const clearForm = () => {
   comment.value = ''
}

const clearPhotoInput = () => {
   selectedPhoto.value = {
      data: null,
      error: ''
   }
   $('input[type="file"]').val('')
}

const handlePhotoChoosen = (e: Event) => {

   selectedPhoto.value.data = null
   const inputElement = e.target as HTMLInputElement

   if (inputElement && inputElement.files && inputElement.files[0]) {
      const file = inputElement.files[0]
      const reader = new FileReader()

      if (!isImageFile(file)) {
         selectedPhoto.value.error = 'File must be an image type (jpg, png, webp)'
      } else if (file.size > 20971520) {
         selectedPhoto.value.error = 'Image must be 20MB or less'
      }

      if (!selectedPhoto.value.error) {
         reader.onloadend = () => {
            selectedPhoto.value.data = reader.result as string
         }
      } else {
         alert(selectedPhoto.value.error)
      }

      reader.readAsDataURL(file)
   }
}

</script>

<style scoped>

::-webkit-scrollbar { width: 0; }

</style>
<template>

   <div class="flex items-end gap-[4px]">
      <div class="bg-gray-400 h-[22px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[14px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[22px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[15px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[22px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[14px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[22px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[22px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[14px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[22px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[9px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[22px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[14px] w-[6px] rounded-lg"></div>
      <div class="bg-gray-400 h-[22px] w-[6px] rounded-lg"></div>
   </div>

</template>

<script lang="ts" setup>

</script>
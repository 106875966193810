<template>
   <div class="w-[390px] max-w-full">
      <div class="flex flex-col">
         <div class="w-full bg-skeleton-1 rounded-2xl p-4">
            <div class="h-full flex items-start gap-4">
               <div class="rounded-2xl w-[75px] h-[75px] bg-skeleton-2"></div>
               <div class="h-full flex flex-col items-start flex-1 gap-2">
                  <div class="w-[50%] h-[25px] bg-skeleton-2 rounded-lg"></div>
                  <div class="w-[30%] h-[25px] bg-skeleton-2 rounded-lg"></div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">

</script>
import { UserType } from "@/types/user"

export const getAssetBaseUrl = () => {
   return process.env.VUE_APP_ASSET_BASE_URL || 'https://ik.imagekit.io/z8bjxyium/'
}

export const getMaxAudioLength = () => {
   return process.env.VUE_APP_MAX_AUDIO_LENGTH || 60
}

export const getBackendBaseUrl = () => {

   const url = window.location.href

   if (url.includes('localhost')) {
      return process.env.VUE_APP_BACKEND_BASE_URL || 'http://localhost:9877'
   } 

   return process.env.VUE_APP_BACKEND_BASE_URL || 'https://us-central1-status-8633a.cloudfunctions.net/api'
}

export const objectHasKey = (obj: any, key: string) => {
   return obj.hasOwnProperty(key) && obj[key] !== null
}

export const handleApiError = (error : any) : string => {
   
   let message = ''
   if(error.response){
      if (error.response.data) {
         if (error.response.data.msg) {
            return `status code: ${error.response.status} - message: ${error.response.data.msg}`
         } else {
            return `status code: ${error.response.status}`
         }
      }

      return `status code: ${error.response.status} - message: ${message}`
   }

   return error.message
}

export function isImageFile(file : File) : boolean {
   
   const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
   return allowedTypes.includes(file.type)
}

export function getUserSpecialities(user : UserType) : any {
   
   return user.specialities ? user.specialities.split(',') : []
}

export function createLoginRedirect(url : string) : void {
   
   localStorage.setItem('inFlightLoginRedirect', url)
}

export function getLoginRedirect() : any {
   
   return localStorage.getItem('inFlightLoginRedirect')
}

export function removeLoginRedirect() : void {
   
   return localStorage.removeItem('inFlightLoginRedirect')
}

export function createFriend(number: string) : void {
   
   localStorage.setItem('inFlightCreateFriend', `${number}`)
}

export function getFriend() : any {
   
   return localStorage.getItem('inFlightCreateFriend')
}

export function removeFriend() : void {
   
   return localStorage.removeItem('inFlightCreateFriend')
}

export const generateVideoThumbnail = async (videoFile: any) : Promise<string> => {
   return new Promise((resolve) => {
      const video = document.createElement('video')
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      video.addEventListener('loadeddata', () => {
         canvas.width = video.videoWidth
         canvas.height = video.videoHeight
         ctx?.drawImage(video, 0, 0, canvas.width, canvas.height)

         const thumbnailDataUrl = canvas.toDataURL('image/png')
         resolve(thumbnailDataUrl)
      })

      const objectURL = URL.createObjectURL(videoFile)
      video.src = objectURL
   })
}

export function isVideoFile(file: File): boolean {
   const allowedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg']
   return allowedVideoTypes.includes(file.type)
}

export function isHEVCFile(file: File): boolean {
   return file.name.includes('.hevc')
}

export function getFirstName(fullName: string) : string {
   
   const nameParts = fullName.split(' ')
   return nameParts[0]
}

export function isLoggedIn() : boolean {
   
   const userData = localStorage.getItem('inFlightAuthUser')
   const user = userData ? JSON.parse(userData) : null
   return user && user.id
}

export function copyToClipboard(text: string) {
   if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
         console.log('Text copied to clipboard')
      }).catch(err => {
         console.error('Unable to copy text to clipboard: ', err)
      })
   } else {
      const textArea = document.createElement('textarea')
      textArea.value = text
      textArea.style.position = 'fixed'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
         const successful = document.execCommand('copy')
         const msg = successful ? 'Text copied to clipboard' : 'Unable to copy text to clipboard'
         console.log(msg)
      } catch (err) {
         console.error('Unable to copy text to clipboard: ', err)
      }

      document.body.removeChild(textArea)
   }
}

export function timeElapsedString(datetime: number, level: number = 7, outputFormat: string = 'normal'): string {
   const now = new Date()
   const ago = new Date(datetime)
   let diffInMilliseconds = now.getTime() - ago.getTime()
   const levels: Record<string, number> = {
     year: 365 * 24 * 60 * 60 * 1000,
     month: 30 * 24 * 60 * 60 * 1000,
     week: 7 * 24 * 60 * 60 * 1000,
     day: 24 * 60 * 60 * 1000,
     hour: 60 * 60 * 1000,
     min: 60 * 1000,
     sec: 1000,
   }

   let string : Record<string, string>
 
   if (outputFormat == 'normal') {

      string = {
        year: 'year',
        month: 'month',
        week: 'week',
        day: 'day',
        hour: 'hour',
        min: 'min',
        sec: 'sec',
      }
   } else {

      string = {
         year: 'y',
         month: 'mon',
         week: 'w',
         day: 'd',
         hour: 'h',
         min: 'm',
         sec: 's',
       }
   }
 
   const result: string[] = []
 
   for (const key in string) {
     if (diffInMilliseconds >= levels[key]) {
       const count = Math.floor(diffInMilliseconds / levels[key])
       result.push(`${count} ${string[key]}${count > 1 && outputFormat == 'normal' ? 's' : ''}`)
       diffInMilliseconds -= count * levels[key]
     }
   }
 
   const output = result.slice(0, level).join(', ')
 
   return output.length > 0 ? output + ' ago' : 'just now'
}

export const formatedDateTime = (input: number) => {

   const date = new Date(input)
   return date.toLocaleString()
}

export function installPWA() {
   if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
         console.log('ServiceWorker registration successful with scope: ', registration.scope)
      }, function(err) {
         console.log('ServiceWorker registration failed: ', err)
      })
   }
}

export function truncateText(input: string, limit: number) : string {
   if (input.length > limit) {
      return input.substring(0, limit) + '...'
   }
   return input
}

export function trimString(str: string, maxLength: number): string {
   if (str.length > maxLength) {
     return str.slice(0, maxLength) + '...'
   } else {
     return str
   }
}

export const getAudioBase64 = async (blob: Blob): Promise<string> => {
   return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => {
         const base64Data = reader.result as string
         resolve(base64Data)
      }

      reader.onerror = (error) => {
         reject(error)
      }

      reader.readAsDataURL(blob)
   })
}

export const encodeString = (value: string, mode: 'hex' | 'base64') : string => {
   
   if (mode == 'hex') {
      const encoder = new TextEncoder()
      const encodedValue = Array.from(encoder.encode(value)).map(byte => byte.toString(16).padStart(2, '0')).join('')
   
      return encodedValue
   } 
   
   return value
}

export const updateAuthUser = (user : any) : void => {

   localStorage.setItem('inFlightAuthUser', JSON.stringify({
      'id' : user.user_id, 
      'name' : user.name, 
      'number' : user.number,
      'email' : user.email, 
      'image' : user.photo, 
      'bio' : user.bio, 
      'type' : 'admin'
   }))
}

export const generateUserId = (length: number) => {

   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
   let userId = ''
 
   for (let i = 0; i < length; i++) {
     const randomIndex = Math.floor(Math.random() * characters.length)
     userId += characters.charAt(randomIndex)
   }
 
   return userId
}

export const removeAuthUser = () : void => {

   localStorage.removeItem('inFlightAuthUser')
   localStorage.removeItem('inFlightAuthUserTokens')
}

export function isValidEmail (email: string) : boolean {
   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
   return emailRegex.test(email)
}

export function isUserAuthorized () : boolean {
   const userData = localStorage.getItem('inFlightUserAddAuth')

   if (userData) {
      if (userData !== 'SW5mbGlnaHQyNA==') {
         return false
      }

      return true
   } else {
      return false
   }
}

export const disableScroll = () => {
   document.body.style.overflow = 'hidden'
}

export const enableScroll = () => {
   document.body.style.overflow = ''
}

export function makeUserAuthorized () : void {
   localStorage.setItem('inFlightUserAddAuth', 'SW5mbGlnaHQyNA==')
}

export function shareOnX(url: string) {
   var tweetText = ''
   var encodedTweet = encodeURIComponent(tweetText);
   var encodedUrl = encodeURIComponent(url);
   var twitterIntentUrl = 'https://twitter.com/intent/tweet?text=' + encodedTweet + '&url=' + encodedUrl
   window.open(twitterIntentUrl, 'Twitter Share', 'width=600, height=400')
}
<template>
   <div class="w-full flex flex-col gap-8 mt-12 px-6 md:px-0 md:w-[93%] mx-auto mb-24">
      <div class="text-sm text-gray-500">
         A list of all the users in our database
      </div>
      <div class="flex flex-col gap-12">
         <div class="flex items-center">
            <div class="w-full">
               <input v-model="searchInput" class="max-w-[50%] md:max-w-[25%] border-gray-300 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm" type="text" placeholder="Search">
               <div class="flex items-center gap-4 mt-5">
                  <Switch v-model="handleSwitch" class="outline-none group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none">
                     <span class="sr-only">Switch to AI Experts</span>
                     <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                     <span aria-hidden="true" :class="[handleSwitch ? 'bg-indigo-600' : 'bg-gray-200', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']" class="outline-none" />
                     <span aria-hidden="true" :class="[handleSwitch ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']" />
                  </Switch>
                  <div class="mt-1 text-gray-600">Currently showing {{ handleSwitch ? 'AI experts' : 'users' }}</div>
               </div>
            </div>
            <div class="mt-4 ml-auto sm:mt-0 sm:flex-none">
               <button @click=handleAddUser  type="button" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-md font-semibold text-white shadow-sm hover:bg-indigo-500 outline-none">
                  Add user
               </button>
            </div>
         </div>
         <div class="text-md">
            <table v-if="!handleSwitch" class="min-w-full divide-y divide-gray-300 border">
               <thead>
                  <tr>
                     <th scope="col" class="py-6 pl-4 w-[30%] pr-3 text-left font-medium text-gray-900 sm:pl-6">Name</th>
                     <th scope="col" class="px-3 py-6 w-[30%] text-left font-medium text-gray-900">Sub Text</th>
                     <th scope="col" class="px-3 py-6 text-left font-medium text-gray-900 text-center">Role</th>
                     <th scope="col" class="px-3 py-6 text-left font-medium text-gray-900 text-center">Created At</th>
                     <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span class="sr-only">Edit</span>
                     </th>
                  </tr>
               </thead>
               <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="user in filteredUsers" :key="user.email" class="divide-x divide-gray-200">
                     <td class="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 flex gap-4 items-center">
                        <img class="w-[50px] h-[50px] rounded-full" :src="user.image" alt="">
                        <div class="flex flex-col items-start">
                           <div class="text-gray-900 font-medium">{{ user.name }}</div>
                           <div class="text-gray-500">{{ user.email }}</div>
                        </div>
                     </td>
                     <td class="whitespace-nowrap px-3 py-4 text-gray-500">{{ user.subText }}</td>
                     <td class="whitespace-nowrap px-3 py-4 text-gray-500 text-center">
                        <span :class="user.vip == 'yes' ? 'bg-green-50 text-green-700 ring-green-600/20' : 'bg-gray-50 text-gray-700 ring-gray-600/20'" class="inline-flex items-center rounded-md px-2 text-sm font-medium ring-1 ring-inset px-3 pt-1">
                           {{ user.vip == 'yes' ? 'Expert' : 'Member' }}
                        </span>
                     </td>
                     <td class="whitespace-nowrap px-3 py-4 text-gray-500 text-center">{{ formatedDateTime(parseInt(user.createdAt as string)) }}</td>
                     <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-center sm:pr-6 text-indigo-600">
                        <span @click=handleEditUser(user) class="hover:text-indigo-900 cursor-pointer">Edit</span> | 
                        <span @click=handleDeleteUser(user) class="hover:text-indigo-900 cursor-pointer">Delete</span>
                     </td>
                  </tr>
               </tbody>
            </table>
            <table v-else class="min-w-full divide-y divide-gray-300 border">
               <thead>
                  <tr>
                     <th scope="col" class="py-6 pl-4 w-[30%] pr-3 text-left font-medium text-gray-900 sm:pl-6">Name</th>
                     <th scope="col" class="px-3 py-6 w-[30%] text-left font-medium text-gray-900">Sub Text</th>
                     <th scope="col" class="px-3 py-6 w-[30%] text-left font-medium text-gray-900 text-center">Persona</th>
                     <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span class="sr-only">Edit</span>
                     </th>
                  </tr>
               </thead>
               <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="aiExpert in aiExperts" :key="aiExpert.name" class="divide-x divide-gray-200">
                     <td class="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 flex gap-4 items-center">
                        <img class="w-[50px] h-[50px] rounded-full" :src="`${assetUrl}ai-experts/${aiExpert.image}`" alt="">
                        <div class="flex flex-col items-start">
                           <div class="text-gray-900 font-medium">{{ aiExpert.name }}</div>
                           <div class="text-gray-500">{{ aiExpert.email }}</div>
                        </div>
                     </td>
                     <td class="whitespace-nowrap px-3 py-4 text-gray-500">{{ aiExpert.subText }}</td>
                     <td class="whitespace-nowrap px-3 py-4 text-gray-500 text-center">
                        {{ truncateText(aiExpert.persona as string, 50) }}
                     </td>
                     <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-center sm:pr-6 text-indigo-600">
                        <span @click=handleEditAiExpert(aiExpert) class="hover:text-indigo-900 cursor-pointer">Edit</span>
                     </td>
                  </tr>
               </tbody>
            </table>
            <SlideOver :show="openSaveUser" @closed="openSaveUser = false" class="px-4 sm:px-6">
               <template v-slot:content>
                  <SaveUser :userr="editUser" :type="editType" @success="handleUserSuccess" @error="handleUserError" />
               </template> 
            </SlideOver>
         </div>
      </div>
   </div>
   <ConfirmationModal :show="openConfirmModal" @closed="handleConfirmClose">
      <template v-slot:buttons>
         <button @click="askingConfirmed" type="button" :class="isBusy ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer'" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">Delete user</button>
         <button @click="openConfirmModal = false" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">Cancel</button>
      </template>
   </ConfirmationModal>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { UserType } from '@/types/user'
import { computed, onMounted, ref } from 'vue'
import SaveUser from '@/components/SaveUser.vue'
import SlideOver from '@/widgets/SlideOver.vue'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'
import { NotificationType } from '@/types/common'
import { formatedDateTime, getAssetBaseUrl, truncateText } from '@/utils/common'
import Notification from '@/widgets/Notification.vue'
import Admin from '@/composables/Admin'
import { Switch } from '@headlessui/vue'

const searchInput = ref<string>('')
const openConfirmModal = ref<boolean>(false)
const deleteUser = ref<UserType>()
const isBusy = ref<boolean>(false)
const openSaveUser = ref<boolean>(false)
const assetUrl = getAssetBaseUrl()
const editUser = ref<UserType | null>(null)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const users = ref<UserType[]>([])
const aiExperts = ref<UserType[]>([])
const editType = ref<any>('user')
const { allUsers, allAiExperts, removeUser } = Admin()

const handleConfirmClose = () => openConfirmModal.value = false

const filteredUsers = computed(() => {
   const searchTerm = searchInput.value.toLowerCase()

   return users.value.filter((user: any) =>
      user.name.toLowerCase().includes(searchTerm)
   )
})

const handleSwitch = ref<boolean>(false)

const handleUserSuccess = async () => {
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'User saved successfully'}
   openSaveUser.value = false
   setTimeout(() => { notifClosed() }, 2000)

   if (editType.value == 'user') {

      await fetchUsers()
   } else {
      await fetchAiExperts()
   }
}

const handleUserError = (error: string) => {
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error }
   setTimeout(() => { notifClosed() }, 2000)
}

const askingConfirmed = async () => {
   if (!isBusy.value && deleteUser.value) {
      isBusy.value = true
      await removeUser(deleteUser.value.id)

      isBusy.value = false
      openConfirmModal.value = false
      await fetchUsers()
   }  
}

const handleEditUser = (user: UserType) => {
   editUser.value = user
   editType.value = 'user'
   openSaveUser.value = true
}

const handleEditAiExpert = (expert: UserType) => {
   editUser.value = expert
   editType.value = 'ai'
   openSaveUser.value = true
}

const handleDeleteUser = (user: UserType) => {
   deleteUser.value = user
   openConfirmModal.value = true
}

const handleAddUser = () => {
   editUser.value = null
   openSaveUser.value = true
}

const notifClosed = () => {
   notif.value.show = false
}

const fetchUsers = async () => {
   const response = await allUsers()
   if (response.status == 200) {
      users.value = response.data.users
   }
}

const fetchAiExperts = async () => {
   const response = await allAiExperts()
   if (response.status == 200) {
      aiExperts.value = response.data.users
   }
}

onMounted(async () => {

   await fetchUsers()
   await fetchAiExperts()
})

</script>
<template>
   <div class="w-72 bg-black rounded-3xl py-4 shadow-lg flex items-center justify-center py-8">
      <div class="flex flex-col items-center justify-center gap-12">
         <div v-if="heading && heading != ''" class="text-white text-center text-head-3 mx-8">{{ heading }}</div>
         <div :class="!heading || heading == '' ? 'mt-4' : ''" class="relative flex flex-col items-center justify-center">
            <div v-if="recorder.isRecording" class="w-[106px] h-[106px] absolute">
               <div class="absolute transition-all delay-100 w-full h-full ellipsis-one">
                  <img src="/assets/img/ellipse_1.svg" alt="">
               </div>
               <div class="absolute transition-all delay-100 w-full h-full ellipsis-two">
                  <img src="/assets/img/ellipse_2.svg" alt="">
               </div>
               <div class="absolute transition-all delay-100 w-full h-full ellipsis-three">
                  <img src="/assets/img/ellipse_3.svg" alt="">
               </div>
            </div>
            <div class="relative z-20">
               <img class="w-[7rem] h-[7rem] rounded-full" :src="profilePhoto" alt="profile picture">
            </div>
         </div>

         <div v-if="recorder.isRecording" @click="stopRecording" class="bg-theme-gray-9 rounded-3xl text-head-1 font-bold px-6 py-3">
            <button class="text-white">{{ recorder.recordingTime }} - Stop</button>
         </div>
         <div v-else-if="!recorder.isRecording && !recorder.hasRecordingEnded" @click="startRecording" class="bg-theme-blue rounded-3xl text-head-1 font-bold px-6 py-3">
            <button class="text-white">Start Recording</button>
         </div>
         <div v-else class="text-head-1 font-bold flex items-center gap-3">
            <button @click="handleRetry" :class="isBusy ? 'opacity-70 cursor-not-allowed' : ''" class="bg-theme-gray-9 rounded-3xl text-white px-6 py-3 ">Retry</button>
            <button @click="handleSubmit" :class="isBusy ? 'opacity-70 cursor-not-allowed' : 'hover:opacity-95'" class="bg-theme-blue rounded-3xl text-white px-6 py-3">Add</button>
         </div>
         
      </div>
   </div>
</template>

<script setup lang=ts>
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { Recorder } from '@/types/recorder'
import { getMaxAudioLength } from '@/utils/common'

const props = defineProps(['profilePhoto', 'isBusy', 'heading'])
const emit = defineEmits(['submit'])
const maxAudioLength = getMaxAudioLength()
let recorder: Recorder = reactive({
   mediaRecorder: null,
   isRecording: false,
   hasRecordingEnded: false,
   audioChunks: [],
   timer: null,
   recordingTime: '00:00',
})

onMounted(() => {

   navigator.permissions.query({name: 'microphone' as PermissionName}).then(function (permissionObj) {
      
   })
})

onBeforeUnmount(() => {

   stopRecording()
})

const startRecording = async () => {
   try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      recorder.mediaRecorder = new MediaRecorder(stream)
      recorder.audioChunks = []

      recorder.mediaRecorder.ondataavailable = (event: any) => {
         if (event.data.size > 0) {
            recorder.audioChunks.push(event.data)
         }
      }

      recorder.mediaRecorder.onstop = () => {
         postStop()
      }

      recorder.mediaRecorder.start()
      postStart()

   } catch (error) {
      console.error('Error starting recording:', error)
   }
}

const stopRecording = () => {
   if (recorder.mediaRecorder && recorder.mediaRecorder.state === 'recording') {
      recorder.mediaRecorder.stop()
   }
}

const postStop = () => {
   const tracks = recorder.mediaRecorder?.stream.getTracks()
   tracks!!.forEach((track: any) => track.stop())

   recorder.isRecording = false
   recorder.hasRecordingEnded = true

   stopTimer()
}

const postStart = () => {
   recorder.isRecording = true
   recorder.hasRecordingEnded = false

   startTimer()
}

const createAudio = () => {
   const audioBlob = new Blob(recorder.audioChunks)
   const audioUrl = URL.createObjectURL(audioBlob)
   const audio = new Audio(audioUrl)
   
   return {
      audioBlob,
      audio
   }
}

const startTimer = () => {
   let seconds = 0

   recorder.timer = setInterval(() => {

      if (maxAudioLength && seconds == maxAudioLength) {
         stopRecording()
      } else {
         seconds++
         recorder.recordingTime = formatTime(seconds)
      }
   }, 1000)
}

const stopTimer = () => {
   clearInterval(recorder.timer)
}

const formatTime = (seconds: number) => {
   const minutes = Math.floor(seconds / 60)
   const remainingSeconds = seconds % 60
   return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
}

const handleRetry = () => {
   
   recorder.isRecording = false
   recorder.hasRecordingEnded = false
}

const handleSubmit = async () => {
   
   const { audioBlob, audio } = createAudio()

   emit('submit', audioBlob)
}

</script>

<style scoped>
   @keyframes animation-one {
      from { left: 8px; top: 6px; }
      to { left: -8px; top: -6px; }
   }

   @keyframes animation-two {
      from { right: 8px; top: 6px; }
      to { right: -8px; top: -6px; }
   }

   @keyframes animation-three {
      from { bottom: 8px; }
      to { bottom: -8px; }
   }
   

   .ellipsis-one {
      animation: animation-one 2s infinite alternate ease-in-out;
      left: 8px;
      top: 6px;
   }
   .ellipsis-two {
      animation: animation-two 2s infinite alternate ease-in-out;
      right: 8px;
      top: 6px;
   }
   .ellipsis-three {
      animation: animation-three 2s infinite alternate ease-in-out;
      bottom: 8px;
   }
</style>
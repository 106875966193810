<template>
   <div class="w-[21rem] rounded-xl py-4 shadow-custom-symetric bg-white flex flex-col items-start">
      <div class="flex flex-col w-full">
         <!--<div @click="$router.push({name: 'Profile', params: {id: ''}})" class="flex flex-row items-center cursor-pointer text-black text-size-2 px-6 py-4 hover:bg-gray-100">
            <div class="flex flex-row items-center gap-4">
               <font-awesome-icon icon="fa-regular fa-user-circle" class="icon text-black text-size-2" />
               <span class="text-size-3">View Profile</span>
            </div>
         </div>-->
         <div @click="$router.push({name: 'ProfileEdit'})" class="flex flex-row items-center cursor-pointer text-black text-size-2 px-6 py-4 hover:bg-gray-100">
            <div class="flex items-center gap-4">
               <font-awesome-icon icon="fa-solid fa-pencil" class="icon text-black text-size-2" />
               <span class="text-size-3">Edit Profile</span>
            </div>
         </div>
         <div @click="handleLogout" class="flex flex-row items-center cursor-pointer text-black text-size-2 px-6 py-4 hover:bg-gray-100">
            <div class="flex item-center gap-4">
               <font-awesome-icon icon="fa-solid fa-sign-out-alt" class="icon text-[#fc064c] text-size-2 mt-1" />
               <span class="text-size-3 text-[#fc064c]">Log out</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang=ts>
import { ref } from 'vue'
import { removeAuthUser } from '@/utils/common'
import { useRouter } from 'vue-router'

const router = useRouter()

const handleLogout = () => {

   removeAuthUser()
   router.push({name: 'SignIn'})
}

</script>
<template>
  <div class="flex flex-row items-center justify-center md:mx-theme-desktop mx-theme my-4 md:my-7 md:z-50 md:sticky">
      <div @click="$router.push({name: 'Home'})" class="w-fit cursor-pointer" :class="openSearch ? 'hidden' : ''">
         <img class="w-[8rem]" src="/assets/logo.svg" alt="Application logo" />
      </div>
      <div v-if="auth" class="flex items-center w-full">
         <div class="flex flex-row items-center gap-5 md:gap-7 ml-auto" :class="openSearch ? 'hidden' : ''">
            <div v-if="authUser.email == ADMIN_EMAIL" @click="$router.push({name: 'UserList'})" class="cursor-pointer">
               <img src="/assets/icons/add-user-2.svg" class="w-[1.47rem] h-[1.47rem]" alt="">
            </div>
            <div @click="$router.push({name: 'SaveProject', params: {id: ''}})" class="cursor-pointer">
               <img src="/assets/icons/Vector.svg" class="w-[1.47rem] h-[1.47rem]" alt="">
            </div>
            <Menu v-slot="{ open }" as="div" class="relative">
               <MenuButton class="flex items-center rounded-full text-gray-500 hover:text-gray-600 outline-none">
                  <div @click="notificationSeen" class="cursor-pointer relative">
                     <img src="/assets/icons/bell.svg" class="w-[1.47rem] h-[1.47rem]" alt="">
                     <div v-if="hasUnseenNotifs" class="w-[10px] h-[10px] bg-[#e4606d] absolute top-0 right-0 rounded-full"></div>
                  </div>
               </MenuButton>
               <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="z-10 w-max absolute left-0 top-0 -translate-x-[60%] translate-y-[60px] transform origin-top-right bg-transparent outline-none focus:outline-none">
                     <MenuItem v-slot="{ active }">
                        <UserNotificationMenu :data="notifications" @click.stop="handleMenuItemClick" />
                     </MenuItem>
                  </MenuItems>
               </transition>
            </Menu>
            <!--<div @click="toggleSearch" class="cursor-pointer">
               <img src="/assets/icons/search.svg" class="w-[1.47rem] h-[1.47rem]" alt="">
            </div>-->
            <Menu v-slot="{ open }" as="div" class="relative">
               <MenuButton class="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600 outline-none">
                  <div v-if="auth" class="cursor-pointer bg-no-repeat bg-cover bg-center rounded-full min-w-[40px] max-w-40px] min-h-[40px] max-h-[40px]" :style="{ backgroundImage: `url(${authUser.image})` }">
                  </div>
               </MenuButton>
               <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="z-10 w-max absolute left-0 top-0 -translate-x-[90%] md:-translate-x-[85%] translate-y-[25%] md:translate-y-[35%] transform origin-top-right bg-transparent outline-none focus:outline-none">
                     <MenuItem v-slot="{ active }">
                        <UserTopMenu />
                     </MenuItem>
                  </MenuItems>
               </transition>
            </Menu>
         </div>
         <div class="flex flex-row items-center gap-2 md:gap-7 w-full h-[2.26rem] md:h-[3.33rem]" :class="openSearch ? 'animate__animated animate__bounceInRight' : 'hidden'">
            <div class="cursor-pointer">
               <img src="/assets/icons/search.svg" class="w-[1.47rem] h-[1.47rem]" alt="">
            </div>
            <div class="flex-1 bg-transparent">
               <input type="text" placeholder="Search Designs" class="outline-none border-none bg-transparent pl-2 h-[2.26rem]">
            </div>
            <div @click="toggleSearch" class="cursor-pointer ml-auto">
               <img src="/assets/icons/cross.svg" class="w-[1.2rem] h-[1.2rem]" alt="">
            </div>
         </div>
      </div>
     <div v-else class="ml-auto flex items-center font-satoshi-regular">
       <button
           class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden absolute z-50 right-4"
           @click="showDrawer = !showDrawer"
       >
         <a class="icon-toggle">
           <span v-for="n in 3" :class="[showDrawer ? 'x' : '', 'bar']"></span>
         </a>
       </button>
       <div class="hidden w-full md:block md:w-auto">
         <div class="flex items-center">
           <div
               class="justify-center px-8 py-4 text-[13px] tracking-tight leading-4 text-black whitespace-nowrap bg-zinc-100 rounded-[999px] cursor-pointer"
               @click="$router.push({ name: 'SignIn' })">
             Log In
           </div>
           <div
               class="justify-center px-7 py-4 text-[13px] tracking-tight leading-4 text-white whitespace-nowrap bg-blue-600 rounded-full ml-2 cursor-pointer hidden md-2:block"
               @click="$router.push({ name: 'Apply' })">
             Sign Up
           </div>
         </div>
       </div>
     </div>
     <div
         :class="showDrawer ? 'top-0' : 'top-[-240px]'"
         class="fixed z-40 bg-white w-screen drop-shadow-lg h-[240px] md:hidden font-satoshi"
         style="transition: top 0.5s ease-in-out"
     >
       <div class="mt-[120px]">
         <div
             class="flex justify-center border-b pb-5 text-base font-normal cursor-pointer"
             @click="$router.push({ name: 'SignIn' })"
         >
           Log In
         </div>
         <div
             class="flex justify-center py-5 text-[#2666FF] text-base font-normal cursor-pointer font"
             @click="$router.push({ name: 'Apply' })"
         >
           Sign Up
         </div>
       </div>
     </div>
   </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import UserTopMenu from '@/components/UserTopMenu.vue'
import UserNotificationMenu from '@/components/UserNotificationMenu.vue'
import { ADMIN_EMAIL } from '@/utils/constants'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import User from '@/composables/User'
import 'animate.css'
import { NotificationType } from '@/types/notification'
import { handleApiError } from '@/utils/common'

const showDrawer = ref(false);

let auth = localStorage.getItem('inFlightAuthUser')
let authUser = ref<any>(null)
const openSearch = ref<boolean>(false)
const notifications = ref<NotificationType[]>()
const { getNotifications, tagNotificationSeen } = User()
const isBusy = ref<boolean>(false)
const hasUnseenNotifs = ref<boolean>(false)
const toggleSearch = () => {
   openSearch.value = !openSearch.value
}

const handleMenuItemClick = (e: Event) => {
   e.preventDefault();
}

if (auth) {
   authUser = JSON.parse(auth)
}

const notificationSeen = async () => {

   if (!isBusy.value) {
      
      hasUnseenNotifs.value = false
      isBusy.value = true
      const response = await tagNotificationSeen('all')
   }
}

onMounted(async () => {

   const response = await getNotifications()
   if (response.status == 200) {
      notifications.value = response.data.notifications
      hasUnseenNotifs.value = response.data.notifications.some((notif: NotificationType) => !notif.seen)
   } else {
      const error = handleApiError(response)
      console.log(error)
   }
})

</script>

<style scoped>
.icon-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 18px;
  width: 18px;
}

.icon-toggle .bar {
  height: 2px;
  width: 100%;
  background-color: black;
  transition: all 200ms ease-in-out;
}

.icon-toggle:hover {
  cursor: pointer;
}

.x:nth-of-type(1) {
  transition: all 200ms ease-in-out;
  transform: rotate(-45deg);
  transform-origin: bottom right;
  width: 22px;
}

.x:nth-of-type(2) {
  transition: all 200ms ease-in-out;
  transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 200ms ease-in-out;
  transform: rotate(45deg);
  transform-origin: top right;
  width: 22px;
}
</style>
import { getBackendBaseUrl } from "@/utils/common"
import axios from "axios"
import Auth from "./Auth"

const baseUrl : string = getBackendBaseUrl()
const { getToken } = Auth()

const User = () => {

   const getTokens = () => {
      let tokenData = localStorage.getItem('inFlightAuthUserTokens')
      let tokens = tokenData ? JSON.parse(tokenData) : null
      return tokens
   }

   const hasTokens = () => {
      return localStorage.getItem('inFlightAuthUserTokens') !== null
   }

   const getHeaders = () => { 

      const headers : any = { 'Content-Type': 'application/json'}

      if (hasTokens()) {
         headers['Authorization'] = `Bearer ${getTokens().access}`
      }

      return headers
   }

   const getUser = async (id : string) => {

      try {
         const response = await axios.get(baseUrl + '/get-user?id=' + id, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.get(baseUrl + '/get-user?id=' + id, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const toggleFollow = async (id : string) => {

      try {
         const response = await axios.post(baseUrl + '/toggle-follow-user', { id: id }, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.post(baseUrl + '/toggle-follow-user', { id: id }, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const addUser = async (id : string, name: string, email: string, photo: string | null, additionalInfo: any) => {
      try {
         const response = await axios.post(baseUrl + '/add-user', { id: id, name: name, email: email, photo: photo, additionalInfo: additionalInfo}, {headers: {}})
         return response
      } catch (err : any) {
         return err
      }
   }

   const updateUser = async (user : {displayName: string, bio: string, photo: string}) => {
      try {
         const response = await axios.put(baseUrl + '/update-user', { name: user.displayName, bio: user.bio, photo: user.photo }, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.put(baseUrl + '/update-user', { name: user.displayName, bio: user.bio }, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const removeUser = async (id: string) => {
      try {
         const response = await axios.delete(baseUrl + '/remove-user', { headers: getHeaders(), data: { } })
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.delete(baseUrl + '/remove-user', { headers: getHeaders(), data: { } })
            return response
         }else{
            return err
         }
      }
   }

   const handleSigninWithEmail = async (step: number, email: string, passcode: string) => {

      let data

      if (step == 1) {
         data = { email: email, step: step }
      } else if (step == 2) {
         data = { email: email, step: step, passcode: passcode }
      }

      try {

         const response = await axios.post(baseUrl + '/sign-in-with-email', data, {headers: getHeaders()})
         return response
      } catch (err : any) {

         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.post(baseUrl + '/sign-in-with-email', data, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   const getNotifications = async () => {

      try {
         const response = await axios.get(baseUrl + '/user-notifications', {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.get(baseUrl + '/user-notifications', {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }
   
   const savePairResponsee = async (pairId: string, responsee: string, token : string | null, refreshToken : string | null, user: string | null) => {

      try {
         const response = await axios.post(baseUrl + '/save-pair-response', {pairId: pairId, userId: user, token: token, refreshToken: refreshToken, response: responsee}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const tagNotificationSeen = async (notification: any) => {

      try {
         const response = await axios.post(baseUrl + '/tag-notification-seen', {notification: notification}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         if(err.response && err.response.data && err.response.data.code && err.response.data.code.includes('token-expired')){
            await getToken(getTokens().refresh)
            const response = await axios.post(baseUrl + '/tag-notification-seen', {notification: notification}, {headers: getHeaders()})
            return response
         }else{
            return err
         }
      }
   }

   return {
      getUser,
      addUser,
      updateUser,
      removeUser,
      toggleFollow,
      handleSigninWithEmail,
      getNotifications,
      tagNotificationSeen,
      savePairResponsee
   }
}

export default User
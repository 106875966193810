<template>
   <div :class="(artwork.type == 'video' || artwork.type == 'hevc') && artwork.source == 'local' ? 'w-screen md:w-auto px-6 md:min-w-[365px] min-h-[300px]' : 'w-full'" class="slider-in relative flex items-center justify-center">
      <div :class="(artwork.type == 'video' || artwork.type == 'hevc') && artwork.source == 'local' ? 'w-full rounded-[2.5rem] border border-theme-gray-4' : ''" class="slider-img-holder transition-none relative flex w-full h-full relative">
         <img v-if="artwork.type == 'image' || (artwork.type == 'video' && artwork.thumbnail !== '')" @click="handleClick(artworks, artworkIndex)" class="rounded-[2.5rem] cursor-pointer mx-auto min-w-[1px] h-screen-custom" :class="maxDimensions" :src="getArtworkSourceURL(artwork)" alt="edit">
         <div v-if="artwork.type == 'video'" @click="handleClick(artworks, artworkIndex)" class="absolute top-1/2 left-1/2 tranform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center cursor-pointer">
            <div class="bg-black m-auto cursor-pointer rounded-full border border-theme-gray-4 flex items-center justify-center w-[60px] h-[60px]">
               <font-awesome-icon icon="fa-solid fa-play" class="icon text-white ml-1" size="xl" />
            </div>
         </div>
         <div v-else-if="artwork.type == 'hevc'" class="absolute top-1/2 left-1/2 tranform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
            <div class="bg-black m-auto rounded-full border border-theme-gray-4 flex items-center justify-center w-[60px] h-[60px]">
               <font-awesome-icon icon="fa-solid fa-file" class="icon text-white ml-1" size="xl" />
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { getAssetBaseUrl } from '@/utils/common'
import { ProjectArtwork } from '@/types/project'

const assetUrl : string = getAssetBaseUrl()
const props = defineProps(['artwork', 'isActive', 'artworks', 'artworkIndex', 'screenWidth', 'canRemove'])
const emit = defineEmits(['clicked', 'removed'])

const handleRemove = (index: string, source: string) => {
   if (props.canRemove) {
      emit('removed', index, source)
   }
}

const handleClick = (artworks: ProjectArtwork[], index: string) => {
   emit('clicked', artworks, index)
}

const screenHeight = window.innerHeight
const maxHeight = ref<string>(screenHeight < 760 ? '550' : '600')

const maxDimensions = computed(() => {

   let maxWidth = 'max-w-[365px]'
   let maxHeight = ''

   if (props.artwork.type == 'image') {
   
      const width = parseInt(props.artwork.width)
      const height = parseInt(props.artwork.height)
      const aspectRatio = width / height

      if (aspectRatio > 1) {

         if (width > 700) {
            maxWidth = 'max-w-[700px]'
         } else if (width > 600) {
            maxWidth = 'max-w-[600px]'
         } else if (width > 500) {
            maxWidth = 'max-w-[500px]'
         } else if (width > 400) {
            maxWidth = 'max-w-[400px]'
         } else if (width > 300) {
            maxWidth = 'max-w-[365px]'
         }
      }
   } else {
      maxWidth = 'max-w-[365px]'
   }

   if (props.screenWidth == 'mobile') {
      maxWidth = 'max-w-[285px]'
   } 

   if (props.artwork.source == 'local') {
      maxHeight = 'h-screen-custom'
   }

   return maxWidth + ' ' + maxHeight
})

const getArtworkSourceURL = (artwork: any) : string => {

   let artworkSource : string = ''
   
   if (artwork.type == 'image') {

      artworkSource = artwork.source == 'database' ? assetUrl + 'artworks/' + artwork.data : artwork.data

      if (artwork.source == 'database') {
         artworkSource += props.screenWidth == 'mobile' ? '?tr=w-285,h-' + maxHeight.value : '?tr=h-' + maxHeight.value
      }

   } else if (artwork.type == 'video') {

      artworkSource = artwork.source == 'database' ? assetUrl + 'video_thumbnails/' + artwork.thumbnail + '?tr=w-320,h-' + maxHeight.value : artwork.data
   }

   return artworkSource
}

</script>

<style scoped>
   .swiper-slide-active .slider-in .slider-img-holder { height: 100%; }
   .swiper-slide:not(.swiper-slide-active) .slider-in .slider-img-holder { height: 90%; }
   .h-screen-custom { max-height: calc(100vh - 200px); }
   .custom-transition { transition-property: all; transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); transition-duration: 150ms; }
</style>